<template>
<!-- <v-dialog v-if="showModal" v-model="showModal" max-width="400" persistent> -->
<v-card>
    <vc-header title="Tiempo de inactividad" style="box-shadow: -2px -15px 15px 11px"></vc-header>
    <v-row class="mb-2 pl-1 pr-1 pt-1" style="margin:auto;margin-top:0px">
        <v-card outlined elevation="0" class="pb-4">
            <v-card-text>
                La Aplicación tiene varios minutos de inactividad.
                {{ time / 1000 }}
            </v-card-text>
        </v-card>
    </v-row>
    <vc-footer style="box-shadow: -2px 15px 15px 11px">
        <template v-slot:options>
            <v-btn v-if="visible" elevation="0" class="capitalize" text color="info" @click="onLine">
                Sigo Aquí
            </v-btn>
            <v-btn elevation="0" class="capitalize" text color="error" @click="$emit('logout')">
                Cerrar sesión
            </v-btn>
        </template>
    </vc-footer>
</v-card>
<!-- </v-dialog> -->
</template>

<script>

import _sAuth from "@/services/AuthService";
export default {
    name: '',

    components: {},

    data() {
        return {
            time: 30000,
            showModal: false,
            visible: true
        }
    },

    created() {

        /* this.showModal = JSON.parse(localStorage.getItem('showModal')); */
        this.time = Math.ceil(this.time / 1000) * 1000;
        /* if (!this.showModal) { */
        let timerId = setInterval(() => {
            this.time -= 1000;
            if (this.showModal) clearInterval(timerId);
            if (this.time < 1) {
                clearInterval(timerId);
                this.visible = false
                localStorage.removeItem('showModal')
                localStorage.setItem("showModal", false)
                this.$emit('onSesion');
            }
        }, 1000);
        /*  } */

    },

    methods: {
        onLine() {
            _sAuth
                .refreshToken({
                    UsrID: this.$fun.getUserID()
                })
                .then((response) => {
                    if (response.status == 200) {
                        localStorage.removeItem("token")
                        localStorage.setItem("token", response.data.Result.Data.Token);
                        this.$emit('onLine')

                    }
                });
        }
    },
    /*  beforeDestroy() {
         if (this.timerId) {
             clearInterval(this.timerId);
         }
     }, */

}
</script>

<style scoped>

</style>
