<template>
<div class="page-title-wrapper" style="height:40px important;">
    <v-row justify="center">
        <v-breadcrumbs :items="items" class="px-2 py-0">
            <template v-slot:divider>
                <i class="pe-7s-angle-right-circle"> </i>
            </template>
        </v-breadcrumbs>
    </v-row>
</div>
</template>

<script>
export default {
    components: {},

    watch: {
        $route() {
            this.items = this.$route.meta.breadcrumb;
        },
    },
    mounted() {
        this.items = this.$route.meta.breadcrumb;

    },
    data() {
        return {
            items: [],
        };
    },
}
</script>
