<template>
<div>
    <vc-text :readonly=readonly :disabled=disabled label="Proveedor" @click="dialogSupplier=true" v-model="textDescription">
        <template v-slot:prepend>
            <v-icon style="font-size:16px;" @click="$emit('editProvider')" color="info">mdi-file-edit-outline</v-icon>
        </template>

    </vc-text>
    <v-dialog width="80%" v-model="dialogSupplier" v-if=dialogSupplier transition="dialog-bottom-transition">
        <vc-supplier-crud style="overflow: hidden !important; " @dblClick="dblClick($event)" @close="dialogSupplier=false"></vc-supplier-crud>
    </v-dialog>

</div>
</template>

<script>
import vcSupplierCrud from '@/components/Logistics/vcSupplierCrud.vue'
export default {
    components: {
        vcSupplierCrud
    },
    props: {
        value: null,
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: true
        },

    },
    data() {
        return {
            supplier: null,
            textDescription: '',
            dialogSupplier: false,
        };
    },
    methods: {
        dblClick(item) {
            this.supplier = item;
            this.$emit('input', item);
            this.textDescription = item.SupName;
            this.dialogSupplier = false;
        }
    },
    created() {

        this.supplier = this.value;
        if (this.supplier != null) {
            this.textDescription = this.supplier.SupName;
        }
    },

    watch: {
        value() {
            this.textDescription = this.value.SupName;

        }
    },
};
</script>
