import Service from "../Service";
import Vue from "vue";
const resource = "GenSupplier/";

export default {
    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: {},
        });
    },
    update(obj, requestID) {
        return Service.put(resource + "update", obj, {
            params: {},
        });
    },
    delete(obj, requestID) {
        return Service.delete(resource + "delete", {
            data: obj
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {},
        });
    },
    searchbyruc(parameters) {
        return Service.post(resource + "searchbyruc", parameters, {
            params: {},
        });
    },

    getSupplierSearch() { return Vue.prototype.$http.defaults.baseURL + resource + "search"; },
};