<template>
<div>
    <div>

        <vc-header :save="isEditable" close :title="value > 0 ? 'Orden de ' + orderLocal.TypeOrderName + ': ' + orderLocal.OrdDocumentNumber + ' | ' + orderLocal.NtpFullName : 'Orden'" :approve="optionApproved" :transactionApproved="transactionApproved" download @download="printPdf" @save="save()" @close="$emit('close')">

        </vc-header>

        <v-card class="mt-1 pt-0" style="max-height: 650px;  overflow-y: auto;">
            <form id="formorder">
                <v-row style="margin:auto">

                    <v-col lg="3" cols="12" class="s-col-form">
                        <v-row class="mb-3">
                            <v-col cols="12">
                                <v-card outlined elevation="0" height="auto">
                                    <vc-header-title title="Datos Generales" />

                                    <v-row style="margin: auto" class="pb-5 pt-3">
                                        <v-col class="s-col-form" cols="12">
                                            <lgs-supplier :disabled="!isEditable" id="Supplier" label="Proveedor" v-model="Supplier" :value="Supplier" />
                                        </v-col>
                                        <v-col lg="6" md="6" cols="12" class="s-col-form">
                                            <vc-text label="Contacto" :disabled="!isEditable" id="SupConName" v-model="Supplier.SupConName"></vc-text>
                                        </v-col>

                                        <v-col lg="6" md="6" cols="12" class="s-col-form">
                                            <vc-text number label="Telefono" :disabled="!isEditable" id="SupTelephone" v-model="Supplier.SupTelephone"></vc-text>
                                        </v-col>

                                        <v-col lg="12" cols="12" class="s-col-form">
                                            <vc-text label="Email" :disabled="!isEditable" id="SupEmail" v-model="Supplier.SupEmail"></vc-text>
                                        </v-col>
                                        <v-col lg="12" class="s-col-form" cols="6">
                                            <vc-select-definition id="TypeCurrency" :disabled="!isEditable" :def="1020" v-model="orderLocal.TypeCurrency" label="Moneda" />
                                        </v-col>

                                        <!-- <v-col lg="12" class="s-col-form" cols="6">
                                            <vc-select-definition id="TypeCredit" :disabled="!isEditable" :def="1021" v-model="orderLocal.TypeCredit" label="Tipo Credito" />
                                        </v-col>
                                        <v-col lg="12" class="s-col-form" cols="6">
                                            <vc-select-definition id="TypePayment" :disabled="!isEditable" :def="1022" v-model="orderLocal.TypePayment" label="Forma de Pago" />
                                        </v-col> -->

                                        <v-col cols="12" class="s-col-form">
                                            <vc-select-definition id="TypePayment" :disabled="!isEditable" :def="1056" v-model="orderLocal.TypePayment" label="Forma de Pago" @input="loadPayment" />
                                        </v-col>

                                        <v-col cols="12" class="s-col-form" v-if="orderLocal.TypePayment > 0">
                                            <v-select label="Condición de Pago" :disabled="!isEditable" id="ConID" :items="paymentCondition" item-text="ConDescription" item-value="ConID" v-model="orderLocal.ConID"></v-select>
                                        </v-col>
                                        <v-col cols="12" class="s-col-form mt-0" v-if="orderLocal.TypePayment > 0">
                                            <v-select label="Días Credito" :disabled="!isEditable" id="DayID" :items="dayCredit" item-text="DayDescription" item-value="DayID" v-model="orderLocal.DayID"></v-select>
                                        </v-col>

                                        <v-col lg="12" class="s-col-form" cols="6">
                                            <vc-text number id="OrdDeliveryDay" :disabled="!isEditable" label="Tiempo de entrega(días)" :min="0" v-model="orderLocal.OrdDeliveryDay"></vc-text>
                                        </v-col>
                                        <v-col lg="12" class="s-col-form" cols="6">
                                            <vc-text number id="OrdWarrantyDay" :disabled="!isEditable" label="Garantia(días)" v-model="orderLocal.OrdWarrantyDay" :min="0"></vc-text>
                                        </v-col>
                                        <v-col lg="12" class="s-col-form" cols="6">
                                            <vc-date id="OrdDate" label="Fecha" disabled v-model="orderLocal.OrdDate"></vc-date>
                                        </v-col>
                                        <v-col cols="12" class="s-col-form pt-0" v-if="orderLocal.OrdID > 0">
                                            <v-btn block text outlined elevation="0" class="capitalize mt-5" small @click="showUpload=true">
                                                <v-icon color="green" style="font-size:16px;margin-right:5px;">fa-solid fa-file</v-icon>Anexos
                                            </v-btn>
                                        </v-col>

                                    </v-row>
                                </v-card>
                            </v-col>

                        </v-row>
                    </v-col>

                    <v-col lg="9" cols="12" class="s-col-form">
                        <v-row class="mb-3">
                            <lgs-order-purcharse-support @support="support($event)" :support="itemsSupport" />
                        </v-row>
                        <v-divider class="mb-0" style="margin-top:-20px" />
                        <v-row class="mb-3">
                            <v-col cols="12">
                                <v-card outlined elevation="0" height="auto">
                                    <!-- <vc-header-title /> -->

                                    <!-- <v-row style="margin: auto">
                                        <v-col cols="12" style="margin-top: -10px" class="pb-6"> -->
                                    <v-data-table dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="Line" :height="$vuetify.breakpoint.xs ? 'auto' : '200'" hide-default-footer>
                                        <template v-slot:item.OddUnitPrice="props">
                                            <div v-if="value > 0">
                                                {{parseFloat(props.item.OddUnitPrice) > 0 ? parseFloat(props.item.OddUnitPrice).toFixed(2) : props.item.OddUnitPrice}}
                                            </div>
                                            <vc-text v-else style="width: 125px" decimal @input="changeItem(props.item)" v-model="props.item.OddUnitPrice">
                                                Precio Unitario
                                            </vc-text>
                                        </template>
                                        <template v-slot:item.OddQuantity="props">

                                            <vc-text v-if="isQuantity && value <= 0" style="width: 125px" decimal v-model="props.item.OddQuantity" @input="changeItem(props.item)">
                                                Cantidad
                                            </vc-text>
                                            <div v-else>
                                                {{props.item.OddQuantity}}
                                            </div>
                                        </template>
                                        <template v-slot:item.OddObservation="props">
                                            <div v-if="value > 0">
                                                {{props.item.OddObservation}}
                                            </div>
                                            <vc-text v-else label="" v-model="props.item.OddObservation"></vc-text>
                                        </template>
                                        <template v-slot:item.OddAmount="{ item }">
                                            {{parseFloat(item.OddAmount) > 0 ? parseFloat(item.OddAmount).toFixed(2) : item.OddAmount}}
                                        </template>
                                    </v-data-table>
                                    <!-- </v-col>
                                    </v-row> -->
                                </v-card>
                            </v-col>

                            <v-col cols="12">
                                <v-card elevation="0" height="auto">
                                    <!-- <vc-header-title /> -->

                                    <v-row style="margin: auto">
                                        <v-col cols="12" lg="8" md="8" class="s-col-form">
                                            <v-textarea auto-grow ref="OrdObservation" label="Observaciones Generales" v-model="orderLocal.OrdObservation" />
                                        </v-col>
                                        <v-col cols="12" lg="4" md="4" class="s-col-form">
                                            <div>
                                                <vc-select-definition v-if="value <= 0" label="Seleccione Impuesto" :outlined="false" :def="1027" return-object v-model="tax" @input="changeTax($event)" />
                                                <h5 v-else>{{orderLocal.TypePercentageName}}</h5>
                                            </div>

                                            <v-divider class="mt-4 mb-4"></v-divider>
                                            <v-row style="margin-top:-15px;margin-bottom:-17px">
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>V. NETO</b></v-label>
                                                </v-col>
                                                <v-col lg="6" cols="6" class="s-col-form">
                                                    <h5 class="text-right">
                                                        <h5 class="text-right">
                                                            <!-- {{orderLocal.OrdTotalNet}} -->
                                                            {{
                            orderLocal.OrdTotalNet >= 1000
                              ? orderLocal.OrdTotalNet.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTotalNet.toFixed(2)
                          }}
                                                        </h5>
                                                    </h5>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="mt-4 mb-4"></v-divider>
                                            <!-- <v-row>
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>COSTOS DIRECTOS</b></v-label>
                                                </v-col>
                                                <v-col lg="6" cols="6" class="s-col-form">
                                                    <h5 v-if="value > 0" class="text-right">
                                                        {{
                            orderLocal.OrdDirectCost >= 1000
                              ? orderLocal.OrdDirectCost.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdDirectCost > 0 ? orderLocal.OrdDirectCost.toFixed(2) : 0
                          }}
                                                    </h5>
                                                    <h5 v-else style="margin-top:-30px" class="text-right">
                                                        <vc-text decimal v-model="orderLocal.OrdDirectCost" @input="changeTotal()"></vc-text>
                                                    </h5>
                                                </v-col>
                                            </v-row> -->
                                            <v-row v-if="orderLocal.OrdGeneralgto > 0">
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>GTO GENERALES</b></v-label>
                                                </v-col>
                                                <v-col lg="6" class="s-col-form">
                                                    <h5 v-if="value > 0" class="text-right">
                                                        {{
                            orderLocal.OrdGeneralgto >= 1000
                              ? orderLocal.OrdGeneralgto.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdGeneralgto > 0  ? orderLocal.OrdGeneralgto.toFixed(2) : 0
                          }}
                                                    </h5>
                                                    <h5 v-else style="margin-top:-20px" class="text-right">
                                                        <vc-text decimal v-model="orderLocal.OrdGeneralgto" @input="changeTotal()"></vc-text>
                                                    </h5>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="orderLocal.OrdUtilities>0">
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>UTILIDADES</b></v-label>
                                                </v-col>
                                                <v-col lg="6" class="s-col-form">
                                                    <h5 v-if="value > 0" class="text-right">
                                                        {{
                            orderLocal.OrdUtilities >= 1000
                              ? orderLocal.OrdUtilities.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdUtilities > 0 ? orderLocal.OrdUtilities.toFixed(2) : 0
                          }}
                                                    </h5>
                                                    <h5 v-else style="margin-top:-20px" class="text-right">
                                                        <vc-text decimal v-model="orderLocal.OrdUtilities" @input="changeTotal()"></vc-text>
                                                    </h5>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="orderLocal.OrdDiscount>0">
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>DESCUENTO</b></v-label>
                                                </v-col>
                                                <v-col lg="6" class="s-col-form">
                                                    <h5 v-if="value > 0" class="text-right">
                                                        {{
                            orderLocal.OrdDiscount >= 1000
                              ? orderLocal.OrdDiscount.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdDiscount > 0 ? orderLocal.OrdDiscount.toFixed(2) : 0
                          }}
                                                    </h5>
                                                    <h5 v-else style="margin-top:-20px" class="text-right">
                                                        <vc-text decimal v-model="orderLocal.OrdDiscount" @input="changeTotal()"></vc-text>
                                                    </h5>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="mt-4 mb-4"></v-divider>
                                            <v-row style="margin-top:-15px;margin-bottom:-17px">
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>SUB TOTAL</b></v-label>
                                                </v-col>
                                                <v-col lg="6" class="s-col-form">
                                                    <h5 class="text-right">
                                                        {{
                            orderLocal.OrdSubTotal >= 1000
                              ? orderLocal.OrdSubTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdSubTotal.toFixed(2)
                          }}
                                                    </h5>
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col class="s-col-form">
                                                    <v-label><b>IMPUESTO{{ " (" + OrdTaxPercentage + "%)" }}</b></v-label>
                                                </v-col>
                                                <v-col class="s-col-form">
                                                    <h5 class="text-right">
                                                        {{
                            orderLocal.OrdTaxAmount >= 1000
                              ? orderLocal.OrdTaxAmount.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTaxAmount.toFixed(2)
                          }}
                                                    </h5>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="mt-4 mb-4"></v-divider>
                                            <v-row style="margin-top:-15px;">
                                                <v-col class="s-col-form">
                                                    <v-label><b>TOTAL</b></v-label>
                                                </v-col>
                                                <v-col class="s-col-form">
                                                    <h4 class="text-right">
                                                        {{
                            orderLocal.OrdTotal >= 1000
                              ? orderLocal.OrdTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTotal.toFixed(2)
                          }}
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                            <!-- <lgs-order-purcharse-detail :value="value" :orderNew="order" :orderEdit="orderLocal" @detail="detail" /> -->
                        </v-row>
                    </v-col>
                </v-row>
            </form>

        </v-card>

        <vc-footer style="box-shadow: -2px 15px 15px 11px"></vc-footer>
        <vc-loading :processing="processing" :errors="errors" @close="processing=false;" />
        <vc-modal-circuit close :circuits="circuits" :TrsCode="TrsCode" :dialog="dialogCircuit" @close="dialogCircuit = false" :width="circuits.length > 1 ? 940 : 500" />
        <v-dialog v-model="showUpload" v-if="showUpload" max-width="800">
            <vc-upload-files :config="configFile"  @close="showUpload=false;" />
        </v-dialog>

    </div>
</div>
</template>

<script>
//Services
import _sOrderPurchase from "@/services/Logistics/LgsOrderPurcharseService";
import _sConditionPayment from "@/services/Logistics/LgsPaymentConditionService.js";
import _sDayCredit from "@/services/Logistics/LgsDayCreditService.js";

//Components
import LgsSupplier from "@/views/Logistics/LgsSupplier.vue";
import LgsOrderPurcharseSupport from './LgsOrderPurcharseSupport.vue';
import LgsOrderPurcharseDetail from './LgsOrderPurcharseDetail.vue';

//Functions
import _validaERP from "@/plugins/validaERP";

export default {
    components: {
        LgsSupplier,
        LgsOrderPurcharseSupport,
        LgsOrderPurcharseDetail
    },
    props: {
        order: {},
        value: {
            default: 0,
            type: Number,
        },
        isQuantity: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            orderLocal: {},
            Supplier: {
                SupID: null,
                SupDocumentNumber: null,
                SupName: null,
                SupConName: null,
                SupTelephone: null,
                SupEmail: null,
            },

            itemsSAP: [],
            selectedSAP: [],
            headersSAP: [{
                    text: "Cod. Art.",
                    value: "ArtCode",
                    sortable: false,
                    width: 80,
                },
                {
                    text: "Descripción",
                    value: "OddDescription",
                    sortable: false,
                    width: 340,
                },
                {
                    text: "Prc. U.(Sin IGV)",
                    value: "OddUnitPrice",
                    sortable: false,
                    width: 90,
                },
                {
                    text: "Cant",
                    value: "OddQuantity",
                    sortable: false,
                    width: 50,
                },

                {
                    text: "Und",
                    value: "OddUnitMeasurement",
                    sortable: false,
                    width: 50,
                },
                {
                    text: "SubTotal",
                    value: "OddAmount",
                    sortable: false,
                    width: 70,
                    align: "right",
                },
                {
                    text: "Comentario",
                    value: "OddObservation",
                    sortable: false,
                    width: 400,
                },
            ],

            //Global
            tax: null,
            OrdTaxSign: "",
            OrdTaxPercentage: "",
            typePayment: [],
            selectedPayment: null,
            paymentCondition: [],
            dayCredit: [],
           

            //Circuitos
            circuits: [],
            dialogCircuit: false,
            TrsCode: "TrsOrder",

            //Detail
            itemsSAP: [],

            //Support
            itemsSupport: [],

            //Loading
            processing: false,
            errors: null,

            //Transacciones
            optionApproved: false,
            transactionApproved: null,

            //Attach
            showUpload:false,
            configFile:{
                TrsCode: "TrsOrder",
                DocEntry: this.value,
                OrdID: "ID",
                service: _sOrderPurchase
            }

        }
    },

    created() {
        this.loadPayment();

        if (this.value > 0) {
            this.initialize();
        } else {
            if (this.order.CttID > 0) {
                this.orderLocal = this.order;
                this.Supplier = this.order.sup;
                this.tax = {
                    DedValue: this.order.TypePercentage,
                    DedHelper: parseFloat(this.order.OrdTaxPercentage),
                    DedAbbreviation: this.order.OrdTaxSign
                };
                //this.changeTax()

                console.log("al levantar orden", this.order);
            }

            //this.orderLocal = this.order;

            this.itemsSAP = this.order.detail;

        }

        if (this.itemsSAP)
            for (let i = 0; i < this.itemsSAP.length; i++) this.itemsSAP[i].Line = i + 1;

        this.changeTotal()
    },
    computed: {
        isEditable() {
            return this.orderLocal.OrdID == null || this.orderLocal.OrdID == 0;
        },
    },

    mounted() {
        if (this.value != null) {
            this.processing = true;
        }
    },

    watch: {

        isEditable() {
            this.processing = false;
        },
        /* 'orderLocal.TypePayment'(){

            this.loadPayment()
        } */
    },

    methods: {

        initialize() {
            _sOrderPurchase
                .byID({
                    OrdID: this.value,
                })
                .then((r) => {
                    //this.order = r.data.Result

                    this.orderLocal = r.data.Result
                    this.Supplier = r.data.Result
                    this.itemsSAP = r.data.Result.itemsDetails;
                    this.itemsSupport = r.data.Result.itemsSupport;

                    console.log("al levantar", this.orderLocal);

                    this.transactionApproved = this.orderLocal.Transaction;
                    if (this.orderLocal.Transaction != null)
                        if (this.orderLocal.Transaction.UsersApproved.length > 0)
                            if (
                                this.orderLocal.Transaction.UsersApproved.find(
                                    (x) => x.UsrID == this.$fun.getUserID()
                                )
                            )
                                if (this.orderLocal.OrdStatus == 4) this.optionApproved = true;

                });

        },

        loadPayment() {
            this.paymentCondition = []
            this.dayCredit = []

            _sConditionPayment.list(this.orderLocal.TypePayment).then(resp => {
                this.paymentCondition = resp.data.Result
            })

            _sDayCredit.list(this.orderLocal.TypePayment).then(resp => {
                this.dayCredit = resp.data.Result
            })
        },

        support(item) {
            console.log(item);
            if (this.value <= 0) {
                item.OprID = 0
                item.OrdID = 0
            }

            this.itemsSupport = item
        },
        /* detail(det, head) {
            this.itemsSAP = det
            this.orderLocal.OrdDirectCost = Number.parseFloat(head.OrdDirectCost)
            this.orderLocal.OrdGeneralgto = Number.parseFloat(head.OrdGeneralgto)
            this.orderLocal.OrdUtilities = Number.parseFloat(head.OrdUtilities)
            this.orderLocal.OrdDiscount = Number.parseFloat(head.OrdDiscount)

            this.orderLocal.OrdSubTotal = Number.parseFloat(head.OrdSubTotal)
            this.orderLocal.OrdTaxAmount = Number.parseFloat(head.OrdTaxAmount)
            this.orderLocal.OrdTotalNet = Number.parseFloat(head.OrdTotalNet)
            this.orderLocal.OrdTotal = Number.parseFloat(head.OrdTotal)

            this.orderLocal.OrdTaxPercentage = Number.parseFloat(head.OrdTaxPercentage)
            this.orderLocal.TypePercentage = head.TypePercentage;
            this.orderLocal.OrdObservation = head.OrdObservation;

        }, */
        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },
        changeItem(item) {
            this.orderLocal.OrdSubTotal = 0;
            this.orderLocal.OrdTotalNet = 0;
            this.orderLocal.OrdTaxAmount = 0;
            this.orderLocal.OrdTotal = 0;

            let edit = Object.assign({},
                this.itemsSAP.find((x) => x.Line == item.Line)
            );
            let indexEdit = this.itemsSAP.indexOf(item);

            edit.OddAmount = edit.OddUnitPrice * edit.OddQuantity;
            console.log("edit.OddAmount", edit.OddAmount);

            this.itemsSAP.splice(indexEdit, 1, edit);

            this.itemsSAP.forEach((element) => {
                let unitPrice =
                    element.OddUnitPrice == null || element.OddUnitPrice == "" ?
                    0 :
                    element.OddUnitPrice;

                this.orderLocal.OrdTotalNet =
                    parseFloat(this.orderLocal.OrdTotalNet) +
                    parseFloat(unitPrice) *
                    (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));

                this.orderLocal.OrdSubTotal =
                    parseFloat(this.orderLocal.OrdTotalNet) +
                    ((parseFloat(this.orderLocal.OrdDirectCost || 0) +
                            parseFloat(this.orderLocal.OrdGeneralgto || 0) +
                            parseFloat(this.orderLocal.OrdUtilities || 0)) -
                        parseFloat(this.orderLocal.OrdDiscount || 0)
                    );
            });

            this.orderLocal.OrdTaxAmount =
                (parseFloat(this.orderLocal.OrdSubTotal) *
                    parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                100;

            this.orderLocal.OrdTotal =
                this.orderLocal.OrdSubTotal + this.orderLocal.OrdTaxAmount;
        },

        changeTax(item) {
            if (this.tax != null) {

                this.OrdTaxPercentage = this.tax.DedHelper
                console.log(this.tax.DedHelper);
                //this.orderLocal.TypePercentage = this.tax.DedValue;
                this.OrdTaxSign = this.tax.DedAbbreviation;

                this.orderLocal.OrdTaxAmount =
                    (parseFloat(this.orderLocal.OrdSubTotal) *
                        parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                    100;

                this.changeTotal();

            }
        },

        changeTotal() {

            this.orderLocal.OrdTotalNet = 0;
            this.orderLocal.OrdSubTotal = 0;
            this.orderLocal.OrdTaxAmount = 0;
            this.orderLocal.OrdTotal = 0;

            if (this.itemsSAP !== undefined) {

                console.log("changeTotal", this.itemsSAP);

                this.itemsSAP.forEach((element) => {
                    let unitPrice = element.OddUnitPrice == null ? 0 : parseFloat(element.OddUnitPrice);

                    this.orderLocal.OrdTotalNet =
                        parseFloat(this.orderLocal.OrdTotalNet) +
                        parseFloat(unitPrice) *
                        (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));

                    this.orderLocal.OrdSubTotal =
                        parseFloat(this.orderLocal.OrdTotalNet) +
                        ((parseFloat(this.orderLocal.OrdDirectCost || 0) +
                                parseFloat(this.orderLocal.OrdGeneralgto || 0) +
                                parseFloat(this.orderLocal.OrdUtilities || 0)) -
                            parseFloat(this.orderLocal.OrdDiscount || 0)
                        );

                    this.orderLocal.OrdTaxAmount =
                        (parseFloat(this.orderLocal.OrdSubTotal) *
                            parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                        100;
                });

            }
            this.orderLocal.OrdSubTotal = parseFloat(
                parseFloat(this.orderLocal.OrdSubTotal).toFixed(2)
            );
            this.orderLocal.OrdTaxAmount = parseFloat(
                parseFloat(this.orderLocal.OrdTaxAmount).toFixed(2)
            );

            let total = 0.0

            switch (this.OrdTaxSign) {
                case "+":
                    total = this.orderLocal.OrdSubTotal + this.orderLocal.OrdTaxAmount;
                    break;
                case "-":
                    total = this.orderLocal.OrdSubTotal - this.orderLocal.OrdTaxAmount;
                    break;
            }

            this.orderLocal.OrdTotal = total

        },

        save() {
            //Validamos Campos
            var resp = _validaERP.valida({
                form: '#formorder',
                items: {

                    Supplier: {
                        required: true,
                        value: this.Supplier,
                    },
                    SupConName: {
                        required: true,
                        cadena: true,
                        regular: true,
                        value: this.Supplier.SupConName,
                    },
                    SupEmail: {
                        required: true,
                        email: true,
                        value: this.Supplier.SupEmail,
                    },
                    SupTelephone: {
                        required: true,
                        number: true,
                        minlength: 9,
                        value: this.Supplier.SupTelephone,
                    },

                    /* TypeCredit: {
                        required: true,
                        value: this.orderLocal.TypeCredit,
                    }, */
                    TypePayment: {
                        required: true,
                        value: this.orderLocal.TypePayment,
                    },

                    ConID: {
                        required: this.paymentCondition.length > 0 ? true : false,
                        value: this.orderLocal.ConID,
                    },

                    DayID: {
                        required: this.dayCredit.length > 0 ? true : false,
                        value: this.orderLocal.DayID,
                    },

                    OrdDeliveryDay: {
                        required: true,
                        min: 1,
                        number: true,
                        value: this.orderLocal.OrdDeliveryDay,
                    },
                    OrdWarrantyDay: {
                        required: true,
                        number: true,
                        min: 0,
                        value: this.orderLocal.OrdWarrantyDay,
                    },
                    TypeCurrency: {
                        required: true,
                        value: this.orderLocal.TypeCurrency,
                    },
                    OrdDate: {
                        required: true,
                        value: this.orderLocal.OrdDate,
                    },

                }
            })

            if (resp) {

                if (this.Supplier == null) {
                    this.$fun.alert("Falta proveedor", "warning");
                    return;
                }
                if (this.itemsSAP.length < 1) {
                    this.$fun.sweetAlert("No se ha registrado ningún detalle", "warning");
                    return;
                }

                if (this.$fun.formatDateView(this.orderLocal.OrdDate) < this.$fun.formatDateView(this.$fun.getDate())) {
                    this.$fun.sweetAlert("Fecha no puede ser menor a la actual", "warning")
                    return
                }

                if (this.orderLocal.OrdTotal <= 0) {
                    this.$fun.sweetAlert("[Monto Total de Orden no valido.]", "warning")
                    return
                }

                for (let i = 0; i < this.itemsSAP.length; i++) {

                    if (
                        this.itemsSAP[i].OddQuantity > 0 &&
                        this.itemsSAP[i].OddUnitPrice == 0
                    ) {
                        this.$fun.alert(
                            "Precio inválido. " + this.itemsSAP[i].OddDescription,
                            "warning"
                        );
                        return;
                    }

                    let sum = this.itemsSAP[i].DerQuantity - this.itemsSAP[i].DerQuantityAttended;

                    if (this.itemsSAP[i].OddQuantity > sum) {
                        this.$fun.alert(
                            "Cantidad restante por atender. [" + this.itemsSAP[i].OddDescription + "] = " + sum,
                            "warning"
                        );
                        return;
                    }
                }

                if (this.paymentCondition.length <= 0) {
                    this.orderLocal.ConID = null
                }

                if (this.dayCredit.length <= 0) {
                    this.orderLocal.DayID = null
                }

                this.orderLocal.OrdID = 0;
                this.orderLocal.ReqID = this.order.ReqID
                this.orderLocal.OrdTaxPercentage = this.tax.DedHelper
                this.orderLocal.TypePercentage = this.tax.DedValue;
                this.orderLocal.OrdTaxSign = this.tax.DedAbbreviation;

                this.orderLocal.TypeOrder = this.order.TypeOrder
                this.orderLocal.SupDocumentNumber = this.Supplier.SupDocumentNumber;
                this.orderLocal.SupName = this.Supplier.SupName;
                this.orderLocal.SupTelephone = this.Supplier.SupTelephone;
                this.orderLocal.SupConName = this.Supplier.SupConName;
                this.orderLocal.SupEmail = this.Supplier.SupEmail;
                this.orderLocal.UsrID = this.$fun.getUserID();
                this.orderLocal.CpyID = this.$fun.getCompanyID()
                this.orderLocal.SecStatus = 1;
                this.orderLocal.UsrCreateID = this.$fun.getUserID();

                this.orderLocal.itemsDetails = this.itemsSAP;
                this.orderLocal.itemsSupport = this.itemsSupport;

                this.$fun.sweetAlert("¿Seguro de grabar?", "question").then(val => {
                    if (val.value) {
                        this.processing = true;

                        _sOrderPurchase
                            .save(this.orderLocal)
                            .then((resp) => {
                                    if (resp.status == 200) {

                                        if (resp.data.Result.Data.length > 0) {

                                            this.circuits = resp.data.Result.Data;
                                            this.dialogCircuit = true;
                                        } else {
                                            this.processing = false;

                                            this.$fun.sweetAlert("Guardado Correctamente", "success");
                                            this.$emit("close");

                                        }
                                    }
                                },
                                (e) => {
                                    this.errors = e.response
                                    this.processing = true;
                                })
                    }
                })

                console.log("save", this.orderLocal);
            }

        },

        printPdf() {

            if (this.orderLocal.OrdID == undefined) return
            this.processing = true;
            _sOrderPurchase.printPDF(this.orderLocal.OrdID).then(resp => {
                    this.processing = false;
                    console.log("response print pdf", resp.data);
                    this.$fun.downloadFile(resp.data, this.$const.TypeFile.PDF, "Orden de " + this.orderLocal.TypeOrderName + "_" + this.orderLocal.OrdDocumentNumber);
                },
                (e) => {
                    this.errors = e.response
                    this.processing = true;
                })
        }
    }

};
</script>

<style lang="scss" scoped>

</style>
