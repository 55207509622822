<template>
    <div style="padding-top:-2px;margin-top:-2px;">
        <template>
            <v-label>
                <b>{{ label }}</b>
            </v-label>
            <v-select
                ref='sgeneric'
                v-model="valueLocal"
                :items="items"
                chips
               
                multiple
            ></v-select>
        </template>
    </div>
    </template>
    
    <script>
    // import _sDefinition from "@/services/General/GenDefinitionService.js";
    
    export default {
        components: {},
        props: {
            itemvalue: {
                type: String,
                default: "DedValue",
            },
            abbr: {
                type: Boolean,
                default: false,
            },
            value: null,
            label: {
                type: String,
                default: "",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            noDefault: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            clearable: {
                type: Boolean,
                default: false,
            },
            def: {
                type: Number,
                default: 0,
            },
            add: {
                type: Boolean,
                default: false,
            },
            returnObject: {
                type: Boolean,
                default: false,
            },
            dgr: {
                type: Number,
                default: 0,
            },
            autocomplete: {
                type: Boolean,
                default: false,
            },
            full: {
                type: Boolean,
                default: false,
            },
            autofocus: {
                type: Boolean,
                default: false,
            },
    
            outlined: {
                type: Boolean,
                default: true
            },
        },
        data: () => ({
            items: ['.Pdf', '.Docx', '.Png'],
            valueLocal: [],
            config: {}
        }),
    
        
        methods: {
            isFocused(val) {
                this.$refs.sgeneric.isFocused(val);
            },
            focus() {
                this.$refs.sgeneric.focus();
            },
    
            refresh(item) {
                this.$refs.sgeneric.load();
            },
            addEvent() {
                this.dialogAdd = true;
            },
            input(item) {
                this.$emit("input", item);
            },
            change(item) {
                this.$emit("change", item);
            },
        },
        created() {
    
            
          
            // this.config.params.requestID = this.$fun.getUserID();
            this.valueLocal = this.value;
        },
    };
    </script>
    