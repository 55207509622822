import Service from "../Service";
import Vue from "vue";
const resource = "LgsGenericExactus/";

export default {

    getPartidas() {
        return Vue.prototype.$http.defaults.baseURL + resource + "getPartidas";
    },

    getProjects() {
        return Vue.prototype.$http.defaults.baseURL + resource + "getProjects";
    },

    getTypeSpent() {
        return Vue.prototype.$http.defaults.baseURL + resource + "getTypeSpent";
    },

    getCenterCoste() {
        return Vue.prototype.$http.defaults.baseURL + resource + "getCenterCoste";
    }
};