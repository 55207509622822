<template>
<div>

    <v-dialog v-if="dialogPbk" v-model="dialogPbk" max-width="700">
        <div>
            <vc-header title="Bancos" save @save="save" close @close="dialogPbk=false"></vc-header>
            <v-row style="margin:auto">

                <v-card>
                    <form id="frmsupplierBanks">
                        <v-row class="mt-3 mb-2">
                            <v-col lg="6" md="6" cols="12" class="s-col-form">
                                <vc-select-definition id="TypeBank" v-model="itemPbk.TypeBank" :def="1025" label="Tipo de Banco">
                                </vc-select-definition>
                            </v-col>

                            <v-col lg="6" md="6" cols="12" class="s-col-form">
                                <vc-select-definition id="TypeCurrency" v-model="itemPbk.TypeCurrency" :def="1020" label="Tipo de Moneda">
                                </vc-select-definition>
                            </v-col>

                            <v-col cols="12" class="s-col-form">
                                <vc-text id="PbkBill" number autofocus v-model="itemPbk.PbkBill" label="Numero de Cuenta Interbancario(no incluya guiones)">
                                </vc-text>
                            </v-col>
                            <!--  <v-col lg="4" md="4" cols="12" class="s-col-form">
                                <vc-text id="PbkCci" v-model="itemPbk.PbkCci" label="Cuenta CCI">
                                </vc-text>
                            </v-col> -->
                            <v-col cols="12" class="s-col-form">
                                <vc-text id="PbkBeneficiaryName" v-model="itemPbk.PbkBeneficiaryName" label="Beneficiario">
                                </vc-text>
                            </v-col>

                        </v-row>
                    </form>
                </v-card>
            </v-row>
        </div>

    </v-dialog>

    <v-col cols="12" lg="12" md="12">
        <v-btn small elevation="0" color="" @click="dialogPbk=true" class="capitalize m-1 ml-1 mr-1">
            <v-icon color="success" style="font-size:12px;margin-right:5px;">fa-plus</v-icon> Nuevo
        </v-btn>
        <v-data-table @click:row="rowClick" dense hide-default-footer disable-sort :items="items" :headers="headers" :items-per-page="-1" v-model="selected" item-key="PbkID">
            <template v-slot:item.delete="{ item }">
                <v-btn icon elevation="0" class="capitalize ml-1 mr-1" small @click="remove(item)">
                    <v-icon style="font-size:18px;margin-right:5px;color:red">mdi-minus-circle-outline</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <!--  <vc-crud sortable restore no-full ref="crudSupplierBanks" title="Bancos" :config="configPbk" :filter="filterPbk" @add="
                  dialogPbk = true;
                  itemPbk = {};
                " @edit="editPbk($event)" add edit remove searchInput>
            <template v-slot:SecStatus="{ row }">
                <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'">
                    mdi-checkbox-blank-circle</v-icon>
            </template>
        </vc-crud> -->
    </v-col>
</div>
</template>

<script>
import _sGenSupplierBanks from "@/services/General/GenSupplierBanksService.js";
import _validaERP from "@/plugins/validaERP";

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon,

    },

    props: {
        SupID: {
            type: Number,
            default: 0
        }
    },
    data() {

        return {
            /* 
             filterPbk: {
                 SupID: 0,
             },
             configPbk: {
                 model: {
                     PbkID: "ID",
                 },
                 service: _sGenSupplierBanks,

             },
              */
            itemPbk: {},
            dialogPbk: false,
            headers: [{
                    text: "Del",
                    value: "delete",
                    sortable: false,
                    width: 50,
                    align: "center"
                },
                {
                    text: "Tipo de Banco",
                    value: "TypeBankName",
                    sortable: false,
                },
                {
                    text: "Cuenta",
                    value: "PbkBill",
                    sortable: false,
                },
                {
                    text: "Beneficiario",
                    value: "PbkBeneficiaryName",
                    sortable: false,
                },
                {
                    text: "Tipo de Moneda",
                    value: "TypeCurrencyName",
                    sortable: false,
                },
            ],
            items: [],
            selected: []
        };
    },

    mounted() {

    },

    created() {
        this.initialize()
    },

    methods: {
        initialize() {
            let parametersPagination = {
                filter: {
                    CpyID: 0,
                    SupID: this.SupID
                },
                draw: 1,
                start: 0,
                length: 1000000,
                order: [{
                    dir: "asc",
                    column: "",
                }, ],
                search: "",
            }
            _sGenSupplierBanks.pagination(parametersPagination).then(resp => {
                this.items = resp.data.Result.data
                console.log(resp.data.Result.data);
            })
        },

        //Fila
        rowClick: function (item, row) {
            this.selected = [item];
        },

        save() {
            //Validamos Campos
            var resp = _validaERP.valida({
                form: "#frmsupplierBanks",
                items: {
                    TypeBank: {
                        required: true,
                        value: this.itemPbk.TypeBank,
                    },
                    TypeCurrency: {
                        required: true,
                        value: this.itemPbk.TypeCurrency,
                    },
                    PbkBill: {
                        required: true,
                        number: true,
                        minlength: 10,
                        value: this.itemPbk.PbkBill,
                    },
                    PbkBeneficiaryName: {
                        required: true,
                        value: this.itemPbk.PbkBeneficiaryName,
                    },
                },
            });

            if (resp) {
                this.itemPbk.SupID = this.SupID;
                console.log("save. bank", this.itemPbk);

                this.itemPbk.SecStatus = 1;
                this.itemPbk.UsrCreateID = this.$fun.getUserID();

                this.$fun.alert("¿Seguro de grabar?", "question", false).then(val => {
                    if (val.value) {
                        _sGenSupplierBanks.save(this.itemPbk, 0).then((resp) => {
                            if (resp.status == 200) {
                                this.initialize()
                                this.dialogPbk = false
                            }
                        });
                    }
                })

            }
        },
        remove(item) {
            this.$fun.alert("¿Seguro de eliminar?", "question", false, "center").then(val => {
                if (val.value) {
                    item.SecStatus = 0;
                    item.UsrUpdateID = this.$fun.getUserID();
                    _sGenSupplierBanks.delete(item, 0).then((resp) => {
                        if (resp.status == 200) {
                            this.initialize()
                        }
                    });
                }
            })
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
