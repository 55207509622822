<template>
<div>

    <v-row justify="center">

        <!-- <v-col cols="12"> -->
        <!--  <v-card elevation="0" style="margin-top: 8px;border-radius:10px">
            <v-card-title class="ml-3" style="font-size:11px">
                <b>Leyenda</b>
                <v-divider class="mr-4 ml-1"></v-divider>
                <ul>

                    <li>
                        <v-icon color="" style="font-size:16px;">
                            fa-solid fa-paper-plane
                        </v-icon> | Subir Cotización
                    </li>

                    <li>
                        <v-icon color="green" @click="openFile({name: 'ManualProveedor.pdf'})">mdi-download</v-icon> | <a style="text-decoration:">Descargar Manual</a>
                    </li>
                </ul>
                <br>

            </v-card-title>
        </v-card> -->
        <v-card elevation="0" style="margin-top: 2px;border-radius:10px" class="mt-2">
            <div class="card-body">

                <h4 class="card-title" style="font-size: 16px;">COTIZACIONES</h4>

                <v-divider></v-divider>
                <div class="px-0 pt-0 text-left  px-lg-0">
                    <p class="mx-auto mb-0 text-sm">
                        <a href="https://www.youtube.com/watch?v=cJsFPeCBUeM" style="text-decoration:underline" target="_blank" class="font-weight-bold">Cómo subir mi cotización!</a>
                    </p>
                    <!-- <p class="mx-auto mb-0 text-sm">
                        <a href="https://www.youtube.com/watch?v=cJsFPeCBUeM" style="text-decoration:underline" target="_blank" class="font-weight-bold">Cómo subir mis comprobantes de pago!</a>
                    </p> -->
                </div>
                <!--                     <b>Leyenda</b>
                    <v-divider vertical class="ml-2"></v-divider> -->
                <v-row>
                    <v-card elevation="0" style="margin-top: 2px;border-radius:10px">
                        <div class="card-body">
                            <h4 class="card-title">Filtro</h4>
                            <input type="text" placeholder="Search" v-model="search" class="form-control">
                        </div>

                        <v-data-table class="elevation-0 table-condensed table-bordered" disable-sort :search="search" fixed-header :items=items height="auto" @click:row="rowClick" v-model="selected" item-key="CttID" dense :headers=headers :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 50, 100],
          }">

                            <template v-slot:item.CttDate="{ item }">
                                {{$moment(item.CttDate).format($const.FormatDateDB)}}
                            </template>
                            <template v-slot:item.NeedDateCot="{ item }">
                                {{$moment(item.NeedDateCot).format($const.FormatDateDB)}}
                            </template>

                            <template v-slot:item.CtpDateCotizacionReceived="{ item }">
                                <div v-if="item.CtpStatus == 2">{{$moment(item.CtpDateCotizacionReceived).format($const.FormatDateTimeDB)}}</div>
                            </template>

                            <template v-slot:item.CttStatusName="{ item }">
                                <v-chip x-small :color="item.CttStatusColor">
                                    {{ item.CttStatusName }}<span>
                                    </span>
                                </v-chip>
                            </template>
                            <template v-slot:item.CttRequiredVisit="{ item }">
                                <v-chip v-if="item.CttRequiredVisit == 1" :color="item.CttRequiredVisit == 1 ? 'info': ''" x-small>{{item.CttRequiredVisit == 1 ? 'Requiere visita Campo': ''}}</v-chip>
                                <!-- <v-chip x-small v-if="item.CttScheduledDate !== null">{{$moment(item.CttScheduledDate).format($const.FormatDateTimeDB)}}</v-chip> -->
                                <!-- <v-chip v-if="item.CttRequiredVisit == 1" :color="item.CtpVisitAproved == 1 ? 'info': 'error'" x-small>{{item.CtpVisitAproved == 1 ? 'OK': 'Por Aprobar'}}</v-chip> -->
                            </template>

                            <template v-slot:item.send="{ item }">
                                <v-btn v-if="item.CttStatus !== 1 && item.CttStatus !== 3 && item.CttStatus !== 5" title="Subir Cotizacion" elevation="0" style="text-transform:capitalize" small icon @click="show(item)">
                                    <v-icon style="font-size: 16px;color:green" color="">fa-solid fa-paper-plane</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:item.CtpStatus="{ item }">
                                <v-chip :title="item.CtpStatus == 2 ? 'Cotización enviada correctamente' : ''" x-small :color="item.CtpStatus == 2 ? 'info' : 'warning'">
                                    {{ item.CtpStatus == 2 ? 'Si' : 'No' }}
                                </v-chip>
                            </template>
                            <template v-slot:item.state="{ item }">
                                <v-chip title="Estado ganador de concurso" v-if="item.WinID > 0 && item.CtpID == item.CtpWinner && item.CttStatus == 1" :color="'success'" x-small>Aceptada
                                </v-chip>
                                <v-chip v-if="item.WinID > 0 && item.CtpID !== item.CtpWinner && item.CttStatus == 1" :color="'warning'" x-small>No aceptada
                                </v-chip>

                            </template>
                            <template v-slot:item.Attach1="{ item }">
                                <i v-if="item.Attach1!==null && item.CtpStatus == 2" class="fa-solid fa-download mr-2" style="color:green" @click="downloadFile(item.Attach1)"></i>
                                <div v-if="item.CtpStatus == 2">{{item.Attach1}}</div>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-row>
            </div>
        </v-card>
        <!-- </v-col> -->

    </v-row>

    <v-dialog v-if="dialog" v-model="dialog" persistent>
        <sup-cotization-upload :value="item.CttID" :provider="provider" @close="dialog=false" />
    </v-dialog>

</div>
</template>

<script>
//Services
import _sPortal from "@/services/PortalProviders/SupPortalProvidersService";
import _sGenSupplier from "@/services/General/GenSupplierService.js";
import _sHelper from "@/services/HelperService.js";

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
import SupCotizationUpload from './SupCotizationUpload.vue';

export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon,
        SupCotizationUpload,

    },
    props: {},
    data() {

        return {

            //Loading
            processing: false,
            errors: null,

            filter: {},
            headers: [
                /* {
                    text: "ID",
                    value: "CttID"
                }, */
                {
                    text: "Estado Cotización",
                    value: "state"
                },
                {
                    text: "Subir Cot.",
                    value: "send",
                    width: 5,
                    align: "center"
                },
                /* {
                    text: "Subir Adj.",
                    value: "attach",
                    width: 5,
                    align: "center"
                }, */
                {
                    text: "Nro. Cotizacion",
                    value: "CttDocumentNumber",
                    width: 120
                },
                /* {
                    text: "Nro. Orden",
                    value: "OrdDocumentNumber",
                    width: 120
                }, */
                {
                    text: "Inicio Proceso",
                    value: "CttDate",
                    width: 120
                },
                {
                    text: "Fin Proceso",
                    value: "NeedDateCot",
                    width: 120
                },
                /* {
                    text: "Visita Campo",
                    value: "CttRequiredVisit",
                    width: 200
                }, */
                {
                    text: "Adjunto",
                    value: "Attach1",
                    width: 200
                },
                {
                    text: "Envió cotización",
                    value: "CtpStatus",
                    width: 90,
                    align: "center"

                },
                {
                    text: "Cotización enviada el",
                    value: "CtpDateCotizacionReceived",
                    width: 100,
                    align: "center"

                },
                {
                    text: "Estado Proceso",
                    value: "CttStatusName",
                    align: "center",
                    width: 120
                },
                {
                    text: "Motivo Anulación",
                    value: "TypeAnulatedName",
                    align: "center",
                    width: 150
                },
                /* {
                    text: "Fecha E.",
                    value: "SecCreate"
                }, */
            ],

            search: "",
            selected: [],
            items: [],

            //Modal Cotizacion
            item: {},
            dialog: false,
            provider: {}

        };
    },

    mounted() {
        this.initialize()
        this.initializeProvider()
    },

    created() {

    },

    computed: {

    },

    methods: {
        initialize() {
            this.processing = true
            _sPortal.byruc(this.$fun.getUserName()).then(resp => {
                    if (resp.status == 200) {
                        console.log(resp.data.Result);
                        this.items = resp.data.Result;
                        this.processing = false
                    }
                },
                (e) => {

                    this.errors = e.response
                    this.processing = true;
                })
        },

        initializeProvider() {
            let supplier = {
                ruc: this.$fun.getUserName()
            }
            _sGenSupplier
                .searchbyruc(supplier)
                .then(resp => {
                        if (resp.status == 200) {
                            this.provider = resp.data.Result
                            console.log("provider", resp.data.Result);
                        }
                    },
                    (e) => {})
        },

        rowClick: function (item, row) {
            this.selected = [item];
        },
        show(item) {
            if (this.provider.Banks.length <= 0) {
                this.$fun.alert("Debe registrar 1 entidad bancaria.", "warning", false, "center")
                return
            }

            if (this.provider.SupAttachDJ == null) {
                this.$fun.alert("Debe adjuntar Declaración Jurada.", "warning", false, "center")
                return
            }

            this.item = item
            this.dialog = true
        },

        downloadFile(url) {
            console.log("fileeeeeeee", url);
            if (url == null) return;
            _sHelper.download(url, 3).then((r) => {
                this.$fun.download(r.data, url);
            });
        }
    },
};
</script>

<style lang="scss" scoped>
@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}

.bounce {
    animation: bounce 1s infinite;
}
</style>
