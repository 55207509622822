<template>
<div>
    <vc-header title=""></vc-header>
    <v-row style="margin:auto">

        <v-card class="mt-1 pb-8" outlined>

            <!-- <v-tabs height="35" @change="changeTab($event)" slider-size="9" hide-slider="" centered="" v-model="currentItem">
                <v-tab v-for="item in items" :key="item" :href="'#tab-' + item">
                    {{ item }}</v-tab>
            </v-tabs> -->
            <v-tabs height="45" @change="changeTab($event)" slider-size="9" hide-slider="" centered="" v-model="currentItem">

                <v-tab v-for="item in items" :key="item" :href="'#tab-' + item">
                    <!-- <v-icon left>
                        mdi-account
                    </v-icon> -->
                    {{ item }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="currentItem" style="margin: 8px 0px 10px 10px">
                <v-tab-item v-for="item in items" :key="item" :value="'tab-' + item">
                    <!-- <v-divider></v-divider> -->
                    <v-row v-if="item == 'Información General'">

                        <v-row>
                            <form id="supplier">
                                <v-row class="mt-3 mb-4">
                                    <v-card elevation="0" style="border-bottom: inset; border-top: outset">
                                        <v-card-title style="margin-top: -10px; margin-bottom: -15px">
                                            <h6>Datos Generales.</h6>
                                        </v-card-title>
                                    </v-card>

                                    <v-col lg="6" md="6" cols="12" class="s-col-form">
                                        <vc-text number disabled outlined id="SupDocumentNumber" v-model="Supplier.SupDocumentNumber" label="Numero de Documento">
                                        </vc-text>
                                    </v-col>

                                    <v-col lg="6" md="6" cols="12" class="s-col-form">
                                        <vc-select-definition clearable noDefault id="TypeOfTaxRegime" v-model="Supplier.TypeOfTaxRegime" :def="1019" label="Tipo de Regimen Tributario">
                                        </vc-select-definition>
                                    </v-col>

                                    <v-col cols="12" class="s-col-form">
                                        <vc-text autofocus id="SupName" v-model="Supplier.SupName" label="Razón Social">
                                        </vc-text>
                                    </v-col>

                                    <v-col cols="12" class="s-col-form">
                                        <vc-textarea id="SupAddress" v-model="Supplier.SupAddress" label="Dirección Fiscal">
                                        </vc-textarea>
                                    </v-col>
                                    <v-col cols="12" class="s-col-form">
                                        <vc-text id="SupConName" v-model="Supplier.SupConName" label="Contacto">
                                        </vc-text>
                                    </v-col>

                                    <v-col lg="6" md="6" cols="12" class="s-col-form">
                                        <vc-text id="SupTradeName" v-model="Supplier.SupTradeName" label="Giro Comercial">
                                        </vc-text>
                                    </v-col>

                                    <v-col lg="6" md="6" cols="12" class="s-col-form">
                                        <vc-Date clearable id="SupDateConstitute" v-model="Supplier.SupDateConstitute" label="Fecha de Constituido">
                                        </vc-Date>
                                    </v-col>

                                    <v-col lg="6" md="6" cols="12" class="s-col-form">
                                        <vc-text number id="SupTelephone" v-model="Supplier.SupTelephone" label="Telefono">
                                        </vc-text>
                                    </v-col>
                                    <v-col lg="6" md="6" cols="12" class="s-col-form">
                                        <vc-text id="SupEmail" v-model="Supplier.SupEmail" label="Correo Electronico">
                                        </vc-text>
                                    </v-col>
                                    <v-col cols="12" class="s-col-form">
                                        <vc-text id="SupMainItem" v-model="Supplier.SupMainItem" label="Articulo Principal">
                                        </vc-text>
                                    </v-col>

                                </v-row>
                                <v-row>
                                    <v-col lg="6" md="6" cols="12">
                                        <v-row>
                                            <v-card elevation="0" style="border-bottom: inset; border-top: outset" width="100%">
                                                <v-card-title style="margin-top: -10px; margin-bottom: -15px">
                                                    <h6>Comprobantes de Pago.</h6>
                                                </v-card-title>
                                            </v-card>

                                            <v-col cols="12">

                                                <v-checkbox style="margin-bottom: -30px; margin-top: -10px" v-model="Supplier.SupB" label="Boleta" color="red" hide-details></v-checkbox>
                                                <v-checkbox style="margin-bottom: -30px" v-model="Supplier.SupF" label="Factura" color="red" hide-details></v-checkbox>
                                                <v-checkbox style="margin-bottom: -30px" v-model="Supplier.SupRH" label="Recibo por Honorarios" color="red" hide-details></v-checkbox>

                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col lg="6" md="6" cols="12">
                                        <v-row>
                                            <v-card elevation="0" style="border-bottom: inset; border-top: outset" width="100%">
                                                <v-card-title style="margin-top: -10px; margin-bottom: -15px">
                                                    <h6>Venta.</h6>
                                                </v-card-title>
                                            </v-card>
                                            <v-col cols="12">

                                                <v-checkbox style="margin-bottom: -30px; margin-top: -10px" v-model="Supplier.SupServices" label="Servicios" color="red" hide-details></v-checkbox>

                                            </v-col>
                                        </v-row>
                                        <v-row>

                                        </v-row>
                                    </v-col>

                                </v-row>
                            </form>
                        </v-row>

                        <v-row>
                            <v-row class="mt-9 mb-4">
                                <v-card elevation="0" style="border-bottom: inset; border-top: outset">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -15px">
                                        <h6>Declaración Juarada.</h6>
                                        <label>
                                            <i v-if="Supplier.SupAttachDJ!==null" class="fa-solid fa-download mr-2" style="color:green" @click="downloadFile"></i>
                                            {{Supplier.SupAttachDJ}}</label>
                                    </v-card-title>
                                    <div class="pt-0 text-left pl-4 pb-9">
                                        <p class="mb-0 text-sm">
                                            <a style="text-decoration:underline" class="font-weight-bold" @click="openDJ('formato-declaracion-juarada-imp.docx')">Descargar Formato.</a>
                                        </p>

                                    </div>
                                </v-card>

                            </v-row>

                            <v-col cols="12" style="margin-top:-30px">

                                <b-card title="" class="main-card mb-3">
                                    <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-success="fileUploaded"></vue-dropzone>
                                </b-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" style="margin-top:-30px">

                                <b-card title="" class="main-card mb-3">
                                    <v-btn elevation="0" outlined small color="info" @click="saveSup">Actualiar datos</v-btn>
                                </b-card>
                            </v-col>
                        </v-row>
                    </v-row>

                    <v-row v-if="item == 'Actividades Economicas'">
                        <gen-supplier-economic-activities />
                    </v-row>

                    <v-row v-if="item == 'Bancos'">
                        <gen-supplier-bank v-if="Supplier.SupID > 0" :SupID="Supplier.SupID" />
                    </v-row>

                    <!-- <v-row v-if="item == 'Representantes Legales'">

                    </v-row> -->

                </v-tab-item>

            </v-tabs-items>

        </v-card>
    </v-row>
</div>
</template>

<script>
//Services
import _sGenSupplier from "@/services/General/GenSupplierService.js";
import _sHelper from "@/services/HelperService.js";

import _validaERP from "@/plugins/validaERP";
import GenSupplierEconomicActivities from './GenSupplierEconomicActivities.vue';
import GenSupplierBank from './GenSupplierBank.vue';

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
    components: {
        GenSupplierEconomicActivities,
        GenSupplierBank,
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            Supplier: {
                SupDateConstitute: "",
                SupID: 0
            },

            tabSelected: "",
            currentItem: "tab-Usuarios",
            //items: ["Información General"],
            //items: ["Información General", "Actividades Economicas", "Bancos", "Representantes Legales"],
            items: ["Información General", "Bancos"],
            dropzoneOptions: {
                url: "https://190.223.56.84:8183/api/v1.0/helper/dropzone?parameter=" + 7,
                thumbnailWidth: 150,
                maxFilesize: 2,
                headers: {
                    "My-Awesome-Header": "header value"
                },
                acceptedFiles: '.pdf,.docx',
                maxFiles: 1, // Limita la cantidad máxima de archivos a 1
            },
        };
    },

    created() {
        this.initialize()
    },
    mounted() {},
    watch: {},

    methods: {
        initialize() {
            let supplier = {
                ruc: this.$fun.getUserName()
            }
            _sGenSupplier
                .searchbyruc(supplier)
                .then(resp => {
                        if (resp.status == 200) {
                            this.Supplier = resp.data.Result;
                        }
                    },
                    (e) => {})
        },

        changeTab(n) {
            this.tabSelected = n;
        },

        saveSup(item) {
            //Validamos Campos
            var resp = _validaERP.valida({
                form: "#supplier",
                items: {
                    SupDocumentNumber: {
                        required: true,
                        minlength: 11,
                        maxlength: 11,
                        value: this.Supplier.SupDocumentNumber,
                    },
                    SupName: {
                        required: true,
                        minlength: 3,
                        value: this.Supplier.SupName,
                    },
                    SupAddress: {
                        required: true,
                        minlength: 3,
                        value: this.Supplier.SupAddress,
                    },
                    SupTelephone: {
                        required: true,
                        number: true,
                        value: this.Supplier.SupTelephone,
                    },
                    SupEmail: {
                        required: true,
                        email: true,
                        value: this.Supplier.SupEmail,
                    },
                },
            });

            console.log("save.", this.Supplier);
            if (resp) {
                this.Supplier.SecStatus = 1;
                this.$fun.alert("¿Seguro de actualizar datos?", "question", false, "center")
                    .then(val => {
                        if (val.value) {
                            _sGenSupplier
                                .save(this.Supplier, this.$fun.getUserID())
                                .then(resp => {
                                    if (resp.status == 200) {

                                        this.$fun.sweetAlert("Guardado Correctamente", "success");
                                        this.initialize()

                                    }
                                })
                        }
                    })
            }
        },

        fileUploaded(file, response) {
            this.Supplier.SupAttachDJ = response[0].RqdAttach
            this.saveSup()
        },
        downloadFile() {
            if (this.Supplier.SupAttachDJ == null) return;
            _sHelper.download(this.Supplier.SupAttachDJ, 3).then((r) => {
                this.$fun.download(r.data, this.Supplier.SupAttachDJ);
            });
        },
        openDJ(name) {
            if (name == null) return;
            _sHelper.download(name, 3).then((r) => {
                this.$fun.download(r.data, name);
            });
        }
    },
};
</script>

<style lang="scss" scoped></style>
