<template>
<div>

    <vc-loading :processing="processing" :errors="errors" :title="'Cargando datos.'" @close="processing=false;" />
    <v-dialog v-model="showDeuda" v-if="showDeuda && customer.operacionComercialDeuda.length > 0" max-width="500" persistent>

        <div class="main-card mb-3 card">

            <div class="card-header">
                <i class="header-icon fa-regular fa-credit-card icon-gradient bg-plum-plate"></i>Recordatorio de deuda
            </div>
            <!-- <v-card style="max-height: 450px;  overflow-y: auto;"> -->
            <v-img cover class="white--text align-end" height="200px" src="./../../assets/images/cobranzas/img02.png">
            </v-img>
            <div class="card-body ml-4">
                <b style="color:#03296a">Hola estimado {{customer.nombreCompleto}}</b>
                <br>
                <p style="color:#3d5783">Le informamos que su deuda a la fecha es.</p>

                <div class="card no-shadow rm-border bg-transparent widget-chart text-center" style="border-radius: 20px;margin-top:-40px" v-for="oc in customer.operacionComercialDeuda" :key="oc.idProforma">

                    <div class="widget-chart-content ml-3">
                        <div class="widget-numbers" style="font-size:35px;color: #faa919;">
                            <span class="mr-4" style="font-size:25px;">{{oc.nroInmueble_1}} <i class="v-icon material-icons theme--light text-success text--darken-2 bounce iconhome" style="font-size: 34px;" @click="redirect(oc);showDeuda=false">payment</i></span><br><span class="mr-4">USD {{oc.sumaVencido.toLocaleString("es-MX", {
                                                                                                        minimumFractionDigits: 2,
                                                                                                        maximumFractionDigits: 2,
                                                                                                    })}} </span>
                        </div>
                    </div>
                    <v-divider />
                </div>

            </div>
            <div class="card-body" style="margin-top:-40px">
                <v-img class="white--text align-end" src="./../../assets/images/cobranzas/imgbancos.png">
                </v-img>
            </div>
            <div class="main-card mb-0 card">
                <div class="card-body">
                    <i class="fa-solid fa-location-dot"></i>
                    <span style="font-size:10px;color:#3d5783" class="ml-1">OFICINA DE CREDITOS Y COBRANZAS</span>

                </div>

            </div>
            <!-- </v-card> -->

            <vc-footer close @close="showDeuda=false"></vc-footer>
        </div>
    </v-dialog>

    <v-dialog v-model="showPromotion" v-if="showPromotion && advertisement.length > 0" max-width="600" persistent>

        <div class="main-card mb-3 card">
            <div class="card-header">
                <i class="header-icon lnr-laptop-phone icon-gradient bg-plum-plate"></i>Anuncios
            </div>
            <b-card title="" class="main-card mb-3">
                <b-carousel id="carousel1" style="text-shadow: 1px 10px 2px #333;" controls indicators background="#ababab" :interval="3000" img-width="1024" img-height="780" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
                    <b-carousel-slide v-for="adv in advertisement" :key="adv.AdvID">
                        <!-- :img-src="adv.AdvSrc" v-for="adv in advertisement" :key="adv.AdvID" @click="window.location.href = 'https://www.google.com';" -->
                        <template #img>
                            <img class="d-block img-fluid w-100" width="1024" height="480" :src="adv.AdvSrc" alt="" @click="redirectTo(adv)">
                        </template>
                    </b-carousel-slide>
                </b-carousel>
            </b-card>
            <vc-footer close @close="showPromotion=false;showDeuda=true"></vc-footer>
        </div>
    </v-dialog>

    <v-row class="mt-4" justify="center">

        <v-card elevation="0" style="margin-top: 2px;border-radius:10px">
            <v-row justify="center">
                <v-col cols="12" style="text-align-last: center;">
                    <h1 style="color:#1f4d9d;font-family: Calibri">¡BIENVENIDO!</h1>
                    <br>
                    <h3 style="color:#16a94b;margin-top:-20px;font-family:;font-weight: 700;" class="capitalize">{{customer.nroDocumentoTitular == null ? "ERROR AL CARGAR DATOS DE CLIENTE": customer.nombreCompleto}}</h3>
                </v-col>
            </v-row>

            <div class="mb-3 card mt-6">
                <div class="card-header-tab card-header">
                </div>
                <div class="no-gutters row">
                    <div class="col-sm-6 col-md-4 col-xl-4">
                        <div class="card no-shadow rm-border bg-transparent widget-chart text-left ">
                            <div class="icon-wrapper iconhome" @click="showCustomer=true;showOC=false;showNews=false;showSchedule=false;showQuestion=false">
                                <div class="icon-wrapper-bg opacity-10 bg-warning "></div>
                                <i class="lnr-user text-white"></i>
                            </div>
                            <div class="widget-chart-content">
                                <div class="widget-subheading">....</div>
                                <div class="widget-numbers" style="font-size: 24px;">Datos Personales</div>
                            </div>
                        </div>
                        <div class="divider m-0 d-md-none d-sm-block"></div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-xl-4">
                        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                            <!-- <div class="icon-wrapper iconhome" @click="redirect"> -->
                            <div class="icon-wrapper iconhome" @click="scrollToProperties()">
                                <div class="icon-wrapper-bg opacity-9 " style="background:#1f4d9d"></div>
                                <i class="lnr-home text-white"></i>
                            </div>
                            <div class="widget-chart-content">
                                <div class="widget-subheading">....</div>
                                <div class="widget-numbers" style="font-size: 24px;">
                                    <span>Mis Propiedades</span>
                                </div>
                            </div>
                        </div>
                        <div class="divider m-0 d-md-none d-sm-block"></div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-xl-4">
                        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                            <div class="icon-wrapper iconhome" @click="showCustomer=false;showOC=false;showNews=true;showSchedule=false;showQuestion=false">
                                <div class="icon-wrapper-bg opacity-9 bg-success"></div>
                                <i class="lnr-license text-white"></i>
                            </div>
                            <div class="widget-chart-content">
                                <div class="widget-subheading">Proximamente</div>
                                <div class="widget-numbers text-success" style="font-size: 24px;">
                                    <span>Novedades</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center d-block p-3 card-footer mt-7">
                    <h6>Gracias por ser parte de nuestra familia a través de nuestra intranet, donde los pagos son más simples que nunca.</h6>
                    <button class="btn btn-primary btn-md" @click="showQuestion=true;showCustomer=false;showOC=false;showNews=false;showSchedule=false;">
                        <i class="fas fa-web"></i>
                        <span class="mr-1"><a target="_blank" style="color:white">Preguntas frecuentes</a></span>
                    </button>
                    <button class="btn btn-success btn-md ml-2">
                        <i class="fa fa-vr-cardboard"></i>
                        <span class="ml-2"><a href="https://imperu.com.pe/vista360/" target="_blank" style="color:white">Recorrido Virtual 360°</a></span>
                    </button>
                </div>
            </div>
        </v-card>

        <!-- Datos del cliente -->
        <pag-customer :item="customer" v-if="showCustomer" />

        <!-- Operaciones Comerciales -->

        <v-col cols="12" v-show="showOC" id="mispropiedades">
            <v-card elevation="0" style="margin-top: 2px;border-radius:10px">
                <div class="card-body">

                    <h4 class="card-title" style="font-size: 16px;">MIS PROPIEDADES</h4>
                    <v-divider></v-divider>
                    <v-row>

                    </v-row>
                </div>
            </v-card>
            <div>

                <v-card style="border-radius:10px" elevation="0" class="pb-3 mt-3" v-for="item in customer.operacionComercial" :key="item.correlativo">
                    <v-col cols="12" lg="10" md="10">
                        <v-row style="margin:auto" justify="center">

                            <v-col cols="12" lg="3" md="4">

                                <div class="mb-3 mt-0">
                                    <div class="card-header-tab card-header">
                                        <a @click="redirect(item)" style="text-decoration:underline">{{item.correlativo}}</a>
                                        <v-chip class="ml-2" x-small :color="item.estadoOC == 'DEVUELTO' ? 'error': 'success'">{{item.estadoOC}}</v-chip>
                                    </div>
                                    <div class="no-gutters row" style="align-self: center;">

                                        <div class="col-sm-12 col-md-12 col-xl-12">
                                            <div class="card no-shadow rm-border bg-transparent widget-chart text-left" style="border-radius: 20px;">
                                                <div class="icon-wrapper " style="border-radius:7px">
                                                    <div class="icon-wrapper-bg opacity-9" style="background:#1f4d9d"></div>
                                                    <i class="lnr-home text-white"></i>
                                                </div>
                                                <div class="widget-chart-content ml-3">
                                                    <div class="widget-subheading">{{item.tipoInmueble_1}}</div>
                                                    <span>
                                                        <h3>{{item.nroInmueble_1}}</h3>
                                                    </span>
                                                    <!-- <div class="widget-numbers" style="width: max-content;font-size:28px">
                                                    <span>{{item.nroInmueble_1}}</span>
                                                </div> -->
                                                </div>
                                            </div>
                                            <div class="divider m-0 d-md-none d-sm-block"></div>
                                        </div>

                                    </div>

                                </div>

                            </v-col>
                            <v-col cols="12" lg="9" md="8" class="mt-0" style="margin-top:-20px">

                                <div class="mb-2 card mt-0">
                                    <div class="card-header-tab card-header">
                                        <button class=" btn btn-secondary btn-md mr-2 pt-2" @click="redirect(item)" style="background-color: #2dce89;">
                                            <!-- <button class=" btn btn-secondary btn-md mr-2" @click="selectedOC(item)"> -->

                                            <span class="mr-2 opacity-7">
                                                <i class="fa-regular fa-credit-card"></i>
                                            </span>

                                            <span class="mr-1"><a target="_blank" style="color:white">Pagar</a></span>
                                        </button>
                                        <button class="btn btn-secondary btn-md" v-if="item.ubicacionGps.length > 0">
                                            <span class="mr-2 opacity-7">
                                                <i class="fa-solid fa-location-dot"></i>
                                            </span>

                                            <span class="mr-1"><a :href="item.ubicacionGps" target="_blank" style="color:white">Ubica tu lote</a></span>
                                        </button>

                                    </div>
                                    <div class="no-gutters row" style="align-self: center;">

                                        <div class="col-sm-12 col-md-12 col-xl-12">
                                            <div class="card no-shadow rm-border bg-transparent widget-chart text-left" style="border-radius: 20px;">

                                                <div class="widget-chart-content ml-3">
                                                    <h4 style="color:#1f4d9d">{{item.proyecto}} || {{item.etapa}}</h4>
                                                </div>
                                            </div>
                                            <div class="divider m-0 d-md-none d-sm-block"></div>

                                        </div>

                                    </div>

                                </div>
                            </v-col>

                        </v-row>
                    </v-col>
                </v-card>
            </div>

        </v-col>

        <!-- Noticias -->
        <v-col cols="12" v-if="showNews">
            <v-card elevation="0" style="margin-top: 2px;border-radius:10px">
                <div class="card-body">

                    <h4 class="card-title" style="font-size: 16px;">NOVEDADES</h4>
                    <v-divider></v-divider>
                    <v-row>

                    </v-row>
                </div>
            </v-card>
        </v-col>

        <!-- Preguntas Frecuentes -->
        <pag-question-frecuent v-if="showQuestion" />

        <!-- Cronograma -->
        <!-- <pag-schedule :item="itemOC" v-if="showSchedule" @return="showSchedule=false;showOC=true;itemOC:{}" :customer="customer" /> -->

        <!-- Notificaciones -->
        <!-- <vc-notification /> -->
    </v-row>
</div>
</template>

<script>
import _sPayGateway from '@/services/PaymentGateway/PagPayGatewayService.js'
import PagSchedule from './PagSchedule.vue'
import PagCustomer from './PagCustomer.vue'
import PagQuestionFrecuent from './PagQuestionFrecuent.vue'

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon,
        PagSchedule,
        PagCustomer,
        PagQuestionFrecuent
    },
    props: {
        UsrName: {
            type: String,
            default: ""
        }
    },
    data() {

        return {
            customer: {
                nroDocumentoTitular: null
            },
            advertisement: [],
            itemOC: {},
            //question: [],

            //Loading
            processing: false,
            errors: null,
            showOC: false,
            showCustomer: false,
            showNews: false,
            showSchedule: false,
            sliding: null,

            //
            showPromotion: false,
            showDeuda: false,
            showQuestion: false,

        };
    },

    /* mounted() {

    }, */

    mounted() {
        this.initialize()
    },

    methods: {
        initialize() {
            this.processing = true
            _sPayGateway.getCustomer(this.$fun.getUserName()).then(resp => {
                    if (resp.status == 200) {
                        this.processing = false

                        this.customer = resp.data.Result.customer

                        if (this.customer.operacionComercial == null) {
                            this.customer.operacionComercial = []
                        }

                        if (this.customer.operacionComercialDeuda == null) {
                            this.customer.operacionComercialDeuda = []
                        }
                        this.advertisement = resp.data.Result.advertisement
                        //this.question = resp.data.Result.question

                        localStorage.removeItem("UsrFullName");
                        localStorage.setItem("UsrFullName", this.customer.nombreCompleto);

                        this.showPromotion = true

                        if (this.advertisement.length <= 0) this.showDeuda = true;

                    }
                },
                (e) => {

                    this.errors = e.response
                    this.processing = true;
                })
        },
        selectedOC(item) {
            if (item !== null) {
                this.itemOC = item
                this.showSchedule = true
                this.showOC = false
            }

        },

        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = false;
        },

        redirect(item) {
            if (item !== null) {
                localStorage.removeItem("PagItem");
                localStorage.removeItem("PagCustomer");
                localStorage.setItem("PagItem", JSON.stringify(item))
                localStorage.setItem("PagCustomer", JSON.stringify(this.customer))

                //this.$router.push({ path: '/payment/schedule/', query: { item: JSON.stringify(item), customer : JSON.stringify(this.customer) } });
                this.$router.push('/payment/schedule/');
            }

        },

        redirectTo(item) {
            if (item.AdvRedirection !== null) {
                window.open(item.AdvRedirection, '_blank');
            }
        },

        scrollToProperties() {

            this.showOC = true;
            this.showCustomer = false;
            this.showNews = false;
            this.showSchedule = false;
            this.showQuestion = false;
            const misPropiedadesDiv = document.getElementById("mispropiedades");
            // Calculamos la posición del elemento con respecto a la ventana
            const offsetTop = misPropiedadesDiv.getBoundingClientRect().top;

            // Desplazamos la página para que el div sea visible
            window.scrollTo({
                top: window.scrollY + offsetTop,
                behavior: "smooth" // Agregamos transición suave si lo deseas
            });

        }
    },
};
</script>

<style lang="scss" scoped>
@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}

.bounce {
    animation: bounce 1s infinite;
}

.iconhome {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    /* Tipo de puntero predeterminado cuando no se pasa el mouse */
}

.iconhome:hover {
    cursor: pointer;
    /* Tipo de puntero cuando el mouse se pasa sobre el icono */
}
</style>
