import Service from "../Service";

const resource = "LgsOrderPurcharseVoucherDetail/";

export default {

    update(obj) {
        return Service.put(resource + "update", obj, {
            params: {},
        });
    },

    active(obj) {
        return Service.post(resource + "active", obj, {
            params: {},
        });
    },

};