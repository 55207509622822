import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    mutations: {
        setTimeToken(state, n) {
            state.TimeToken = n;
        },
        setShowModal(state, value) {
            state.showModal = value;
        },
        setTime(state, time) {
          state.time = time;
        },
        setIsTimerRunning(state, value) {
          state.isTimerRunning = value;
        },

        setItemData(state, itemData) {
            state.itemData = itemData;
        },
        setCustomerData(state, customerData) {
            state.customerData = customerData;
        }
    },
    getters: {
        getItemData: state => state.itemData,
        getCustomerData: state => state.customerData,
    },
    actions: {},
    modules: {},
    state: {
        hideConfigButton: false,
        showConfig: false,
        showSidenav: true,
        showNavbar: true,
        showFooter: true,
        layout: "default",
        showModal: false,
        time: 10000,
        isTimerRunning: false,
        customerData: null,
        itemData: null
    },
});