<template>
<div>
    <v-label><b>{{label}}</b></v-label>
    <vc-select-generic style="margin-top:-18px" autocomplete class="text" itemtext="GenDescription" itemvalue="GenID" id="GenDescription" :value="value" @input="input($event)" :config="config" :full="full" :text="text" :clearable=clearable :noDefault=noDefault>
    </vc-select-generic>
</div>
</template>

<script>
import _sGenericService from "@/services/General/GenGenericService";
export default {
    name: "SelectPosition",
    components: {},
    props: {
        clearable: {
            type: Boolean,
            default: false,
        },
        value: null,
        text: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "",
        },
        noDefault: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
        areID: 0
    },
    watch: {
        value(value) {
            this.item = value;
        },
    },
    methods: {
        input(val) {
            this.$emit("input", val);
        },

    },
    data: () => ({
        item: 0,
        val: '',
        items: [],
        config: {},
    }),

    created() {
     
        this.config = {
            url: _sGenericService.getUrlPosition(),
            title: this.label,
            params: {
                CpyID: localStorage.getItem('CpyID')
                /* search: '',
                requestID: this.$fun.getUserID() */
            }
        }

    }

}
</script>
