<template>
<div>
    <v-row class="mb-3">
        <v-col cols="12">
            <v-card outlined elevation="0" height="auto">
                <vc-header-title />

                <v-row style="margin: auto">
                    <v-col cols="12" style="margin-top: -10px" class="pb-6">
                        <v-data-table class="table-bordered" dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="Line" :height="$vuetify.breakpoint.xs ? 'auto' : '200'" hide-default-footer>
                            <template v-slot:item.OddUnitPrice="props">
                                <div v-if="value > 0">
                                    {{parseFloat(props.item.OddUnitPrice) > 0 ? parseFloat(props.item.OddUnitPrice).toFixed(2) : props.item.OddUnitPrice}}
                                </div>
                                <vc-text v-else style="width: 125px" decimal @input="changeItem(props.item)" v-model="props.item.OddUnitPrice">
                                    Precio Unitario
                                </vc-text>
                            </template>
                            <template v-slot:item.OddQuantity="props">
                                <div v-if="value > 0">
                                    {{props.item.OddQuantity}}
                                </div>
                                <vc-text v-else style="width: 125px" decimal v-model="props.item.OddQuantity" @input="changeItem(props.item)">
                                    Cantidad
                                </vc-text>
                            </template>
                            <template v-slot:item.OddObservation="props">
                                <div v-if="value > 0">
                                    {{props.item.OddObservation}}
                                </div>
                                <vc-text v-else label="" v-model="props.item.OddObservation"></vc-text>
                            </template>
                            <template v-slot:item.OddAmount="{ item }">
                                {{parseFloat(item.OddAmount) > 0 ? parseFloat(item.OddAmount).toFixed(2) : item.OddAmount}}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-card elevation="0" height="auto">
                <!-- <vc-header-title /> -->

                <v-row style="margin: auto">
                    <v-col cols="12" lg="8" md="8" class="s-col-form">
                        <v-textarea auto-grow ref="OrdObservation" label="Observaciones Generales" v-model="orderLocal.OrdObservation" />
                    </v-col>
                    <v-col cols="12" lg="4" md="4" class="s-col-form">
                        <div>
                            <vc-select-definition v-if="value <= 0" label="Seleccione Impuesto" :outlined="false" :def="1027" return-object v-model="tax" @input="changeTax($event)" />
                            <h5 v-else>{{orderLocal.TypePercentageName}}</h5>
                        </div>

                        <v-divider class="mt-4 mb-4"></v-divider>
                        <v-row style="margin-top:-15px;margin-bottom:-17px">
                            <v-col lg="6" class="s-col-form">
                                <v-label><b>V. NETO</b></v-label>
                            </v-col>
                            <v-col lg="6" cols="6" class="s-col-form">
                                <h5 class="text-right">
                                    <h5 class="text-right">
                                        <!-- {{orderLocal.OrdTotalNet}} -->
                                        {{
                            orderLocal.OrdTotalNet >= 1000
                              ? orderLocal.OrdTotalNet.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTotalNet.toFixed(2)
                          }}
                                    </h5>
                                </h5>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4 mb-4"></v-divider>
                        <v-row>
                            <v-col lg="6" class="s-col-form">
                                <v-label><b>COSTOS DIRECTOS</b></v-label>
                            </v-col>
                            <v-col lg="6" cols="6" class="s-col-form">
                                <h5 v-if="value > 0" class="text-right">
                                    {{
                            orderLocal.OrdDirectCost >= 1000
                              ? orderLocal.OrdDirectCost.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdDirectCost.toFixed(2)
                          }}
                                </h5>
                                <h5 v-else style="margin-top:-30px" class="text-right">
                                    <vc-text decimal v-model="orderLocal.OrdDirectCost" @input="changeTotal()"></vc-text>
                                </h5>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="6" class="s-col-form">
                                <v-label><b>GTO GENERALES</b></v-label>
                            </v-col>
                            <v-col lg="6" class="s-col-form">
                                <h5 v-if="value > 0" class="text-right">
                                    {{
                            orderLocal.OrdGeneralgto >= 1000
                              ? orderLocal.OrdGeneralgto.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdGeneralgto.toFixed(2)
                          }}
                                </h5>
                                <h5 v-else style="margin-top:-20px" class="text-right">
                                    <vc-text decimal v-model="orderLocal.OrdGeneralgto" @input="changeTotal()"></vc-text>
                                </h5>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="6" class="s-col-form">
                                <v-label><b>UTILIDADES</b></v-label>
                            </v-col>
                            <v-col lg="6" class="s-col-form">
                                <h5 v-if="value > 0" class="text-right">
                                    {{
                            orderLocal.OrdUtilities >= 1000
                              ? orderLocal.OrdUtilities.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdUtilities.toFixed(2)
                          }}
                                </h5>
                                <h5 v-else style="margin-top:-20px" class="text-right">
                                    <vc-text decimal v-model="orderLocal.OrdUtilities" @input="changeTotal()"></vc-text>
                                </h5>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="6" class="s-col-form">
                                <v-label><b>DESCUENTO</b></v-label>
                            </v-col>
                            <v-col lg="6" class="s-col-form">
                                <h5 v-if="value > 0" class="text-right">
                                    {{
                            orderLocal.OrdDiscount >= 1000
                              ? orderLocal.OrdDiscount.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdDiscount.toFixed(2)
                          }}
                                </h5>
                                <h5 v-else style="margin-top:-20px" class="text-right">
                                    <vc-text decimal v-model="orderLocal.OrdDiscount" @input="changeTotal()"></vc-text>
                                </h5>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4 mb-4"></v-divider>
                        <v-row style="margin-top:-15px;margin-bottom:-17px">
                            <v-col lg="6" class="s-col-form">
                                <v-label><b>SUB TOTAL</b></v-label>
                            </v-col>
                            <v-col lg="6" class="s-col-form">
                                <h5 class="text-right">
                                    {{
                            orderLocal.OrdSubTotal >= 1000
                              ? orderLocal.OrdSubTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdSubTotal.toFixed(2)
                          }}
                                </h5>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="s-col-form">
                                <v-label><b>IMPUESTO{{ " (" + orderLocal.OrdTaxPercentage + "%)" }}</b></v-label>
                            </v-col>
                            <v-col class="s-col-form">
                                <h5 class="text-right">
                                    {{
                            orderLocal.OrdTaxAmount >= 1000
                              ? orderLocal.OrdTaxAmount.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTaxAmount.toFixed(2)
                          }}
                                </h5>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4 mb-4"></v-divider>
                        <v-row style="margin-top:-15px;">
                            <v-col class="s-col-form">
                                <v-label><b>TOTAL</b></v-label>
                            </v-col>
                            <v-col class="s-col-form">
                                <h4 class="text-right">
                                    {{
                            orderLocal.OrdTotal >= 1000
                              ? orderLocal.OrdTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTotal.toFixed(2)
                          }}
                                </h4>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>

<script>
export default {
    name: '',
    props: {
        orderNew: {},
        orderEdit: {},
        value: {
            default: 0,
            type: Number,
        },
    },

    data() {
        return {
            orderLocal: {},
            //Detalle
            itemsSAP: [],
            selectedSAP: [],
            headersSAP: [
                /* {
                    text: "Descripción",
                    value: "OddDescription",
                    sortable: false,
                    width: 340,
                }, */
                {
                    text: "Descripción",
                    value: "OddDescription",
                    sortable: false,
                    width: 340,
                },
                {
                    text: "Prc. U.(Sin IGV)",
                    value: "OddUnitPrice",
                    sortable: false,
                    width: 90,
                },
                /* {
                    text: "Cant. Req",
                    value: "DerQuantityAttended",
                    sortable: false,
                    width: 50,
                }, */
                {
                    text: "Cant",
                    value: "OddQuantity",
                    sortable: false,
                    width: 50,
                },

                {
                    text: "Und",
                    value: "OddUnitMeasurement",
                    sortable: false,
                    width: 50,
                },
                {
                    text: "SubTotal",
                    value: "OddAmount",
                    sortable: false,
                    width: 70,
                    align: "right",
                },
                {
                    text: "Comentario",
                    value: "OddObservation",
                    sortable: false,
                    width: 400,
                },
            ],

            //Global
            tax: null,
        };
    },
    created() {

        /* this.orderLocal.OrdDirectCost = 0
        this.orderLocal.OrdGeneralgto =  0
        this.orderLocal.OrdUtilities =  0
        this.orderLocal.OrdDiscount = 0 */

        if (this.value > 0) {

            /* this.orderLocal = this.order
            this.itemsSAP = this.order.itemsDetails */

        } else {
            this.itemsSAP = this.orderNew.detail;
        }

        if (this.itemsSAP)
            for (let i = 0; i < this.itemsSAP.length; i++) this.itemsSAP[i].Line = i + 1;

        this.changeTotal();
    },

    watch: {
        itemsSAP() {
            this.$emit('detail', this.itemsSAP, this.orderLocal)
        },
        orderLocal(){
            this.$emit('detail', this.itemsSAP, this.orderLocal)
        },
        'orderLocal.OrdObservation'(){
            this.$emit('detail', this.itemsSAP, this.orderLocal)
        },
        
        orderEdit() {
            if (this.value > 0) {
                this.orderLocal = this.orderEdit
                this.itemsSAP = this.orderEdit.itemsDetails
            }
        }
    },

    methods: {
        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },
        changeItem(item) {
            this.orderLocal.OrdSubTotal = 0;
            this.orderLocal.OrdTotalNet = 0;
            this.orderLocal.OrdTaxAmount = 0;
            this.orderLocal.OrdTotal = 0;

            let edit = Object.assign({},
                this.itemsSAP.find((x) => x.Line == item.Line)
            );
            let indexEdit = this.itemsSAP.indexOf(item);

            edit.OddAmount = edit.OddUnitPrice * edit.OddQuantity;
            console.log("edit.OddAmount",edit.OddAmount);

            this.itemsSAP.splice(indexEdit, 1, edit);

            this.itemsSAP.forEach((element) => {
                let unitPrice =
                    element.OddUnitPrice == null || element.OddUnitPrice == "" ?
                    0 :
                    element.OddUnitPrice;

                this.orderLocal.OrdTotalNet =
                    parseFloat(this.orderLocal.OrdTotalNet) +
                    parseFloat(unitPrice) *
                    (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));


                this.orderLocal.OrdSubTotal =
                        parseFloat(this.orderLocal.OrdTotalNet) +
                        (   (parseFloat(this.orderLocal.OrdDirectCost || 0) +
                            parseFloat(this.orderLocal.OrdGeneralgto || 0) +
                            parseFloat(this.orderLocal.OrdUtilities || 0)) -
                            parseFloat(this.orderLocal.OrdDiscount || 0)
                        );
            });

            this.orderLocal.OrdTaxAmount =
                (parseFloat(this.orderLocal.OrdSubTotal) *
                    parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                100;

            this.orderLocal.OrdTotal =
                this.orderLocal.OrdSubTotal + this.orderLocal.OrdTaxAmount;
        },

        changeTax(item) {
            if (this.tax != null) {
                this.orderLocal.OrdTaxPercentage = this.tax.DedHelper
                this.orderLocal.TypePercentage = this.tax.DedValue;
                this.orderLocal.OrdTaxSign = this.tax.DedAbbreviation;

                this.orderLocal.OrdTaxAmount =
                    (parseFloat(this.orderLocal.OrdSubTotal) *
                        parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                    100;

                this.changeTotal();

            }
        },

        changeTotal() {

            this.orderLocal.OrdTotalNet = 0;
            this.orderLocal.OrdSubTotal = 0;
            this.orderLocal.OrdTaxAmount = 0;
            this.orderLocal.OrdTotal = 0;

            if (this.itemsSAP !== undefined) {

                console.log("changeTotal",this.itemsSAP);

                this.itemsSAP.forEach((element) => {
                    let unitPrice = element.OddUnitPrice == null ? 0 : parseFloat(element.OddUnitPrice);

                    this.orderLocal.OrdTotalNet =
                        parseFloat(this.orderLocal.OrdTotalNet) +
                        parseFloat(unitPrice) *
                        (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));

                    this.orderLocal.OrdSubTotal =
                        parseFloat(this.orderLocal.OrdTotalNet) +
                        (   (parseFloat(this.orderLocal.OrdDirectCost || 0) +
                            parseFloat(this.orderLocal.OrdGeneralgto || 0) +
                            parseFloat(this.orderLocal.OrdUtilities || 0)) -
                            parseFloat(this.orderLocal.OrdDiscount || 0 )
                        );

                    this.orderLocal.OrdTaxAmount =
                        (parseFloat(this.orderLocal.OrdSubTotal) *
                            parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                        100;
                });

            }
            this.orderLocal.OrdSubTotal = parseFloat(
                parseFloat(this.orderLocal.OrdSubTotal).toFixed(2)
            );
            this.orderLocal.OrdTaxAmount = parseFloat(
                parseFloat(this.orderLocal.OrdTaxAmount).toFixed(2)
            );

            let total = 0.0

            switch (this.orderLocal.OrdTaxSign) {
                case "+":
                    total = this.orderLocal.OrdSubTotal + this.orderLocal.OrdTaxAmount;
                    break;
                case "-":
                    total = this.orderLocal.OrdSubTotal - this.orderLocal.OrdTaxAmount;
                    break;
                /* case "*":
                    total = this.orderLocal.OrdSubTotal * this.orderLocal.OrdTaxAmount;
                    break;
                case "/":
                    total = this.orderLocal.OrdSubTotal / this.orderLocal.OrdTaxAmount;
                    break; */
                //default: 
                   // total = this.orderLocal;
            }

            this.orderLocal.OrdTotal = total

            this.$emit('detail', this.itemsSAP, this.orderLocal)
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
