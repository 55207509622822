import Service from "../Service";
import ServiceReport from "../ServiceReport";

const resource = "LgsRequirement/";
const resourceReport = "helper/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {},
        });
    },

    byID(parameters, requestID) {
        return Service.post(resource + "byid", parameters, {
            params: {},
        });
    },

    anulated(obj) {
        return Service.put(resource + "anulated", obj, {
            params: {},
        });
    },

    decline(obj) {
        return Service.put(resource + "decline", obj, {
            params: {},
        });
    },

    editCategory(obj) {
        return Service.put(resource + "editcategory", obj, {
            params: {},
        });
    },

    save(obj) {
        return Service.post(resource + "save", obj, {
            params: {},
        });
    },

    delete(obj, requestID) {
        return Service.delete(resource + "delete", {
            data: obj
        });
    },

    savefiles(obj) {
        return Service.post(resource + "savefiles", obj, {
            params: {},
        });
    },

    datapdf(obj) {
        return Service.post(resource + "datapdf", obj, {
            params: {},
        });
    },

    printPDF(obj) {
        return ServiceReport.post(resourceReport + "pdfrequirement", obj, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },

};