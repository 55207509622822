<template>
<v-row justify="center">
    <v-dialog persistent v-model="dialog" max-width="350">
        <div v-if="!changeSuccess">

            <form id="change">

                <v-card>
                    <h3 class="pt-4 ml-4">{{ usrName }}</h3>
                    <!-- <v-card-title class="headline">{{ usrName }}</v-card-title> -->
                    <v-card-subtitle class="headline" style="font-size:12px !important;margin-top:-20px">Requiere cambio de contraseña</v-card-subtitle>
                    <v-divider></v-divider>
                    <v-card-text style="padding-bottom:0px;" class="pt-0 mt-0">
                        <v-col>
                            <vc-text id="passwordOld" label="Contraseña anterior" type="password" v-model="passwordOld" autofocus></vc-text>
                        </v-col>
                        <v-col>
                            <vc-text id="password" label="Nueva Contraseña" type="password" v-model="password" ></vc-text>
                        </v-col>
                        <v-col>
                            <vc-text id="passwordConfirm" label="Confirmar Nueva Contraseña" type="password" v-model="passwordConfirm"></vc-text>
                        </v-col>
                    </v-card-text>
                    <v-divider></v-divider>
                    <div class="col-md-12 " style="font-size: 10px;font-weight: 700;">
                        <ul class="ml-4">
                            <li>Debe contener al menos una letra en mayúscula.</li>
                            <li>Debe contener al menos una letra en minúscula.</li>
                            <li>Debe contener al menos un número.</li>
                        </ul>
                    </div>
                    <v-divider></v-divider>
                    <vc-footer save close @save="save()" @close="cancel()" />
                    <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancel()">Cancelar </v-btn>
                <v-btn dark color="primary" @click="save()">Guardar </v-btn>
            </v-card-actions> -->
                </v-card>
            </form>
        </div>
        <div v-if="changeSuccess">
            <form id="change">
                <v-card>
                    <h3 class="pt-4 ml-4">{{ usrName }}</h3>
                    <v-divider></v-divider>
                    <v-card-text style="padding-bottom:0px;" class="pt-0 mt-0">
                        <p>¡Tu contraseña ha sido actualizada! </p>
                        <p class="redirect">Serás redirigido al inicio de sesión en unos segundos...</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <vc-footer>
                        <template v-slot:options>
                            <v-btn elevation="0" class="capitalize" text color="error" @click="redirectToLogin">
                                Logout
                            </v-btn>
                        </template>
                    </vc-footer>

                </v-card>
            </form>
        </div>
    </v-dialog>
</v-row>
</template>

<script>
import AuthService from "@/services/AuthService";
import _validaERP from "@/plugins/validaERP";

export default {
    name: "ModalTimeSession",
    props: {
        userName: "",
        user: null
    },
    data: () => ({
        dialog: true,
        password: "",
        passwordOld: "",
        passwordConfirm: "",
        changeSuccess: false,
    }),
    components: {},
    methods: {
        save() {

            //Validamos Campos
            var resp = _validaERP.valida({
                form: "#change",
                items: {
                    passwordOld: {
                        required: true,
                        value: this.passwordOld,
                    },
                    password: {
                        required: true,
                        passcommon: true,
                        password: true,
                        value: this.password,
                    },

                    passwordConfirm: {
                        required: true,
                        password: true,
                        value: this.passwordConfirm,
                    },
                },
            });

            if (resp) {
                if (this.passwordOld != null && this.password.trim() == this.passwordConfirm.trim()) {
                    this.user.UsrID = this.$fun.getUserID()
                    AuthService.changePassword(this.password.trim(), this.passwordOld.trim(), this.user)
                        .then(() => {
                            this.$fun.sweetAlert("Contraseña Actualizada", "success");

                            //this.dialog = false;
                            this.changeSuccess = true

                            setTimeout(() => {
                                //this.$router.replace("/login")
                                this.redirectToLogin()
                            }, 2000);

                            //this.$router.replace("/");
                        })
                        .catch((error) => {
                            //localStorage.clear();
                            //this.$emit("close");
                        });
                } else
                    this.$fun.alert(
                        "La Confirmación de la contraseña es distinta",
                        "warning"
                    );

            }

        },
        cancel() {
            localStorage.clear();
            this.dialog = false;
            this.$emit("close");
        },

        redirectToLogin() {

            let usrExtern = JSON.parse(localStorage.getItem("UsrExtern"))
            document.onload = null;
            document.onmousemove = null;
            document.onmousedown = null;
            document.ontouchstart = null;
            document.onclick = null;
            document.onscroll = null;
            localStorage.clear();
            localStorage.removeItem("token");

            if (usrExtern) {
                this.$router.push("/login");
            } else {
                this.$router.push("/micuenta");
            }
        },
    },
    computed: {
        usrName() {
            return this.userName.toUpperCase();
        },
    },
};
</script>

<style scoped></style>
