let children = [];

children.push({
    path: "/general/catalogos",
    name: "GEN_DEFINITION",
    component: () =>
        import ("../views/General/GenDefinition.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Catálogos", href: "/general/catalogos" },
        ],
    },
});



children.push({
    path: "/general/numeracion",
    name: "GEN_NUMERATION",
    component: () =>
        import ('../views/General/GenNumerationTables.vue'),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Numeración de Tabla", href: "/general/numeracion" },
        ],
    },
});

children.push({
    path: "/general/parametros",
    name: "GEN_PARAMETER",
    component: () =>
        import ('../views/General/GenParameter.vue'),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Parámetros", href: "/general/parametros" },
        ],
    },
});

children.push({
    path: "/general/areas",
    name: "GEN_AREA",
    component: () =>
        import ('../views/General/GenArea.vue'),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Áreas", href: "/general/areas" },
        ],
    },
});


children.push({
    path: "/general/areasporusuario",
    name: "GEN_AREAUSER",
    component: () =>
        import ('../views/General/GenAreaUser.vue'),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Áreas por Usuario", href: "/general/areasporusuario" },
        ],
    },
});



children.push({
    path: "/general/cargos",
    name: "GEN_POSITION",
    component: () =>
        import ('../views/General/GenPosition.vue'),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Cargos", href: "/general/cargos" },
        ],
    },
});

children.push({
    path: "/general/empleados",
    name: "GEN_PERSONROL",
    component: () =>
        import ("../views/General/GenWorker.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Empleados", href: "/general/empleados" },
        ],
    },
});


children.push({
    path: "/general/numeraciondocumento",
    name: "GEN_NUMERATIONDOCUMENT",
    component: () =>
        import ("../views/General/GenNumerationDocument.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            {
                text: "Numeración de Documentos",
                href: "/general/numeraciondocumento",
            },
        ],
    },
});

children.push({
    path: "/general/compania",
    name: "GEN_COMPANY",
    component: () =>
        import ('../views/General/GenCompany.vue'),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Compañias", href: "/general/compania" },
        ],
    },
});

children.push({
    path: "/general/integraciones",
    name: "GEN_INTEGRATION",
    component: () =>
        import ('../views/General/GenCompanyIntegration.vue'),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Integraciones", href: "/general/integraciones" },
        ],
    },
});



children.push({
    path: "/general/grupodefinicion",
    name: "GEN_DEFINITION_GROUP",
    component: () =>
        import ("../views/General/GenDefinitionGroup.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Grupo Catálogos", href: "/general/grupodefinicion" },
        ],
    },
});

children.push({
    path: "/general/centrocosto",
    name: "GEN_COST_CENTER",
    component: () =>
        import ("../views/General/GenCostCenterNew.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Centro de Costos", href: "/general/centrocosto" },
        ],
    },
});

children.push({
    path: "/general/businessLine",
    name: "GEN_BUSINESS_LINE",
    component: () =>
        import ("../views/General/GenBusinessLine.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Línea de Negocio", href: "/general/businessLine" },
        ],
    },
});

children.push({
    path: "/general/projects",
    name: "GEN_PROJECT",
    component: () =>
        import ("../views/General/GenProject.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Proyectos", href: "/general/projects" },
        ],
    },
});

children.push({
    path: "/general/configuracionadjuntos",
    name: "GEN_ATTACH_CONFIGURATION",
    component: () =>
        import ("../views/General/GenAttachConfiguration.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Configuración de Adjuntos", href: "/general/configuracionadjuntos" },
        ],
    },
});

children.push({
    path: "/general/sociosdenegocio",
    name: "GEN_SUPPLIER",
    component: () =>
        import ("../views/General/GenSupplier.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Socios de Negocio", href: "/general/sociosdenegocio" },
        ],
    },
});

children.push({
    path: "/general/campania",
    name: "GEN_COMPAIGN",
    component: () =>
        import ("../views/General/GenCampaign.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Campañas", href: "/general/campania" },
        ],
    },
});

children.push({
    path: "/general/ubigeo",
    name: "GEN_UBIGEO",
    component: () =>
        import ("../views/General/GenUbigeo/GenUbigeo.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Ubigeo", href: "/general/ubigeo" },
        ],
    },
});

children.push({
    path: "/general/propiedadesconfiguración",
    name: "GEN_PROPERTY_SETTING",
    component: () =>
        import ("../views/General/GenPropertySetting.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Propiedades y Configuraciones", href: "/general/propiedadesconfiguración" },
        ],
    },
});


export { children };