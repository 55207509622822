import Vue from "vue";

export default {
    getUrlSearchArea() {
        return Vue.prototype.$http.defaults.baseURL + "GenGenericType/areasearch";
    },

    getUrlPosition() {
        return Vue.prototype.$http.defaults.baseURL + "GenGenericType/positionsearch";
    },

    getURLTransaction() {
        return Vue.prototype.$http.defaults.baseURL + "GenGenericType/transaction";
    },

    getUrlSearchProperty() {
        return Vue.prototype.$http.defaults.baseURL + "GenGenericType/propertysettings";
    },
};