<template>
<div>

    <!-- <v-row class="mt-0" justify="center"> -->
    <!-- <v-card style="border-radius:10px" elevation="0" class="pb-3 mt-0">
            <v-row justify="center" style="margin:auto"> -->
    <v-col cols="12">
        <!-- <vc-modal-dialog title="Actividades Economicas" save close :dialog="dialogSea" :data="itemSea" :config="configSea" @save="saveSea($event, $event)" @close="dialogSea = false" md>
                        <template slot-scope="props">
                            <form id="frmsupplierEconomicActivity">
                                <v-row class="mt-3 mb-2">
                                    <v-col lg="4" md="4" cols="4" class="s-col-form">
                                        <vc-select-definition id="SeaDescription" v-model="props.item.TypeEconomicActivities" :def="1024" label="Tip. Actividad Economica">
                                        </vc-select-definition>
                                    </v-col>

                                    <v-col lg="8" md="8" cols="8" class="s-col-form">
                                        <vc-text id="SeaDescription" v-model="props.item.SeaDescription" label="Descripcion">
                                        </vc-text>
                                    </v-col>
                                </v-row>
                            </form>
                        </template>
                    </vc-modal-dialog> -->

        <v-col cols="12" lg="12" md="12">
            <vc-crud noFooter sortable restore no-full ref="crudSupplierEconomicActivity" title="" :config="configSea" :filter="filterSea" @add="
                  dialogSea = true;
                  itemSea = {};
                " @edit="editSea($event)" add edit remove searchInput>

                <template v-slot:options>
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" small elevation="0" color="" @click="$emit('add')" class="capitalize m-1 ml-1 mr-1">
                                <v-icon color="success" style="font-size:12px;margin-right:5px;">fa-plus</v-icon> Nuevo
                            </v-btn>
                        </template>
                        <span>Nuevo</span>
                    </v-tooltip>
                </template>
                <template v-slot:SecStatus="{ row }">
                    <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'">
                        mdi-checkbox-blank-circle</v-icon>
                </template>
            </vc-crud>
        </v-col>
    </v-col>

    <!--  </v-row>
        </v-card> -->
    <!-- </v-row> -->
</div>
</template>

<script>
import _sGenSupplierEconomicActivity from "@/services/General/GenSupplierEconomicActivityService.js";

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon,

    },
    props: {
        SupID: {
            type: Number,
            default: 0
        }
    },
    data() {

        return {
            dialogSea: false,
            itemSea: [],
            filterSea: {
                SupID: 0,
            },
            configSea: {
                model: {
                    SeaID: "ID",
                },
                service: _sGenSupplierEconomicActivity,
                headers: [{
                        text: "ID",
                        value: "SeaID",
                        sortable: false,
                    },
                    /*  {
                         text: "Proveedor",
                         value: "SupName",
                         sortable: false,
                     }, */
                    {
                        text: "Tipo de Actividad Economica",
                        value: "TypeEconomicActivitiesName",
                        sortable: false,
                    },
                    {
                        text: "Descripcion",
                        value: "SeaDescription",
                        sortable: false,
                    },
                ],
            },
        };
    },

    mounted() {

    },

    created() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>

</style>
