<template>
<div>

    <vc-loading :processing="processing" :errors="errors" :title="'Cargando datos.'" @close="processing=false;" />

    <v-dialog v-model="showDetail" v-if="showDetail" persistent max-width="600">
        <pag-payment :item="itemPayment" @close="showDetail=false" />
    </v-dialog>

    <pag-pay-fee :itemPay="itemPayFee" :itemOC="item" v-if="ShowPayFee && item" @close="ShowPayFee=false" :customer="customer" />

    <v-row class="mt-4" justify="center" v-if="!ShowPayFee">

        <v-col cols="12">

            <v-card elevation="0" style="margin-top: 2px;border-radius:10px">
                <v-row justify="center">
                    <v-col cols="12" style="text-align-last: center;">
                        <h1 style="color:#1f4d9d;font-family: Calibri">¡BIENVENIDO!</h1>
                        <br>
                        <h3 style="color:#16a94b;margin-top:-20px;font-family:;font-weight: 700;" class="capitalize">{{customer.nroDocumentoTitular == null ? "ERROR AL CARGAR DATOS DE CLIENTE": customer.nombreCompleto}}</h3>
                    </v-col>
                </v-row>
            </v-card>
            <v-card elevation="0" style="margin-top: 2px;border-radius:10px">

                <v-col cols="12">

                    <div class="mb-3 card mt-0">
                        <div class="">
                            <v-btn style="text-transform: none;" color="#4b72b6" dark class="" small elevation="0" @click="prevHome"><span class="lnr lnr-arrow-left mr-2"></span>Ir al Inicio</v-btn>
                        </div>
                    </div>
                    <!-- <v-select @input="inputOC($event)" :items="customer.operacionComercial" item-text="correlativo" item-value="idProforma"></v-select> -->

                </v-col>
            </v-card>

            <v-card style="border-radius:10px" elevation="0" class="pb-3 mt-3">
                <v-row>
                    <v-col cols="12" lg="3" md="4">

                        <div class="mb-3 card mt-0">
                            <div class="card-header-tab card-header">
                            </div>

                            <v-tabs vertical v-model="currentItem">

                                <v-tab v-for="item in items" :key="item" :href="'#tab-' + item">
                                    <v-icon left>
                                        mdi-account
                                    </v-icon>
                                    {{ item }}
                                </v-tab>
                            </v-tabs>
                        </div>

                    </v-col>
                    <v-col cols="12" lg="9" md="8">

                        <v-tabs-items v-model="currentItem" class="pl-0 ml-0">
                            <v-tab-item v-for="tab in items" :key="tab" :value="'tab-' + tab" class="pl-0 ml-0">

                                <v-card elevation="0" style="margin-top: 2px;border-radius:10px" v-if="tab == 'Mis Pagos'">
                                    <div class="card-body">
                                        <h2 style="font-size: 18px;" class="card-title">
                                            <h3>{{item.nroInmueble_1}}</h3>
                                            <h5 style="color:#1f4d9d">{{item.proyecto}} || {{item.etapa}}</h5>
                                        </h2>

                                        <v-btn small outlined elevation="0" class="capitalize" @click="download"><i class="fa-solid fa-file-excel mr-2" style="color:green"></i>Estado de cuenta</v-btn>
                                        <v-divider></v-divider>
                                        <b-tabs class="card-header-tab-animation" card>

                                            <b-tab :title="'Pagos pendientes - ' + paymentPending.length" class="p-3">
                                                <div class="scroll-gradient">
                                                    <div class="">

                                                        <v-card style="border-radius:10px" elevation="0" class="pb-0 mt-0" v-for="(pay, index) in paymentPending" :key="index">
                                                            <v-row>
                                                                <v-col cols="12">

                                                                    <div class="mb-3 card mt-0 pr-3">

                                                                        <div class="card-header-tab card-header" style="text-align-last: left;">
                                                                            <v-chip x-small :class="pay.estadopagocuota == 'VENCIDO' ? 'mr-3 bounce' : 'mr-3'" :color="pay.estadocuotacolor">{{pay.estadocuota}}</v-chip>
                                                                            <v-chip x-small class="mr-3" color="error" v-if="pay.estadopagocuota == 'PARC.CANCELADO'">{{pay.estadopagocuota}}</v-chip>
                                                                        </div>
                                                                        <div class="no-gutters row">

                                                                            <div class="col-sm-12 col-md-12 col-xl-12">
                                                                                <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                                                                                    <div class="widget-chart-content">
                                                                                        <div class="widget-subheading">F.V: {{$fun.formatDateView(pay.fechavctocuota)}} - {{'USD'}}</div>

                                                                                        <div class="widget-numbers" style="font-size:20px">
                                                                                            <span>{{pay.montocuota.toLocaleString("es-MX", {
                                                                                                        minimumFractionDigits: 2,
                                                                                                        maximumFractionDigits: 2,
                                                                                                    })}}</span>
                                                                                        </div>
                                                                                        <div class="widget-description" style="color:#1f4d9d">
                                                                                            {{pay.nrocuota}} | {{pay.tipocuota}}
                                                                                            <br>
                                                                                            <span class="pl-0" v-if="pay.estadopagocuota == 'PARC.CANCELADO'">
                                                                                                Monto Pagado: <span style="font-size:16px;color:black;font-weight: 700;">{{pay.totalpagado.toLocaleString("es-MX", {
                                                                                                        minimumFractionDigits: 2,
                                                                                                        maximumFractionDigits: 2,
                                                                                                    })}}</span>
                                                                                            </span><br>
                                                                                            <span class="pl-0" v-if="pay.estadopagocuota == 'PARC.CANCELADO'">
                                                                                                Saldo: <span style="font-size:16px;color:black;font-weight: 700;">{{pay.saldocuota.toLocaleString("es-MX", {
                                                                                                        minimumFractionDigits: 2,
                                                                                                        maximumFractionDigits: 2,
                                                                                                    })}}</span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="icon-wrapper" style="border-radius:10px" v-if="pay.habilitabotompagar && item.estadoOC == 'ACTIVO'" @click="savePayment(pay)">
                                                                                        <div class="icon-wrapper-bg opacity-9 bg-success"></div><i class="v-icon material-icons theme--light text-white text--darken-2 bounce">payment</i>
                                                                                    </div>
                                                                                    <v-btn :outlined="true" @click="savePayment(pay)" elevation="0" v-if="pay.habilitabotompagar && item.estadoOC == 'ACTIVO'" color="#16a94b" dark class="capitalize mr-2">Pagar</v-btn>
                                                                                </div>
                                                                                <div class="divider m-0 d-md-none d-sm-block"></div>
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                    </div>
                                                </div>
                                            </b-tab>
                                            <b-tab :title="'Pagos cancelados - ' + paymentCancelled.length" class="pt-3">
                                                <div class="scroll-gradient">
                                                    <div class="">
                                                        <v-card style="border-radius:10px" elevation="0" class="pb-0 mt-0" v-for="(pay, index) in paymentCancelled" :key="index">
                                                            <v-row>
                                                                <v-col cols="12">

                                                                    <div class="mb-3 card mt-0">
                                                                        <div class="no-gutters row">

                                                                            <div class="col-sm-12 col-md-12 col-xl-12">
                                                                                <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                                                                                    <div class="widget-chart-content">
                                                                                        <div class="widget-subheading">F.V: {{$fun.formatDateView(pay.fechavctocuota)}} - {{'USD'}}</div>

                                                                                        <div class="widget-numbers" style="font-size:20px">
                                                                                            <span>{{pay.totalpagado.toLocaleString("es-MX", {
                                                                                                        minimumFractionDigits: 2,
                                                                                                        maximumFractionDigits: 2,
                                                                                                    })}}</span>
                                                                                        </div>
                                                                                        <div class="widget-description" style="color:#1f4d9d">
                                                                                            {{pay.nrocuota}} | {{pay.tipocuota}}
                                                                                        </div>
                                                                                    </div>

                                                                                    <v-chip x-small class="mr-3" color="success">Pagado</v-chip>
                                                                                    <a @click="viewDetail(pay)" style="text-decoration:underline" class="mr-3">Ver detalle</a>

                                                                                </div>
                                                                                <div class="divider m-0 d-md-none d-sm-block"></div>
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                    </div>
                                                </div>
                                            </b-tab>
                                        </b-tabs>
                                    </div>
                                </v-card>

                            </v-tab-item>

                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

    </v-row>
</div>
</template>

<script>
import _sPayGateway from '@/services/PaymentGateway/PagPayGatewayService.js'
import PagPayment from './PagPayment.vue';
import PagPayFee from './PagPayFee.vue';

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon,
        PagPayment,
        PagPayFee,

    },
    props: {},
    data() {

        return {
            paymentPending: [],
            paymentCancelled: [],
            schedule: [],
            tabSelected: "",
            currentItem: "tab-",
            items: ["Mis Pagos"],

            itemPayment: {},
            itemPayFee: {},

            showDetail: false,
            ShowPayFee: false,

            //Loading
            processing: false,
            errors: null,

        };
    },

    mounted() {

    },

    created() {
        this.getSchedule()

    },

    computed: {
        /* item() {
            return this.$store.getters.getItemData;
        },
        customer() {
            return this.$store.getters.getCustomerData;
        } */

        item() {
            return JSON.parse(localStorage.getItem("PagItem"));
        },
        customer() {
            return JSON.parse(localStorage.getItem("PagCustomer"));
        }
    },

    methods: {
        prevHome() {
            this.$router.push('/');
        },
        getSchedule() {

            if (this.item !== null) {
                this.processing = true
                _sPayGateway.getSchedule(this.item.idProforma).then(resp => {
                        if (resp.status == 200) {
                            this.processing = false
                            this.paymentPending = resp.data.Result.pending
                            this.paymentCancelled = resp.data.Result.cancelled
                            this.schedule = resp.data.Result.schedule

                        }
                    },
                    (e) => {
                        this.errors = e.response
                        this.processing = true;
                    })
            } else {
                this.$store.commit('setItemData', {});
                this.$store.commit('setCustomerData', {});
            }

        },
        viewDetail(item) {

            if (item !== null) {
                this.itemPayment = item
                this.showDetail = true
            }

        },

        savePayment(item) {
            if (item !== null) {
                this.itemPayFee = item
                this.ShowPayFee = true
            } else {
                this.$fun.sweetAlert("[IMP], Error al obtener datos.", "error")
            }

        },
        download() {
            if (this.schedule.length > 0) {
                let json = JSON.stringify(this.schedule);

                _sPayGateway.dataExcel({
                    Json: json
                }).then(resp => {
                    this.$fun.downloadFile(
                        resp.data,
                        this.$const.TypeFile.EXCEL,
                        "Data"
                    );
                })
            }

        },
        inputOC(item) {
            console.log(item);
        }
    },
};
</script>

<style lang="scss" scoped>
@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}

.bounce {
    animation: bounce 1s infinite;
}
</style>
