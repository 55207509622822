import Service from "../Service";

const resource = "ConServiceHour/";

export default {
    byOne() {
        return Service.get(resource + "byone", {
            params: {},
        });
    },
};