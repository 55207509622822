<template>
<div style="padding-top:-2px;margin-top:-2px;">
    <v-label><b>{{label}}</b></v-label>
    <v-textarea counter="" ref="text" :auto-grow="autoGrow" :height="height" :readonly="readonly" :disabled="disabled" dense placeholder=" " outlined :dark=dark hide-details="false" :autofocus="autofocus" :value="value" @click="click()" @input="inputText($event)" @keyup.enter="enter" @keypress="validation($event)" :clearable=clearable></v-textarea>
</div>
</template>

<script>
export default {
    components: {},
    props: {

        value: null,
        /* color: {
            type: String,
            default: "primary"
        }, */
        label: {
            type: String,
            default: ""
        },
        maxlength: {
            type: Number,
            default: 256
        },
        type: {
            type: String,
            default: "text"
        },
        dark: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        number: {
            type: Boolean,
            default: false
        },
        decimal: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        search: {
            type: Boolean,
            default: false
        },
        autoGrow: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default: 60
        }
    },
    watch: {
        config() {},
        value() {
            this.color = "primary";
        },
    },
    methods: {
        setColor(color) {
            this.color = color ? color : "primary";
        },
        error(msg) {
            this.$fun.alert(msg, "warning");
            this.focus();

            this.setColor("error");
        },

        click() {
            this.$emit('click');
        },
        handleBlur() {
            this.$emit('blur')
        },
        focus() {
            this.$refs.text.focus();
        },
        validation(evt) {
            console.log("this.maxlengththis.maxlength",this.maxlength);
            if(this.maxlength > 0){
                
            }
            if (this.number || this.type == "number") {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            }

            if (this.decimal) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46)) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }
        },
        inputText(item) {
            
            if (item == null) {
                item = "";
                this.$emit("input", '');
            } else {
                this.item = item;
                if (!this.search)
                    this.$emit("input", item);
                else {
                    this.debouncedGetAnswer();
                }
            }
        },
        enter() {
            this.$emit("keyupEnter");
        },
        getAnswer() {
            this.$emit("input", this.item);
        }
    },
    mounted() {},
    created() {
        this.debouncedGetAnswer = _.debounce(this.getAnswer, 220);
    },
    watch: {},
    data: () => ({
        //ComboBox
        //
        item: "",
        color: "primary",
        
    }),
};
</script>

<style>
.text .v-input__slot {
    min-height: 28px !important;
    max-height: 28px !important;
}
</style>
