import Service from "./Service";
const resource = "Helper/";
import Vue from "vue";

export default {

    getParam(param) {

        return Service.get(resource + "getparam", {
            params: {
                PrmName: param
            },
        });
    },

    getPhotoWorkerFirma(PrsFirma) {
        return Service.get(resource + "signature", {
            responseType: "blob",
            headers: {
                "Content-Type": "image/jpg",
            },
            params: { PrsFirma: PrsFirma },
        });
    },

    uploadFile(files, parameter) {
        return Service.post(resource + "uploadfile", files, {
            params: {
                parameter: parameter
            },
        });
    },

    uploadFileProvider(files, NroVoucher, Ruc, OrdDocumentNumber) {
        return Service.post(resource + "uploadfileprovider", files, {
            params: {
                NroVoucher: NroVoucher,
                Ruc:Ruc,
                OrdDocumentNumber:OrdDocumentNumber
            },
        });
    },

    download(fileName, type = 1) {
        return Service.post(resource + "downloadfile", {}, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
                //"content-disposition": "Access-Control-Expose-Headers"
            },
            params: {
                fileName: fileName,
                type: type
            },
        });
    },

    downloadRute(fileName, rute) {
        return Service.post(resource + "downloadfilerute", {}, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                fileName: fileName,
                rute: rute
            },
        });
    },
};