<template>
	<div style="padding-top: -2px; margin-top: -2px">
		<vc-select-generic
			ref="sProvince"
			autocomplete
			class="text"
			itemtext="PrvName"
			itemvalue="PrvID"
			:disabled="disabled"
			id="PrvName"
			:value="value"
			:return-object="returnObject"
			@input="input($event)"
			:config="config"
			:label="label"
			:full="full"
			:autofocus="autofocus"
			:text="text"
			:clearable="clearable"
			:noDefault="noDefault"
			:outlined="outlined"
		></vc-select-generic>
	</div>
</template>

<script>
	import _sProvince from "@/services/General/GenProvinceService.js"

	export default {
		name: "SelectProvince",
		props : {
			autofocus: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			clearable: {
				type: Boolean,
				default: false,
			},
			value: null,
			text: {
				type: String,
				default: ""
			},
			label: {
				type: String,
				default: "",
			},
			returnObject: {
				type: Boolean,
				default: false,
			},
			noDefault: {
				type: Boolean,
				default: false,
			},
			full: {
				type: Boolean,
				default: false,
			},

			outlined: {
				type: Boolean,
				default: true
			},
			CceLevel : {
				type : Number,
				default : 100
			},
			DptID : {
				type : Number,
				default : 0
			}
		},
		data() {
			return {
				item: 0,
				val: "",
				items: [],
				config: {},
			}
		},
		watch: {
			value(value) {
				this.item = value;
			},
			DptID(){
                console.log("params.DptID watch" , this.DptID)
				this.config.params.DptID=this.DptID
		    }

		},

		methods: {
			focus() {
				this.$refs.sProvince.focus();
			},
			input(val) {
				this.$emit("input", val);
			},
             
		},

		created() {
			console.log("params.DptID" , this.DptID)
			this.config = {
				url: _sProvince.getUrlSearchProvince(),
				title: this.label,
				params: {
					DptID: this.DptID
				},
			};
		},
	}
</script>