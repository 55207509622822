<template>
<v-app class="scroll">

    <vc-loading :processing="processing" :errors="errors" @close="processing=false;" />

    <div class="app-container app-theme-white">
        <transition name="fade" mode="out-in" appear>
            <Header :configUser="configUser" :user="user" @logout="logout()" />
        </transition>
        <transition name="fade" mode="out-in" appear >
            <div class="app-sidebar sidebar-shadow"  @mouseover="toggleSidebarHover('add', 'closed-sidebar-open')" @mouseleave="toggleSidebarHover('remove', 'closed-sidebar-open')">
                <div class="app-header__logo" style="opacity:0.7" v-if="!usrExtern">

                    <!-- <v-img lazy-src="" max-height="20" max-width="150" src="./../assets/images/logo22.png"></v-img> -->

                    <div class="header__pane ml-auto" v-if="!usrExtern">
                        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" v-bind:class="{ 'is-active': isOpen }" @click="toggleBodyClass('closed-sidebar')">
                            <span class="hamburger-box">
                                <span class="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
                <div class="app-sidebar-content" v-if="!usrExtern">
                    <VuePerfectScrollbar class="app-sidebar-scroll">
                        <sidebar-menu style="    font-family: Calibri;font-size: 14px;" showOneChild :menu="userMenu" />
                    </VuePerfectScrollbar>
                </div>
            </div>

        </transition>

        <div class="app-main__outer">
            <div class="app-main__inner">
                <v-main class="mb-10">
                    <v-container fluid class="pt-0 mt-0">
                        <page-title :icon="icon" style="height:62px" v-if="!usrExtern || user.LexID !== 1001" ></page-title>
                        <sup-home :user="user" :UsrName="usrName" v-if="usrExtern && !hasActiveURL && user.LexID == 1000"  />
                        <pag-home :UsrName="usrName" v-if="usrExtern && !hasActiveURL && user.LexID == 1001"  />
                        <router-view />
                    </v-container>
                </v-main>
            </div>
            <transition name="fade" mode="out-in">
                <Footer />
            </transition>
        </div>
    </div>
</v-app>
</template>

<script>
import Header from "@/components/Layout/Header";
import Footer from "@/components/Layout/Footer";
import Breadcrumb from "@/components/Layout/Breadcrumb.vue";
import {
    SidebarMenu
} from "vue-sidebar-menu";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

//Services
import _sAuth from "@/services/AuthService";

//Components
import PageTitle from "../components/Layout/PageTitle.vue";
import SupHome from "@/views/PortalProviders/SupHome.vue";
import PagHome from '@/views/PaymentGateway/PagHome.vue';

export default {
    name: "App",
    components: {
        Header,
        Footer,
        Breadcrumb,
        SidebarMenu,
        VuePerfectScrollbar,
        PageTitle,
        SupHome,
        PagHome,
    },
    data() {
        return {
            usrName: "",
            user: {},
            userMenu: [],
            configUser: {},
            usrID: this.$fun.getUserID(),
            menu: [],
            isOpen: false,
            sidebarActive: false,
            collapsed: true,
            windowWidth: 0,
            user: {},
            icon: "pe-7s-home icon-gradient bg-amy-crisp",
            usrExtern: false,
            userInfo: null,
            processing: false,
            errors: null,
            timeToken: null,

        };
    },
    created() {
        this.usrName = localStorage.getItem("UsrName");
        this.usrExtern = JSON.parse(localStorage.getItem("UsrExtern"))
        this.initialize();
    },
    methods: {

        initialize() {
            this.userMenu = JSON.parse(localStorage.getItem("UserOptions"));
            this.userMenu.push({
                OptOrder: 0,
                header: true,
                title: "Menu",
            });

            this.userMenu.forEach((opt) => {

                opt.icon = opt.OptIcon
                if (opt.childCount > 0) {

                    if (opt.child.length > 0) {
                        opt.child.forEach((element) => {
                            element.icon = null;
                            element.href = this.$router.options.routes[1].children.find(
                                (x) => x.name == element.OptCode
                            );

                            if (element.childCount > 0) {
                                if (element.child.length > 0) {
                                    element.child.forEach((element2) => {
                                        element2.icon = null;
                                        element2.href = this.$router.options.routes[1].children.find(
                                            (x) => x.name == element2.OptCode
                                        );
                                    });
                                }
                            }

                        });
                    }
                }

            })

            this.userMenu.sort((x, y) => x.OptOrder - y.OptOrder);

            this.user = JSON.parse(localStorage.getItem("UserInfo"));
            console.log("user",this.user);
            this.configUser = JSON.parse(localStorage.getItem("UserConfig"));
            localStorage.setItem("languaje", this.configUser.CfgLanguage);
            localStorage.setItem("persistent", this.configUser.CfgPersistent ? "" : this.configUser.CfgPersistent); //en blanco = true
            localStorage.setItem("AlertType", this.configUser.CfgAlertType); //false alerta grande
            localStorage.setItem("AlertAlign", this.configUser.CfgAlertAlign);
            localStorage.setItem("colorToolbar", this.configUser.CfgToolbar); //#51ae90

            if (this.user.UsrExtern == 0 && (this.user.GenWorkerCE == undefined || this.user.GenWorkerCE == null)) {
                this.$fun.sweetAlert("[IMP]. Error algunos módulos no funcionaran de manera correcta. Asigne Cargo a Usuario", "error")
            }

        },

        logout() {

            localStorage.setItem("isIdle", false);
            localStorage.removeItem("token");
            localStorage.clear();

            if (this.usrExtern) {
                this.$router.push("/login");
            } else {
                this.$router.push("/micuenta");
            }

        },

        toggleBodyClass(className) {
            const el = document.body;
            this.isOpen = !this.isOpen;

            if (this.isOpen) {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        toggleSidebarHover(add, className) {
            const el = document.body;
            this.sidebarActive = !this.sidebarActive;

            this.windowWidth = document.documentElement.clientWidth;

            /* if (!this.usrExtern) { */
            if (this.windowWidth > "992") {
                if (add === "add") {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            }
            /* } */
        },
        getWindowWidth() {
            const el = document.body;

            this.windowWidth = document.documentElement.clientWidth;

            /* if (!this.usrExtern) { */
            if (this.windowWidth < "1350") {
                el.classList.add("closed-sidebar", "closed-sidebar-md");
            } else {
                el.classList.remove("closed-sidebar", "closed-sidebar-md");
            }
            /* } */
        },

        /* closeSidebar() {
            const el = document.body;
            this.windowWidth = document.documentElement.clientWidth;

            if (this.usrExtern) {
                el.classList.add("closed-sidebar", "closed-sidebar-md");
            } else {
                el.classList.remove("closed-sidebar", "closed-sidebar-md");
            }
        }, */

        /* configurateActivity() {

            this.timeToken = setInterval(() => {
                if (this.usrID > 0) {
                    let time = localStorage.getItem("TimeTokenCount") - 3000;
                    localStorage.setItem("TimeTokenCount", time);

                    if (localStorage.getItem("TimeTokenCount") < 1) {
                        if (localStorage.getItem("token") != null) {

                            _sAuth.refreshToken(this.usrID).then((r) => {
                                localStorage.setItem("TimeTokenCount", 0);
                                localStorage.setItem("TimeTokenCount", localStorage.getItem("TimeToken"));
                                localStorage.setItem("token", "");
                                localStorage.setItem("token", r.data.token);

                            });

                            if (this.extern == 1) {
                                _sPayment.generatedToken(this.$fun.getUserID()).then(resp => {
                                    if (resp.status == 200) {
                                        localStorage.setItem("tokenNiubiz", "");
                                        localStorage.setItem("tokenNiubiz", resp.data);
                                    }
                                })
                            }

                        }
                    } 
                }
            }, 3000);

            var t;

            let logoutTimeInactive = () => {
                localStorage.setItem("isIdle", true);
                clearInterval(t);
            };

            let resetTimer = () => {

                clearInterval(t);
                localStorage.setItem("isIdle", false);
                this.timeInactiveShow = 0;
                t = setInterval(() => {
                    if (this.timeInactiveShow < localStorage.getItem("TimeToken")) {
                        this.timeInactiveShow = this.timeInactiveShow + 1000;
                    } else {
                        localStorage.setItem("isIdle", true);
                        this.isInactive = true
                        logoutTimeInactive();
                    }
                }, 1000);
            };

            resetTimer();
            document.onload = resetTimer;
            document.onmousemove = resetTimer;
            document.onmousedown = resetTimer;
            document.ontouchstart = resetTimer;
            document.onclick = resetTimer;
            document.onscroll = resetTimer;
        }, */
    },
    watch: {
        $route(to, from) {},
    },
    computed: {
        // Computed property para verificar si el router-view tiene una URL activa
        hasActiveURL() {
            return this.$route.path !== '/' || this.$route.path == ''; // Devuelve true si la URL no está vacía
        },
    },

    mounted() {
        this.$nextTick(function () {
            window.addEventListener("resize", this.getWindowWidth);

            //Init
            this.getWindowWidth();
            //this.closeSidebar();
        });
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.getWindowWidth);
    },
};
</script>
