import Service from "../Service";

const resource = "GenDistrict/";
import Vue from "vue";

export default {
    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: {},
        });
    },
    update(obj, requestID) {
        return Service.put(resource + "update", obj, {
            params: {},
        });
    },
    delete(obj, requestID) {
        return Service.delete(resource + "delete", {
            data: obj
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {},
        });
    },
    getUrlSearchDistrict() {
        return Vue.prototype.$http.defaults.baseURL + "GenDistrict/list";
    },
};