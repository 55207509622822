<template>
<div style="padding-top:-2px;margin-top:-2px;">
    <v-label><b>{{ label }}</b></v-label>
    <section>
        <v-file-input @click:clear="clear()" v-model="files" prepend-icon="" show-size small-chips color="primary" prepend-inner-icon="mdi-paperclip" dense hide-details outlined>
            <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2" color="primary" dark label x-small>
                    {{ text }}
                </v-chip>
            </template>
        </v-file-input>

        <xlsx-read :file="files">
            <xlsx-json v-if="headers" @parsed="changeData($event)">
                <template #default="{collection}">
                    <div>
                        <v-data-table class="elevation-4 mt-3 mb-3 table-bordered" dense :headers="headers" :items="convertCollection(collection)" />
                    </div>
                </template>
            </xlsx-json>
        </xlsx-read>
    </section>
    <v-row v-if="headers">
        <v-spacer></v-spacer>
        <v-col lg="6">
            <v-btn elevation="0" color="primary" small block @click="$emit('loaded', items)">Cargar Datos</v-btn>
        </v-col>
    </v-row>
</div>
</template>

<script>
    import {
        faClosedCaptioning
    } from '@fortawesome/free-solid-svg-icons';
    import {
        XlsxRead,
        XlsxTable,
        XlsxSheets,
        XlsxJson,
        XlsxWorkbook,
        XlsxSheet,
        XlsxDownload
    } from "vue-xlsx";

    export default {
        components: {
            XlsxRead,
            XlsxTable,
            XlsxSheets,
            XlsxJson,
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
        },
        props: {
            value: null,
            label: {
                type: String,
                default: "Seleccione Archivo"
            }
        },

        data() {
            return {
                headers: [],
                items: [],
                file: null,
                files: null,
                selectedSheet: null,
                sheetName: null,
                sheets: [{
                    name: "SheetOne",
                    data: [{
                        c: 2
                    }]
                }],
                collection: [{
                    a: 1,
                    b: 2
                }],
            };
        },

        methods: {
            clear() {
                this.files = [];
                this.items = [];
                this.$emit("clear");
                this.$emit("input", null);
            },
            changeData(data) {
                this.$emit("changeData", data);
                this.$emit("input", data);
            },
            convertCollection(collection) {

                let list = [];
                if (collection) {
                    let elderlyProp = collection[0];
                    collection.forEach(element => {
                        if (Object.keys(element).length > Object.keys(elderlyProp).length) {
                            elderlyProp = element;
                        }
                    });

                    // Obtener la cantidad de propiedades del objeto con mayor cantidad de propiedades
                    const countProp = Object.keys(elderlyProp).length;
                    const propFaltante = Object.keys(elderlyProp).filter(
                        prop => !collection.every(element => prop in element)
                    );

                    collection.forEach(element => {
                        if (element !== elderlyProp) {
                            propFaltante.forEach(prop => {
                                if (!(prop in element)) {
                                    element[prop] = "";
                                }
                            });
                        }
                    });

                    if (this.headers == 0)
                        if (collection.length > 0)
                            Object.keys(elderlyProp).forEach(e => {
                                this.headers.push({
                                    text: e,
                                    value: e,
                                    column: e
                                });
                            });

                } else collection = [];
                this.items = collection;
                
                return collection;
            },
        },
    };
</script>

<!-- <template>

<div style="padding-top:-2px;margin-top:-2px;">

<v-label>

<b>{{ label }}</b>

</v-label>

<section>

<v-row>

<v-col  :cols="verify ? 10 : 12">

<v-file-input

@click:clear="clear()"

v-model="files"

prepend-icon=""

show-size

small-chips

color="primary"

prepend-inner-icon="mdi-paperclip"

dense

hide-details

outlined

>

<template v-slot:selection="{ index, text }">

<v-chip 

v-if="index < 2" 

color="primary" 

dark 

label 

x-small

>

{{ text }}

</v-chip>

</template>

</v-file-input>

</v-col>

<v-col :cols="verify ? 2 : 0" v-if="verify">

<v-tooltip bottom="" v-if="importfile">

<template v-slot:activator="{ on }">

<v-btn 

color="primary"

v-on="on"  

block

@click="verifyRules()" 

>

<v-icon>mdi-checkbox-marked-outline</v-icon>

</v-btn>

</template>

<span>Verificar</span>

</v-tooltip>

</v-col>

</v-row>

<xlsx-read :file="files">

<xlsx-json v-show="headers" @parsed="changeData($event)">

<template #default="{collection}">

<div style="mt-100">

<v-row>

<v-col>

<v-data-table

v-if="items.length > 0"

dense

:headers="headers"

:items="items"

></v-data-table>

</v-col>

</v-row>

</div>

</template>

</xlsx-json>

</xlsx-read>

</section>

</div>
</template>

<script>
import { XlsxRead, XlsxJson } from "vue-xlsx";

export default {

components: {

XlsxRead,

XlsxJson,

},

props: { 

value: null, 

label: { 

type: String, 

default: "Seleccione Archivo" 

},

verify : {

type : Boolean,

default : false

}

},

data() {

return {

items: [],

file: null,

files: null,

collection: [{ a: 1, b: 2 }],

headers : [],

importfile: true

};

},

methods: {

clear() {

this.files = [];

this.items = [];

this.$emit("clear");

this.$emit("input");

},

changeData(data) {

this.$emit("changeData", data);

this.$emit("input", data);

this.items = data;

this.createdTable();

},

createdTable()

{

if(this.items.length > 0)

{

let headerspre = [];

headerspre = Object.keys(this.items[0]);

headerspre.forEach(element => {

let obj = {

text : element,

value : element

};

this.headers.push(obj);

});

}

},

verifyRules()

{

this.$emit("verifyRules", this.items);

}

},
};
</script> -->
