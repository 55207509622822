<template>
<div>

    <!-- <vc-loading :processing="processing" :errors="errors" :title="'Cargando datos.'" @close="processing=false;" /> -->
    <v-row class="mt-4" justify="center">

        <v-card elevation="0" style="margin-top: 2px;border-radius:10px">
            <v-row justify="center">
                <v-col cols="12" style="text-align-last: center;">
                    <h1 style="color:#1f4d9d;font-family: Calibri">¡BIENVENIDO!</h1>
                    <br>
                    <!-- {{user}} -->
                    <h3 style="color:#16a94b;margin-top:-20px;font-family:;font-weight: 700;" class="capitalize">{{user.UsrFullName == null ? "ERROR AL CARGAR DATOS": user.UsrFullName}}</h3>
                </v-col>
            </v-row>

            <div class="mb-3 mt-6 ml-5 mr-5 card">
                <div class="card-header-tab card-header">
                
                </div>
                <div class="no-gutters row">
                    <div class="col-sm-6 col-md-4 col-xl-3">
                        <div class="card no-shadow rm-border bg-transparent widget-chart text-left ">
                            <div class="icon-wrapper iconhome" @click="showCotization=false;showNews=false;showProvider=true;showQuestion=false;showPayment=false">
                                <div class="icon-wrapper-bg opacity-10 bg-warning "></div>
                                <i class="lnr-user text-white"></i>
                            </div>
                            <div class="widget-chart-content">
                                <div class="widget-subheading">....</div>
                                <div class="widget-numbers" style="font-size: 24px;">Datos Personales</div>
                            </div>
                        </div>
                        <div class="divider m-0 d-md-none d-sm-block"></div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-xl-3">
                        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                            <!-- <div class="icon-wrapper iconhome" @click="redirect"> -->
                            <div class="icon-wrapper iconhome" @click="showCotization=true;showNews=false;showProvider=false;showQuestion=false;showPayment=false">
                                <div class="icon-wrapper-bg opacity-9 bg-info"></div>
                                <i class="lnr-home text-white"></i>
                            </div>
                            <div class="widget-chart-content">
                                <div class="widget-subheading">....</div>
                                <div class="widget-numbers" style="font-size: 24px;">
                                    <span>Mis Cotizaciones</span>
                                </div>
                            </div>
                        </div>
                        <div class="divider m-0 d-md-none d-sm-block"></div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-xl-3">
                        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                            <div class="icon-wrapper iconhome" @click="showCotization=false;showNews=true;showProvider=false;showQuestion=false;showPayment=false">
                                <div class="icon-wrapper-bg opacity-9 red"></div>
                                <i class="lnr-license text-white"></i>
                            </div>
                            <div class="widget-chart-content">
                                <div class="widget-subheading">...</div>
                                <div class="widget-numbers" style="font-size: 24px;">
                                    <span>Mis Ordenes</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-4 col-xl-3">
                        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                            <div class="icon-wrapper iconhome" @click="showCotization=false;showNews=false;showProvider=false;showQuestion=false;showPayment=true">
                                <div class="icon-wrapper-bg opacity-9 bg-success"></div>
                                <i class="lnr-license text-white"></i>
                            </div>
                            <div class="widget-chart-content">
                                <div class="widget-subheading">Proximamente.....</div>
                                <div class="widget-numbers" style="font-size: 24px;">
                                    <span>Mis Pagos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center d-block p-3 card-footer mt-7">
                    <h6>Gracias por ser parte esencial de nuestra red de proveedores a través de nuestro Portal dedicado.</h6>
                    <button class="btn btn-primary btn-md" @click="showQuestion=true;showCotization=false;showNews=false;showProvider=false;showPayment=false">
                        <i class="fas fa-web"></i>
                        <span class="mr-1"><a target="_blank" style="color:white">Preguntas frecuentes</a></span>
                    </button>
                   
                    <!-- <button class="btn btn-success btn-md ml-2">
                        <i class="fa fa-vr-cardboard"></i>
                        <span class="ml-2"><a href="https://imperu.com.pe/vista360/" target="_blank" style="color:white">Recorrido Virtual 360°</a></span>
                    </button> -->
                </div>
            </div>
        </v-card>

        <sup-cotization v-if="showCotization" />
        <sup-provider v-if="showProvider" />
        <sup-question-frecuent v-if="showQuestion" />
        <sup-order-purcharse v-if="showNews" />

        <!-- Noticias -->
        <!-- <v-col cols="12" > -->
        <v-card class="mt-2" elevation="0" style="margin-top: 2px;border-radius:10px" v-if="showPayment">
            <div class="card-body">

                <h4 class="card-title" style="font-size: 16px;">MIS PAGOS</h4>
                <v-divider></v-divider>
                <v-row>

                </v-row>
            </div>
        </v-card>
        <!-- </v-col> -->

    </v-row>
</div>
</template>

<script>
//Services

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
import SupCotization from './SupCotization.vue';
import SupProvider from './SupProvider.vue';
import SupQuestionFrecuent from './SupQuestionFrecuent.vue';
import SupOrderPurcharse from './SupOrderPurcharse.vue';

export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon,
        SupCotization,
        SupProvider,
        SupQuestionFrecuent,
        SupOrderPurcharse
    },
    props: {

        UsrName: {
            type: String,
            default: ""
        },
        user: {
            type: Object,
            default: {}
        }
    },
    data() {

        return {

            supplier: {},
            showCotization: false,
            showNews: false,
            showProvider: false,
            showQuestion: false,
            showPayment: false,

            //Loading
            processing: false,
            errors: null,

        };
    },
    mounted() {},

    methods: {

    },
};
</script>

<style lang="scss" scoped>
@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}

.bounce {
    animation: bounce 1s infinite;
}

.iconhome {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    /* Tipo de puntero predeterminado cuando no se pasa el mouse */
}

.iconhome:hover {
    cursor: pointer;
    /* Tipo de puntero cuando el mouse se pasa sobre el icono */
}
</style>
