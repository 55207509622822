import Service from "../Service";

const resource = "GenCostCenter/";

import Vue from "vue";

export default {
    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: {},
        });
    },
    update(obj, requestID) {
        return Service.put(resource + "update", obj, {
            params: {},
        });
    },
    delete(obj, requestID) {
        return Service.delete(resource + "delete", {
            data: obj
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {},
        });
    },

    searchtree(parameters, requestID) {
        return Service.post(resource + "searchtree", parameters, {
            params: {},
        });
    },

    list(CceLevel, NtpID, requestID) {
        return Service.post(resource + "list", {}, {
            params: {CceLevel : CceLevel, NtpID : NtpID},
        });
    },

    threelist(CceLevel, NtpID, requestID) {
        return Service.post(resource + "threelist", {}, {
            params: {CceLevel : CceLevel, NtpID : NtpID},
        });
    },

    getUrlSearchCostCenter() {
        return Vue.prototype.$http.defaults.baseURL + "GenCostCenter/list";
    },
};