<template>
<div>
    <v-card class="mt-2">
        <vc-header close :title="'Cuadro Comparativo. Cotizaciòn Nro. ' + cotizationLocal.CttDocumentNumber" :optionemit="optionEmit" :approve="optionApproved" :transactionApproved="transactionApproved" @approved="approved()" @close="$emit('close')" />

        <v-card outlined class="mt-1 pb-4" style="max-height: 650px;  overflow-y: auto;">
            <v-col>
                <form id="">
                    <!-- <div>
                        <v-card class="mt-1" outlined width="100%">
                            <v-card-title class="ml-3" style="font-size:11px">
                                <b>Leyenda</b>
                                <v-divider vertical class="ml-2"></v-divider>
                                <ul>
                                    <li>
                                        <v-chip fab :color="'#f5f5dc'" x-small>
                                        </v-chip> = Participante con mayor puntaje.
                                    </li>
                                </ul>
                                <br>

                            </v-card-title>
                        </v-card>
                    </div> -->
                    <v-col lg="12" cols="12">
                        <vc-header-title title="Items Requeridos" />
                        <v-row>
                            <v-col>
                                <v-data-table disable-sort class="table-bordered" dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="CtdID" :height="'auto'" hide-default-footer>

                                    <template v-slot:item.TypeCotization="{ item }">
                                        <v-icon :color="item.TypeCotization == 1 ? 'red' : 'green'" style="font-size:12px;">
                                            {{item.TypeCotization == 1 ? 'fas fa-car': 'fas fa-street-view'}}

                                        </v-icon>
                                        <label class="ml-2">{{item.TypeCotizationName}}</label>
                                    </template>

                                </v-data-table>
                            </v-col>
                        </v-row>

                    </v-col>

                    <v-col lg="12" cols="12">
                        <vc-header-title title="Cotizaciones recibidas" />
                        <v-row style="margin-top:-20px">
                            <v-col :lg="itemsParticipants.length == 1 ? '12': itemsParticipants.length == 2 ? '6' : '4'" cols="12" class="" v-for="(item) in itemsParticipants" :key="item.CtpID">

                                <v-row v-if="item.itemProvider !== null">
                                    <v-col>

                                        <v-card outlined width="100%" style="margin-top:10px;" :color="item.xFlagWinner == 1 ? '#f5f5dc' : ''">
                                            <v-card-title style="margin-top: -10px; margin-bottom: -16px; font-family: Calibri">
                                                <h5>{{item.CtpAlias}} - {{item.CtpName}} con Nro. Doc. {{item.CtpRuc}}</h5>
                                            </v-card-title>
                                        </v-card>

                                        <v-card outlined width="100%" style="margin-top:10px">
                                            <v-data-table class="table-hover table-bordered" outlined disable-sort dense hide-default-footer :items-per-page="-1" :headers="headers" :items="item.itemProvider.itemsDetails" item-key="CpdID">
                                                <template v-slot:item.OddAmount="{ item }">
                                                    <div>{{
                            item.OddAmount > 0
                              ? item.OddAmount.toFixed(2)
                              : '0.00'
                          }}</div>
                                                </template>
                                                <template v-slot:item.OddUnitPrice="{ item }">
                                                    <div>{{
                            item.OddUnitPrice > 0
                              ? item.OddUnitPrice.toFixed(2)
                              : '0.00'
                          }}</div>
                                                </template>
                                            </v-data-table>
                                        </v-card>

                                        <!-- <v-col cols="12"> -->
                                        <v-card elevation="0" outlined height="auto" class="mt-1 pb-3">
                                            <!-- <vc-header-title /> -->

                                            <v-row style="margin: auto">
                                                <v-col cols="12" lg="7" md="7" class="s-col-form pb-3">
                                                    <v-divider class="mt-0 mb-4"></v-divider>
                                                    <v-row style="margin-top:-15px;margin-bottom:-17px">
                                                        <v-col lg="6" class="s-col-form">
                                                            <v-label><b>OBSERVACIONES</b></v-label>
                                                        </v-col>
                                                    </v-row>
                                                    <div class="mt-5" style="font-family: Calibri;font-size: 10px;overflow: overlay;max-height: 300px;" v-html="item.itemProvider.OrdObservation"></div>
                                                </v-col>
                                                <v-col cols="12" lg="5" md="5" class="s-col-form mt-0">

                                                    <v-divider class="mt-0 mb-4"></v-divider>
                                                    <v-row style="margin-top:-15px;margin-bottom:-17px">
                                                        <v-col lg="6" class="s-col-form">
                                                            <v-label><b>V. NETO</b></v-label>
                                                        </v-col>
                                                        <v-col lg="6" cols="6" class="s-col-form">
                                                            <!-- <h5 class="text-right"> -->
                                                            <h6 class="text-right">
                                                                {{
                            item.itemProvider.OrdTotalNet >= 1000
                              ? item.itemProvider.OrdTotalNet.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : item.itemProvider.OrdTotalNet.toFixed(2)
                          }}
                                                            </h6>
                                                            <!-- </h5> -->
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider class="mt-4 mb-4"></v-divider>
                                                    <!-- <v-row style="margin-top:-15px;margin-bottom:0px">
                                                        <v-col lg="6" class="s-col-form">
                                                            <v-label><b>COSTOS DIRECTOS</b></v-label>
                                                        </v-col>
                                                        <v-col lg="6" cols="6" class="s-col-form">
                                                            <h6 class="text-right">
                                                                {{
                            item.itemProvider.OrdDirectCost.toFixed(2)
                           }}
                                                            </h6>
                                                        </v-col>
                                                    </v-row> -->

                                                    <v-row style="margin-top:-10px;margin-bottom:0px" v-if="item.itemProvider.OrdGeneralgto>0">
                                                        <v-col lg="6" class="s-col-form">
                                                            <v-label><b>GTO GENERALES</b></v-label>
                                                        </v-col>
                                                        <v-col lg="6" cols="6" class="s-col-form">
                                                            <!-- <h6 class="text-right"> -->
                                                            <h6 class="text-right">
                                                                {{
                            item.itemProvider.OrdGeneralgto.toFixed(2)
                           }}
                                                            </h6>
                                                            <!--  </h6> -->
                                                        </v-col>
                                                    </v-row>
                                                    <v-row style="margin-top:-10px;margin-bottom:0px" v-if="item.itemProvider.OrdUtilities>0">
                                                        <v-col lg="6" class="s-col-form">
                                                            <v-label><b>UTILIDADES</b></v-label>
                                                        </v-col>
                                                        <v-col lg="6" cols="6" class="s-col-form">
                                                            <!--  <h6 class="text-right"> -->
                                                            <h6 class="text-right">
                                                                {{
                            item.itemProvider.OrdUtilities.toFixed(2)
                           }}
                                                            </h6>
                                                            <!-- </h6> -->
                                                        </v-col>
                                                    </v-row>
                                                    <v-row style="margin-top:-10px;margin-bottom:0px" v-if="item.itemProvider.OrdDiscount>0">
                                                        <v-col lg="6" class="s-col-form">
                                                            <v-label><b>DESCUENTO</b></v-label>
                                                        </v-col>
                                                        <v-col lg="6" cols="6" class="s-col-form">
                                                            <!-- <h5 class="text-right"> -->
                                                            <h6 class="text-right">
                                                                {{
                            item.itemProvider.OrdDiscount.toFixed(2)
                           }}
                                                            </h6>
                                                            <!--  </h5> -->
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider class="mt-0 mb-4"></v-divider>
                                                    <v-row style="margin-top:-15px">
                                                        <v-col lg="6" class="s-col-form">
                                                            <v-label><b>SUB TOTAL</b></v-label>
                                                        </v-col>
                                                        <v-col lg="6" class="s-col-form">
                                                            <h6 class="text-right">
                                                                {{
                            item.itemProvider.OrdSubTotal >= 1000
                              ? item.itemProvider.OrdSubTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              :  item.itemProvider.OrdSubTotal.toFixed(2)
                          }}
                                                            </h6>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row style="margin-top:-10px;">
                                                        <v-col lg="6" class="s-col-form">
                                                            <v-label><b>IMPUESTO {{'(' + item.itemProvider.OrdTaxPercentage + '%)'}}</b></v-label>
                                                        </v-col>
                                                        <v-col lg="6" class="s-col-form">
                                                            <h6 class="text-right">
                                                                {{
                            item.itemProvider.OrdTaxAmount >= 1000
                              ? item.itemProvider.OrdTaxAmount.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              :  item.itemProvider.OrdTaxAmount.toFixed(2)
                          }}
                                                            </h6>
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider class="mt-2 mb-0"></v-divider>
                                                    <!-- <v-row style="margin-top:0px;">
                                                        <v-col lg="6" class="s-col-form">
                                                            <v-label><b>TOTAL</b></v-label>
                                                        </v-col>
                                                        <v-col lg="6" class="s-col-form">
                                                            <h5 class="text-right">
                                                                {{
                            item.itemProvider.OrdTotal >= 1000
                              ? item.itemProvider.OrdTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              :  item.itemProvider.OrdTotal.toFixed(2)
                          }}
                                                            </h5>
                                                        </v-col>
                                                    </v-row> -->
                                                    <v-row>
                                                        <v-col class="s-col-form">
                                                            <v-label><b>TOTAL</b></v-label>
                                                        </v-col>
                                                        <v-col style="color: #812121;border-radius: 10px;outline-style: ;" class="s-col-form">

                                                            <h3 style="transform: scale(1.0);" class="text-right">

                                                                {{item.itemProvider.TypeCurrencyAbbreviation + ' '}} {{item.itemProvider.OrdTotal >= 1000
                              ? item.itemProvider.OrdTotal.toLocaleString("es-MX", {
                                    minimumFractionDigits: 2
                                })
                              : item.itemProvider.OrdTotal.toFixed(2) }}
                                                            </h3>
                                                        </v-col>
                                                    </v-row>

                                                    <!--                                                       <v-row>
                                                            <v-col class="s-col-form">
                                                                <v-label><b>IMPUESTO{{ " (" + orderLocal.OrdTaxPercentage + "%)" }}</b></v-label>
                                                            </v-col>
                                                            <v-col class="s-col-form">
                                                                <h5 class="text-right">

                                                                    {{
                            orderLocal.OrdTaxAmount >= 1000
                              ? orderLocal.OrdTaxAmount.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTaxAmount > 0 ? orderLocal.OrdTaxAmount.toFixed(2): 0
                          }}
                                                                </h5>
                                                            </v-col>
                                                        </v-row>
                                                        <v-divider class="mt-4 mb-4"></v-divider>
                                                        <v-row style="margin-top:-15px;">
                                                            <v-col class="s-col-form">
                                                                <v-label><b>TOTAL</b></v-label>
                                                            </v-col>
                                                            <v-col class="s-col-form">
                                                                <h4 class="text-right">
                                                                    {{
                            orderLocal.OrdTotal >= 1000
                              ? orderLocal.OrdTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTotal > 0 ? orderLocal.OrdTotal.toFixed(2) : 0

                          }}
                                                                </h4>
                                                            </v-col>
                                                        </v-row> -->
                                                </v-col>
                                                <v-col cols="12">
                                                    <i class="fa-solid fa-download mr-2" style="color:green" @click="downloadFile(item.itemProvider.Attach1)"></i>
                                                    <b>{{item.itemProvider.Attach1}}</b>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                        <!--  </v-col> -->

                                    </v-col>
                                </v-row>

                            </v-col>
                        </v-row>

                    </v-col>

                    <v-col lg="12" cols="12">
                        <vc-header-title title="EVALUACIÓN" />
                        <table id="customers" class="mt-3">
                            <thead>
                                <tr style="text-align:center">
                                    <td scope="col"><b>
                                            <h6>Participante</h6>
                                        </b></td>
                                    <td scope="col">
                                        <h6>Moneda</h6>
                                    </td>
                                    <!-- <td scope="col">
                                        <h6>Monto</h6>
                                    </td> -->
                                    <td scope="col">
                                        <h6>Forma Pago</h6>
                                    </td>
                                    <td scope="col">
                                        <h6>Condición Pago</h6>
                                    </td>

                                    <td scope="col">
                                        <h6>Días Credito</h6>
                                    </td>

                                    <td scope="col">
                                        <h6>T. entrega (días)</h6>
                                    </td>
                                    <td scope="col">
                                        <h6>Garantia(días)</h6>
                                    </td>
                                    <td scope="col">
                                        <h6>Monto</h6>
                                    </td>
                                    <td scope="col">
                                        <h6>Evaluación Total - 100%</h6>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>

                                <tr v-for="(item) in itemsParticipants" :key="item.CtpID">

                                    <td>
                                        <h5>{{item.CtpName}}</h5>
                                    </td>
                                    <td style="text-align:center">
                                        <h5>{{item.itemProvider.TypeCurrencyAbbreviation}}</h5>
                                    </td>

                                    <td style="text-align:center">
                                        <h5>{{item.itemProvider.TypePaymentName}}</h5>
                                        <h6>{{item.itemProvider.TypePaymentScore}}%</h6>
                                    </td>
                                    <td style="text-align:center">
                                        <h5>{{item.itemProvider.ConDescription}}</h5>
                                        <h6>{{item.itemProvider.ConScore}}%</h6>
                                    </td>
                                    <td style="text-align:center">
                                        <h5>{{item.itemProvider.DayDescription}}</h5>
                                        <h6>{{item.itemProvider.DayScore}}%</h6>
                                    </td>
                                    <td style="text-align:center">
                                        <h5>{{item.itemProvider.OrdDeliveryDay}}</h5>
                                        <h6>{{item.itemProvider.OrdDeliveryDayScore}}%</h6>
                                    </td>
                                    <td style="text-align:center">
                                        <h5>{{item.itemProvider.OrdWarrantyDay}}</h5>
                                        <h6>{{item.itemProvider.OrdWarrantyDayScore}}%</h6>
                                    </td>
                                    <!-- <td style="text-align:center">
                                        <h5>{{item.itemProvider.OrdTotalScore}}</h5>
                                    </td> -->
                                    <td style="text-align:right">
                                        <h5 style="transform: scale(1.0);">{{item.itemProvider.OrdTotal > 1000 ? item.itemProvider.OrdTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                }) : item.itemProvider.OrdTotal.toFixed(2)}} </h5>

                                        <h6>{{item.itemProvider.OrdTotalScore}}%</h6>
                                    </td>
                                    <td :style="item.xFlagWinner == 1 ? 'text-align:center;background-color:#f5f5dc': 'text-align:center;'">
                                        <!-- item.xFlagWinner == 1 ? '#f5f5dc' : '' -->
                                        <h5>{{item.itemProvider.FullScore}}%</h5>
                                    </td>

                                    <!-- 

                                    <td style="text-align:center" v-if="item.itemProviderCE !== null">

                                        <b>
                                            <h4>{{item.itemProviderCE.TypeCreditValue}}%</h4>
                                        </b>
                                        {{item.itemProviderCE.TypeCreditName}}
                                    </td>
                                    <td style="text-align:center" v-if="item.itemProviderCE !== null">
                                        <b>
                                            <h4>{{item.itemProviderCE.TypePaymentValue}}%</h4>
                                        </b>
                                        {{item.itemProviderCE.TypePaymentName}}
                                    </td>
                                    <td style="text-align:center" v-if="item.itemProviderCE !== null">
                                        <b>
                                            <h4>{{item.itemProviderCE.PrvDeliveryTimeValue}}%</h4>
                                        </b>
                                        {{item.itemProviderCE.PrvDeliveryTime}}
                                    </td>
                                    <td style="text-align:center" v-if="item.itemProviderCE !== null">
                                        <b>
                                            <h4>{{item.itemProviderCE.PrvTimeGarantiaValue}}%</h4>
                                        </b>
                                        {{item.itemProviderCE.PrvTimeGarantia}}
                                    </td>
                                    <td style="text-align:right" v-if="item.itemProviderCE !== null">
                                        <b>
                                            <h4>{{item.itemProviderCE.OrdTotalValue}}%</h4>
                                        </b>
                                    </td>

                                    <td style="text-align:right" v-if="item.itemProviderCE !== null">

                                        <b>
                                            <h4>{{item.itemProviderCE.FullScore}}%</h4>
                                        </b>

                                    </td> -->

                                </tr>

                            </tbody>

                        </table>

                    </v-col>

                    <v-col lg="12" cols="12">

                        <v-row>
                            <v-col cols="12" lg="6" md="6">
                                <vc-header-title title="ELECCION DE GANADOR">
                                    <template v-slot:options>
                                        <v-btn v-if="cotizationLocal.CttStatus==4 && optionApproved" elevation="0" x-small class="mr-3" style="text-transform: inherit;" @click="openReadjustment">Enviar a reajuste</v-btn>

                                        <!-- <v-checkbox v-if="cotizationLocal.CttStatus==4" color="success" class="mt-0" hide-details="" label="Solicitar reajuste" v-model="readjustment"></v-checkbox> -->

                                        <v-btn v-if="cotizationLocal.CttStatus==4 && optionApproved" elevation="0" x-small @click="send(2)" class="" style="text-transform: inherit;">Enviar a ganador</v-btn>
                                    </template>
                                </vc-header-title>
                                <v-card outlined width="100%">
                                    <v-data-table show-select single-select dense hide-default-footer :items-per-page="-1" v-model="selectedPart" :items="itemsParticipants" :headers="headersParticpante" item-key="CtpID">
                                        <template v-slot:item.WinComments={item}>
                                            <vc-text v-model="item.WinComments"></vc-text>
                                        </template>
                                        <template v-slot:item.itemProvider.FullScore={item}>
                                            <v-chip x-small color="success" v-if="item.xFlagWinner == 1">
                                                {{item.itemProvider.FullScore}}
                                            </v-chip>
                                            <v-chip x-small v-else>
                                                {{item.itemProvider.FullScore}}
                                            </v-chip>
                                        </template>
                                    </v-data-table>

                                </v-card>
                            </v-col>
                            <v-col cols="12" lg="6" md="6">

                                <v-row style="margin-top:-15px">

                                    <v-col cols="12">
                                        <vc-header-title title="PART. PARA REAJUSTE">
                                            <template v-slot:options>
                                                <v-btn v-if="cotizationLocal.CttStatus==4 && optionApproved && itemsReadjustment.length > 0" elevation="2" small class="mr-3" style="text-transform: inherit;" @click="createReajuste">G. Proceso reajuste.</v-btn>
                                            </template>
                                        </vc-header-title>
                                        <v-data-table dense hide-default-footer :items-per-page="-1" :items="itemsReadjustment" :headers="headersWinner" item-key="CtpID">
                                            <template v-slot:item.Delete={item}>
                                                <v-icon v-if="cotizationLocal.CttStatus==4" color="red" style="font-size:16px;" @click="deleteWin(item)">mdi-close</v-icon>
                                            </template>
                                        </v-data-table>

                                    </v-col>

                                    <v-col cols="12">
                                        <vc-header-title title="GANADOR">
                                        </vc-header-title>
                                        <v-data-table dense hide-default-footer :items-per-page="-1" :items="itemsWinner" :headers="headersWinner" item-key="CtpID">
                                            <template v-slot:item.Delete={item}>

                                                <v-icon v-if="cotizationLocal.CttStatus==4" color="red" style="font-size:16px;" @click="deleteWin(item)">mdi-close</v-icon>
                                            </template>
                                        </v-data-table>

                                    </v-col>
                                </v-row>

                            </v-col>

                        </v-row>

                    </v-col>
                    <v-col lg="12" cols="12" v-if="readjustment">

                        <v-row>
                            <v-col cols="12" lg="6" md="6">
                                <vc-header-title title="Motivos para reajuste">
                                </vc-header-title>
                                <v-card outlined width="100%">
                                    <vc-select-definition :check="true" :def="1057" v-model="selectedReason" />
                                </v-card>
                            </v-col>
                        </v-row>

                    </v-col>

                </form>
            </v-col>

        </v-card>

    </v-card>
    <v-dialog v-model="showReadjustment" v-if="showReadjustment" max-width="500">
        <vc-header title="Reajuste" save @save="send(1)" close @close="showReadjustment=false" />
        <v-row style="margin:auto">
            <v-card>
                <v-col cols="12">
                    <vc-header-title title="Motivos para reajuste">
                    </vc-header-title>
                    <v-card outlined width="100%">
                        <vc-select-definition :check="true" :def="1057" v-model="selectedReason" />
                    </v-card>
                </v-col>

                <!-- <v-col cols="12">

                    <v-btn v-if="cotizationLocal.CttStatus==4" elevation="0" small block color="info" class="mr-3" style="text-transform: inherit;" @click="send(1)">Enviar a reajuste</v-btn>
                </v-col> -->
            </v-card>

        </v-row>

    </v-dialog>
</div>
</template>

<script>
//Services
import _sCotization from "@/services/Logistics/LgsCotizationService";
import _sApprovalTransaction from "@/services/Security/SecApprovalTransactionService";
import _sHelper from "@/services/HelperService.js";

export default {
    components: {

    },

    props: {
        value: {},
    },
    data() {
        return {
            cotizationLocal: {},
            headersSAP: [{
                    text: "Tipo",
                    value: "TypeCotization",
                    width: 100,
                },

                {
                    text: "Código Art.",
                    value: "ArtCode",
                    width: 100,
                },
                {
                    text: "Descripción",
                    value: "CtdDescription",

                },
                {
                    text: "Cant",
                    value: "CtdQuantity",
                    width: 120,
                },

                {
                    text: "Und",
                    value: "CtdUnitMeasurement",
                    width: 100,
                },
                {
                    text: "Comentario",
                    value: "CtdObservation",
                    width: 240,
                },

            ],
            itemsSAP: [],
            selectedSAP: [],

            //Cotizaciones
            headers: [{
                    text: "Description",
                    value: "CtdDescription",

                },

                {
                    text: "Cant. Stock.",
                    value: "OddQuantity"
                },

                {
                    text: "Precio Uni.",
                    value: "OddUnitPrice",
                },
                {
                    text: "V. Neto",
                    value: "OddAmount",

                },
                /* {
                    text: "Comentario",
                    value: "EtdObservation",

                }, */

            ],
            itemsParticipants: [],

            //Ganadores
            headersParticpante: [{
                    text: "Ruc",
                    value: "CtpRuc",
                    sortable: false,
                },
                {
                    text: "Participante",
                    value: "CtpName",
                    sortable: false,
                },
                {
                    text: "Puntaje",
                    value: "itemProvider.FullScore",
                    sortable: false,
                },
                {
                    text: "Comentario",
                    value: "WinComments",
                    sortable: false,
                },
            ],
            selectedPart: [],

            //Reajuste
            readjustment: false,
            itemsReadjustment: [],
            selectedReason: [],
            showReadjustment: false,

            //Winner
            itemsWinner: [],
            headersWinner: [{
                    text: "X",
                    value: "Delete",
                    sortable: false,
                },
                {
                    text: "Ruc",
                    value: "CtpRuc",
                    sortable: false,
                },
                {
                    text: "Participante",
                    value: "CtpName",
                    sortable: false,
                },
                {
                    text: "Puntaje",
                    value: "WinFullScore",
                    sortable: false,
                },
                {
                    text: "Comentario",
                    value: "WinComments",
                    sortable: false,
                },
            ],

            //Transacciones
            optionApproved: false,
            transactionApproved: null,
            optionEmit: true,

        }
    },

    computed: {
        isEditable() {
            return this.cotizationLocal.CttStatus == 1 || this.cotizationLocal.CttStatus == 4;

        },
    },

    watch: {

    },

    created() {
        this.initialize()
    },

    mounted() {

    },

    methods: {
        initialize() {
            if (this.value != null) {
                _sCotization
                    .byID({
                        CttID: this.value
                    })
                    .then((r) => {
                        this.cotizationLocal = r.data.Result
                        console.log("this.cotizationLocal", this.cotizationLocal);

                        if (this.cotizationLocal !== null) {

                            this.itemsSAP = this.cotizationLocal.itemsDetails
                            this.itemsParticipants = this.cotizationLocal.itemsParticipant.filter(x => x.CtpStatus == 2 && x.itemProvider !== null)
                            this.itemsReadjustment = this.cotizationLocal.itemsWinner.filter(x => x.TypeWinner == 1) //Reajuste
                            this.itemsWinner = this.cotizationLocal.itemsWinner.filter(x => x.TypeWinner == 2) //Ganador

                            this.transactionApproved = this.cotizationLocal.Transaction;
                            if (this.cotizationLocal.Transaction != null)
                                if (this.cotizationLocal.Transaction.UsersApproved.length > 0)
                                    if (
                                        this.cotizationLocal.Transaction.UsersApproved.find(
                                            (x) => x.UsrID == this.$fun.getUserID()
                                        )
                                    )
                                        if (this.cotizationLocal.CttStatus == 4) this.optionApproved = true;
                        }

                    });
            }
        },
        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },

        saveWinner(item) {
            _sCotization.savewinner(item).then(resp => {
                if (resp.status == 200) {
                    this.selectedReason = []
                    this.showReadjustment = false
                    this.initialize()
                }
            });
        },

        deleteWin(item) {
            this.saveWinner(item);
        },

        openReadjustment() {

            if (this.selectedPart.length <= 0) {
                this.readjustment = false
                this.$fun.sweetAlert("Seleccione un participante.", "warning")

                return
            }

            this.showReadjustment = true

        },

        send(op) {

            if (this.selectedPart.length <= 0) {
                this.$fun.sweetAlert("Seleccione un registro.", "warning")
                return
            }

            if (this.selectedPart.length > 0) {

                var itemsReason = []
                console.log(this.selectedReason);
                for (let index = 0; index < this.selectedReason.length; index++) {
                    const element = this.selectedReason[index];
                    console.log(element);
                    itemsReason.push({
                        TypeReason: element
                    })
                }

                let obj = this.selectedPart[0];
                let item = {
                    CtpID: obj.CtpID,
                    CttID: obj.CttID,
                    CtpRuc: obj.CtpRuc,
                    CtpName: obj.CtpName,
                    WinFullScore: obj.itemProvider.FullScore,
                    WinComments: obj.WinComments,
                    TypeWinner: op,
                    reason: itemsReason,
                    UsrCreateID: this.$fun.getUserID()
                }

                console.log("ganador", item);

                //Se envia a Reajuste
                if (op == 1) {
                    if (this.itemsWinner.length > 0) {
                        this.$fun.sweetAlert("Para enviar a Reajuste, no debe existir ganador.", "warning")
                        return
                    }

                    if (this.selectedReason.length <= 0) {
                        this.$fun.sweetAlert("Seleccione motivos para reajuste de cotización.", "warning")
                        return
                    }

                    this.saveWinner(item)
                }

                //Se envia a ganador
                if (op == 2) {
                    if (this.itemsReadjustment.length > 0) {
                        this.$fun.sweetAlert("Para enviar a ganador, no debe existir participantes en Reajuste.", "warning")
                        return
                    }

                    if (this.itemsWinner.length > 0) {
                        this.$fun.sweetAlert("Solo se puede elegir a un ganador.", "warning")
                        return
                    }

                    this.saveWinner(item)
                }
            }

        },

        createReajuste() {
            let item = {}
            if (this.itemsReadjustment.length > 0) {
                this.$fun.sweetAlert("Solicitar Reajuste en Cotización ?", "question").then(val => {
                    if (val.value) {
                        item.CttID = this.cotizationLocal.CttID
                        item.UsrCreateID = this.$fun.getUserID();
                        _sCotization.createversion(item).then(resp => {
                            if (resp.status == 200) {
                                this.$fun.sweetAlert("Solicitado Correctamente", "success");
                                this.$emit("close");
                            }
                        });
                    }
                })

            } else {
                this.$fun.sweetAlert("Agregue Participantes para reajuste.", "warning")
            }
        },

        approved() {

            if (this.itemsWinner.length <= 0) {
                this.$fun.sweetAlert("Para Terminar el proceso de Cotizacion, debe existir 1 ganador.", "warning")
                return
            }

            if (this.transactionApproved)
                this.$fun.sweetAlert("Seguro de aprobar Cotización?", "question").then((r) => {
                    if (r.value) {
                        this.transactionApproved.AtsStatus = 2;
                        this.transactionApproved.UsrCreateID = this.$fun.getUserID();

                        _sApprovalTransaction.approved(this.transactionApproved, this.$fun.getUserID()).then((rq) => {
                                if (rq.status == 200) {
                                    this.$fun.sweetAlert("Aprobado Correctamente", "success");
                                    this.$emit("close");
                                }

                            },
                            (e) => {});
                    }
                });
        },

        downloadFile(url) {
            console.log("fileeeeeeee", url);
            if (url == null) return;
            _sHelper.download(url, 3).then((r) => {
                this.$fun.download(r.data, url);
            });
        }
    }
};
</script>

<style>
#customers {
    font-family: 'Calibri';
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}
</style>
