import Service from "../Service";

const resource = "/SecApprovaltransaction/";

export default {
    approved(objTransaction, requestID) {
        return Service.post(resource + "approved", objTransaction, {
            params: { requestID: requestID },
        });
    },

    createCircuit(objTransaction, requestID) {
        return Service.post("SecCircuitCreate/" + "createcircuit", objTransaction, {
            params: { requestID: requestID },
        });
    },

    getCircuitReq(objTransaction, requestID) {
        return Service.post("SecCircuitCreate/" + "getcircuitrequirement", objTransaction, {
            params: { requestID: requestID },
        });
    },

    getCircuitOrd(objTransaction, requestID) {
        return Service.post("SecCircuitCreate/" + "getcircuitorder", objTransaction, {
            params: { requestID: requestID },
        });
    },

    statusapprovaltransaction(objTransaction, requestID) {
        return Service.post(resource + "statusapprovaltransaction", objTransaction, {
            params: { requestID: requestID },
        });
    },


};