<template>
<div>
    <vc-loading :processing="processing" :errors="errors" @close="processing=false;" />
    <sup-service-hour />

    <vc-header :title="'Nro. Orden: ' + orderLocal.OrdDocumentNumber" close @close="$emit('close')">
        <template v-slot:options>
            <v-btn title="Subir Files" elevation="0" style="text-transform:capitalize" small text @click="save">
                <v-icon class="mr-2" style="font-size: 14px;color:green">fa-solid fa-upload</v-icon>Subir
            </v-btn>
        </template>
    </vc-header>

    <v-card class="mt-0" style="max-height: 650px;  overflow-y: auto;">

        <v-card outlined class="mt-0">
            <v-row style="margin:auto; margin-top:10px; margin-bottom: 10px;">

                <v-col cols="12" class="s-col-text">
                    <v-card elevation="3" class="pb-6">
                        <form id="formupload">
                            <v-row style="margin:auto">
                                <v-col cols="12">
                                    <vc-text id="NroComp" ref="NroComp" label="Nro. Comprobante" autofocus v-model="NroComp"></vc-text>
                                </v-col>
                                <v-col lg="4" md="3" cols="12" v-for="(item, index) in itemAttach" :key="index" class="s-col-form">
                                    <v-file-input persistent-hint style="margin-bottom:-20px" :show-size="false" :ref="'attach' + item.RqdID" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                        <template slot="append">
                                            <label>{{item.RqdSize / 1048576}}MB</label>
                                        </template>
                                    </v-file-input>
                                </v-col>
                                <v-col cols="12">
                                    <vc-textarea id="VouObservation" ref="VouObservation" label="Comentario" v-model="VouObservation"></vc-textarea>
                                </v-col>
                            </v-row>
                        </form>
                    </v-card>
                </v-col>

            </v-row>
        </v-card>

        <v-card outlined class="mt-3">
            <v-row style="margin:auto; margin-top:10px; margin-bottom: 10px;">
                <v-col cols="12">
                    <vc-header-title title="Comprobantes cargados." />

                    <v-row>
                        <v-col cols="12">
                            <div class="card-body">

                                <v-expansion-panels class="mt-1" focusable elevation="0" v-for="(item, index) in Object.keys(itemAttachReceivedGroup)" :key="index">
                                    <v-expansion-panel class="pt-0 pb-0" @click="loadComments(itemAttachReceivedGroup[item][0])">
                                        <v-expansion-panel-header style="min-height: 0px; height: 0px">
                                            <b>
                                                <v-icon left style="font-size:16px"> mdi-checkbox-marked-circle </v-icon>{{ item }}
                                                |
                                                <v-chip v-if="itemAttachReceivedGroup[item][0].VouReception" x-small :color="'info'">{{'Recepcionado'}}</v-chip>
                                                <!-- - -->
                                                <!-- <v-chip x-small :color="itemAttachReceivedGroup[item][0].VouAccept ? 'success' : 'error'">{{itemAttachReceivedGroup[item][0].VouAccept ? 'Registrado' : 'No Registrado'}}</v-chip> -->

                                            </b>
                                        </v-expansion-panel-header>

                                        <v-expansion-panel-content style="margin-top: 15px;">

                                            <v-col cols="12">

                                                <v-card elevation="1">

                                                    <v-data-table disable-sort dense :items-per-page="-1" disable-pagination hide-default-footer :headers="headers" :items="itemAttachReceivedGroup[item]">
                                                        <template v-slot:item.RqdAttach="{ item }">
                                                            <label>
                                                                <!--  <v-icon class="mr-2" color="green" @click="openFile({name:item.RqdAttach})">mdi-download</v-icon> -->{{item.RqdAttach}}
                                                            </label>

                                                        </template>
                                                        <template v-slot:item.RqdDescription="{ item }">
                                                            <b>
                                                                {{item.RqdDescription}}
                                                            </b>

                                                        </template>
                                                        <template v-slot:item.action="{ item }">
                                                         
                                                            <v-btn color="info" icon elevation="0" class="capitalize ml-1 mr-1" small @click="editFile(item)" v-if="!item.VouReception && item.RqdActiveEditable">
                                                                <v-icon style="font-size:16px;margin-right:5px;">mdi-file-edit-outline</v-icon>
                                                            </v-btn>
                                                            <v-btn color="success" icon elevation="0" class="capitalize ml-1 mr-1" small @click="$emit('edit')">
                                                                <v-icon style="font-size:18px;margin-right:5px;" @click="downloadFile(item)">mdi-download</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <template v-slot:item.RqdActiveEditable="{ item }">
                                                            <b v-if="item.RqdActiveEditable">
                                                                Por corregir
                                                            </b>

                                                        </template>

                                                        

                                                    </v-data-table>
                                                </v-card>
                                            </v-col>

                                            <v-col cols="12">
                                                <vc-header-title title="Comentarios" />
                                                <div class="p-3">
                                                    <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">

                                                        <div class="vertical-timeline-item vertical-timeline-element" v-for="item in listComments" :key="item.VocID">
                                                            <div>
                                                                <span class="vertical-timeline-element-icon bounce-in"></span>
                                                                <div class="vertical-timeline-element-content bounce-in">
                                                                    <label class="">{{$fun.formatDateTimeView(item.VocDate)}} | {{item.VocDescription}}</label>
                                                                    <span class="vertical-timeline-element-date"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <vc-footer style="box-shadow: -2px 15px 15px 11px">

        </vc-footer>

    </v-card>

    <v-dialog class="elevation-0" v-if="showEdit" v-model="showEdit" transition="dialog-bottom-transition" max-width="60%">

        <div>
            <vc-header :title="''" close @close="showEdit=false">
            </vc-header>
            <v-card outlined class="mt-0">
                <v-row style="margin:auto; margin-top:10px; margin-bottom: 10px;">

                    <v-col cols="12" class="s-col-text">
                        <v-card elevation="3" class="pb-6">
                            <v-row style="margin:auto">
                                <v-col cols="12" v-for="(item, index) in itemAttach.length > 0 ? itemAttach.filter(x=>x.RqdID == itemFile.RqdID) : []" :key="index" class="s-col-form">
                                    <v-file-input persistent-hint style="margin-bottom:-20px" :show-size="false" :ref="'attach' + item.RqdID" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                        <template slot="append">
                                            <v-btn color="info" icon elevation="0" class="capitalize ml-1 mr-1" small @click="edit(item)">
                                                <v-icon style="font-size:16px;margin-right:5px;">mdi-content-save</v-icon>
                                            </v-btn>
                                            <label>{{item.RqdSize / 1048576}}MB</label>
                                        </template>
                                    </v-file-input>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>

                </v-row>
            </v-card>
        </div>
    </v-dialog>



</div>
</template>

<script>
//Services
import _sOrderPurchase from "@/services/Logistics/LgsOrderPurcharseService";
import _sOrderPurchaseVoucher from "@/services/Logistics/LgsOrderPurcharseVoucherService";
import _sOrderPurchaseVoucherDetail from "@/services/Logistics/LgsOrderPurcharseVoucherDetailService";

import _sHelper from "@/services/HelperService.js";

//Functions
import _validaERP from "@/plugins/validaERP";
import SupServiceHour from './SupServiceHour.vue';

export default {
    components: {
        SupServiceHour

    },
    name: '',
    props: {
        value: 0
    },

    data() {
        return {
            //Loading
            processing: false,
            errors: null,
            orderLocal: {},
            showEdit: false,
            itemFile: {},
            NroComp: "",
            VouObservation: "",

            itemAttach: [],
            itemAttachReceivedGroup: [],
            listComments: [],
            headers: [{
                    text: "Acciones",
                    value: "action",
                    align: "center",
                    width: 120

                },
                {
                    text: "Descripcion",
                    value: "RqdDescription"

                },
                {
                    text: "Archivo",
                    value: "RqdAttach"

                },
                {
                    text: "Estado",
                    value: "RqdActiveEditable"

                },
            ],
            showHour:true
        };
    },

    created() {
        this.initialize()
        this.loadVoucher()
    },

    methods: {
        initialize() {
            _sOrderPurchase
                .byID({
                    OrdID: this.value,
                })
                .then((r) => {
                    this.orderLocal = r.data.Result
                    this.itemAttach = this.orderLocal.itemsSupportAttachs;

                });

        },

        loadVoucher() {
            _sOrderPurchaseVoucher
                .byOrd(this.value)
                .then((r) => {
                    console.log("loadVoucher ", r.data.Result);
                    this.itemAttachReceivedGroup = _.groupBy(r.data.Result.details, "VouNumber");

                });
        },

        loadComments(item) {
            _sOrderPurchaseVoucher
                .byID(item.VouID)
                .then((r) => {
                    this.listComments = r.data.Result.comments

                    console.log(this.listComments);

                });
        },

        save() {

            if (this.NroComp == null || this.NroComp.length <= 0) {
                this.$refs.NroComp.error("Ingrese Nro. Comprobante.")
                return
            }

            //Validamos Campos
            var resp = _validaERP.valida({
                form: "#formupload",
                items: {

                    NroComp: {
                        required: true,
                        minlength: 2,
                        value: this.NroComp,
                    },
                },
            });

            if (resp) {
                if (this.$fun.isValidExtension(this.itemAttach)) {
                    return
                }

                if (this.$fun.isValidSize(this.itemAttach)) {
                    return
                }

                if (this.$fun.isValidLong(this.itemAttach)) {
                    return
                }

                this.$fun.alert("¿Seguro de cargar archivos?", "question").then(val => {
                    if (val.value) {
                        this.processing = true

                        this.$fun.uploadFilesProvider(this.itemAttach, this.NroComp, this.orderLocal.SupDocumentNumber, this.orderLocal.OrdDocumentNumber).then(resp => {

                            if (resp.status == 200) {
                                let obj = {
                                    OprID: this.itemAttach[0].OprID,
                                    OrdID: this.orderLocal.OrdID,
                                    VouNumber: this.NroComp,
                                    VouObservation: this.VouObservation,
                                    CpyID: this.$fun.getCompanyID(),
                                    UsrCreateID: this.$fun.getUserID(),
                                    details: resp.data
                                }

                                _sOrderPurchaseVoucher.save(obj).then(r => {
                                    this.loadVoucher()
                                })
                            }
                            this.processing = false
                        })
                    }
                })
            }
        },

        editFile(item) {
            this.itemFile = item
            this.showEdit = true
        },
        edit(item) {

            if (this.$fun.isValidExtension([item])) {
                return
            }

            if (this.$fun.isValidSize([item])) {
                return
            }

            if (this.$fun.isValidLong([item])) {
                return
            }

            this.$fun.alert("¿Seguro de actualizar archivo?", "question").then(val => {
                if (val.value) {
                    this.processing = true

                    this.$fun.uploadFilesProvider([item], this.itemFile.VouNumber, this.orderLocal.SupDocumentNumber, this.orderLocal.OrdDocumentNumber).then(resp => {

                        if (resp.status == 200) {
                            var response = resp.data[0]
                            response.VodID = this.itemFile.VodID
                            response.UsrUpdateID = this.$fun.getUserID()
                            _sOrderPurchaseVoucherDetail.update(response).then(r => {
                                this.showEdit = false
                                this.loadVoucher()
                            })

                        }
                        this.processing = false
                    })
                }
            })
        },

        downloadFile(item) {
            if (item == null) return;
            _sHelper.downloadRute(item.RqdAttach, item.RqdRoute).then((r) => {
                this.$fun.download(r.data, item.RqdAttach);
            });
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
