import Service from "../Service";

const resource = "SupPortalProviders/";

export default {
    byruc(ruc) {
        return Service.get(resource + "byruc", {
            params: { ruc: ruc},
        });
    },


};