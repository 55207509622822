<template>
<div>
    <v-card class="mt-2">
        <vc-header style="font-family: Calibri" title="Registro de Cotización" dark close @close="$emit('close')" save @save="save()">
            <v-divider vertical></v-divider>
        </vc-header>

        <v-card outlined class="mt-0 pb-4" style="max-height: 650px;  overflow-y: auto;">
            <v-col>
                <form id="formsuppliercotization">
                    <v-row>

                        <v-col cols="12">
                            <vc-header-title title="Datos de Contacto" />

                            <!--  <v-col class="pr-0 pl-0"> -->
                            <v-row>
                                <v-col cols="12" lg="5" md="5" class="s-col-form">
                                    <vc-text id="SupConName" autofocus label="Contacto" v-model="orderLocal.SupConName"></vc-text>
                                </v-col>
                                <v-col cols="12" lg="2" md="2" class="s-col-form">
                                    <vc-text id="SupTelephone" label="Celular" number :min="0" v-model="orderLocal.SupTelephone"></vc-text>
                                </v-col>
                                <v-col cols="12" lg="2" md="2" class="s-col-form">
                                    <vc-text id="SupEmail" label="Correo" v-model="orderLocal.SupEmail"></vc-text>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" class="s-col-form">
                                    <vc-date id="CttExpirationOffer" label="Oferta válida hasta el" v-model="orderLocal.CttExpirationOffer"></vc-date>
                                </v-col>
                            </v-row>

                            <!-- </v-col> -->
                        </v-col>

                        <v-col lg="5" cols="12">
                            <vc-header-title title="Datos Generales">
                                <template v-slot:options>
                                    <h6 class="bounce" v-if="orderLocal.CttRequiredVisit" style="color:red">Cotización requiere de visita a campo</h6>
                                </template>
                            </vc-header-title>

                            <v-col class="pr-0 pl-0">
                                <!-- <form id="formcotization"> -->
                                <v-row>

                                    <!-- <v-card outlined width="100%" style="margin-top:0px"> -->
                                    <v-row style="margin:auto" class="pb-0 pt-0">

                                        <v-col cols="12">

                                            <ul class="ml-6">
                                                <li>
                                                    <b><label>Fecha Solicitud:</label> {{$moment(orderLocal.CttDate).format($const.FormatDateDB)}}</b>
                                                </li>
                                                <li>
                                                    <b><label>Fecha Entrega Cotización:</label> {{$moment(orderLocal.NeedDateCot).format($const.FormatDateDB)}}</b>
                                                </li>
                                                <li v-if="orderLocal.CttRequiredVisit">
                                                    <b><label>Fecha y Hora visita:</label> {{$moment(orderLocal.CttDateRequiredVisit).format($const.FormatDateDB)}}{{' '}}{{orderLocal.CttHourRequiredVisit}}</b>
                                                </li>
                                                <li v-if="orderLocal.CttRequiredVisit">
                                                    <b><label>Preguntar por:</label> {{orderLocal.NtpFullName}}</b>
                                                </li>
                                                <li>
                                                    <b><label>Lugar de entrega:</label> {{''}}</b>
                                                </li>
                                            </ul>
                                        </v-col>

                                    </v-row>
                                    <!-- </v-card> -->
                                </v-row>

                                <!-- </form> -->
                            </v-col>

                        </v-col>
                        <v-col lg="7" cols="12">
                            <vc-header-title title="Condiciones de Pago" />
                            <!-- <v-card outlined width="100%" style="margin-top:0px" class="pb-7"> -->
                            <v-row style="margin:auto">

                                <v-col cols="12" lg="4" class="s-col-form">
                                    <!-- <label>
                                        <h6>Forma de Pago</h6>
                                    </label>
                                    <v-divider class="mt-0" /> -->
                                    <v-select label="Forma de Pago" id="TypePayment" :items="typePayment" item-text="TypePaymentName" item-value="CppID" v-model="selectedPayment"></v-select>
                                </v-col>

                                <v-col cols="6" lg="4" class="s-col-form">
                                    <!-- <label>
                                        <h6>Condición de Pago</h6>
                                    </label>
                                    <v-divider class="mt-0" /> -->
                                    <v-select label="Condición de Pago" id="ConID" :items="paymentCondition" item-text="ConDescription" item-value="ConID" v-model="orderLocal.ConID"></v-select>
                                </v-col>
                                <v-col cols="6" lg="4" class="s-col-form">
                                    <!--  <label>
                                        <h6>Días Credito</h6>
                                    </label>
                                    <v-divider class="mt-0" /> -->
                                    <v-select label="Días Credito" id="DayID" :items="dayCredit" item-text="DayDescription" item-value="DayID" v-model="orderLocal.DayID"></v-select>
                                </v-col>

                            </v-row>

                            <v-row>
                                <v-col cols="6" lg="4" class="s-col-form">
                                    <vc-text :outlined="false" id="OrdDeliveryDay" number :min="1" label="Tiempo de Entrega(días)" v-model="orderLocal.OrdDeliveryDay"></vc-text>
                                </v-col>

                                <v-col cols="6" lg="4" class="s-col-form">
                                    <vc-text :outlined="false" id="OrdWarrantyDay" number :min="0" label="Garantia(días)" v-model="orderLocal.OrdWarrantyDay"></vc-text>
                                </v-col>
                                <v-col cols="12" lg="4" class="s-col-form">
                                    <vc-select-definition :outlined="false" id="TypeCurrency" label="Moneda" :def="1020" v-model="orderLocal.TypeCurrency"></vc-select-definition>
                                </v-col>
                            </v-row>
                            <!-- </v-card> -->

                        </v-col>

                        <v-divider vertical></v-divider>
                        <v-col lg="12" cols="12">
                            <vc-header-title title="Items Requeridos" />
                            <v-row>
                                <v-col>
                                    <v-data-table disable-sort class="table-bordered" dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="CtdID" :height="'auto'" hide-default-footer>
                                        <template v-slot:item.TypeCotization="{ item }">
                                            <v-icon :color="item.TypeCotization == 1 ? 'red' : 'green'" style="font-size:12px;">
                                                {{item.TypeCotization == 1 ? 'fas fa-car': 'fas fa-street-view'}}

                                            </v-icon>
                                            <!-- <b style="margin-left:10px">{{item.TypeCotizationName == 1 ? 'Compra': 'Servicio'}}</b> -->
                                            <label class="ml-2">{{item.TypeCotizationName}}</label>
                                        </template>
                                        <template v-slot:item.OddQuantity="props">
                                            <vc-text @input="changeItem(props.item)" decimal v-model="props.item.OddQuantity"></vc-text>
                                        </template>

                                        <template v-slot:item.OddUnitPrice="props">

                                            <vc-text style="width: 125px" decimal @input="changeItem(props.item)" v-model="props.item.OddUnitPrice">
                                                Precio Unitario
                                            </vc-text>
                                        </template>

                                        <template v-slot:item.OddAmount="{ item }">
                                            <div>{{
                            item.OddAmount > 0
                              ? item.OddAmount.toFixed(2)
                              : '0.00'
                          }}</div>
                                        </template>

                                    </v-data-table>
                                </v-col>
                            </v-row>

                        </v-col>
                        <v-col cols="12">
                            <v-card elevation="0" height="auto">
                                <!-- <vc-header-title /> -->

                                <v-row style="margin: auto">
                                    <v-col cols="12" lg="8" md="8" class="s-col-form pb-3">
                                        <!-- <v-textarea auto-grow ref="OrdObservation" label="Observaciones Generales" v-model="orderLocal.OrdObservation" /> -->
                                        <quill-editor id="ReqObservation" v-model="orderLocal.OrdObservation" ref="myQuillEditor" :options="editorOption"></quill-editor>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" class="s-col-form mt-2">
                                        <div>
                                            <vc-select-definition id="tax" label="Seleccione Impuesto" :outlined="false" :def="1027" return-object v-model="tax" @input="changeTax($event)" />
                                        </div>

                                        <v-divider class="mt-4 mb-4"></v-divider>
                                        <v-row style="margin-top:-15px;margin-bottom:-17px">
                                            <v-col lg="6" class="s-col-form">
                                                <v-label><b>V. NETO</b></v-label>
                                            </v-col>
                                            <v-col lg="6" cols="6" class="s-col-form">
                                                <h5 class="text-right">
                                                    <h5 class="text-right">
                                                        {{
                            orderLocal.OrdTotalNet >= 1000
                              ? orderLocal.OrdTotalNet.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTotalNet > 0 ? orderLocal.OrdTotalNet.toFixed(2) : 0
                          }}
                                                    </h5>
                                                </h5>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mt-4 mb-4"></v-divider>
                                        <!-- <v-row>
                                            <v-col lg="6" class="s-col-form">
                                                <v-label><b>COSTOS DIRECTOS</b></v-label>
                                            </v-col>
                                            <v-col lg="6" cols="6" class="s-col-form">

                                                <h5 style="margin-top:-30px" class="text-right">
                                                    <vc-text decimal v-model="orderLocal.OrdDirectCost" @input="changeTotal()"></vc-text>
                                                </h5>
                                            </v-col>
                                        </v-row> -->
                                        <v-row v-if="orderLocal.CttCheckGTO">
                                            <v-col lg="6" class="s-col-form">
                                                <v-label><b>GTO GENERALES</b></v-label>
                                            </v-col>
                                            <v-col lg="6" class="s-col-form">

                                                <h5 style="margin-top:-20px" class="text-right">
                                                    <vc-text decimal v-model="orderLocal.OrdGeneralgto" @input="changeTotal()"></vc-text>
                                                </h5>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="orderLocal.CttCheckUtilities">
                                            <v-col lg="6" class="s-col-form">
                                                <v-label><b>UTILIDADES</b></v-label>
                                            </v-col>
                                            <v-col lg="6" class="s-col-form">

                                                <h5 style="margin-top:-20px" class="text-right">
                                                    <vc-text decimal v-model="orderLocal.OrdUtilities" @input="changeTotal()"></vc-text>
                                                </h5>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="orderLocal.CttCheckDiscount">
                                            <v-col lg="6" class="s-col-form">
                                                <v-label><b>DESCUENTO</b></v-label>
                                            </v-col>
                                            <v-col lg="6" class="s-col-form">
                                                <h5 style="margin-top:-20px" class="text-right">
                                                    <vc-text decimal v-model="orderLocal.OrdDiscount" @input="changeTotal()"></vc-text>
                                                </h5>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mt-4 mb-4"></v-divider>
                                        <v-row style="margin-top:-15px;margin-bottom:-17px">
                                            <v-col lg="6" class="s-col-form">
                                                <v-label><b>SUB TOTAL</b></v-label>
                                            </v-col>
                                            <v-col lg="6" class="s-col-form">
                                                <h5 class="text-right">
                                                    {{
                            orderLocal.OrdSubTotal >= 1000
                              ? orderLocal.OrdSubTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              :  orderLocal.OrdSubTotal > 0 ? orderLocal.OrdSubTotal.toFixed(2) : 0
                          }}
                                                </h5>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col class="s-col-form">
                                                <v-label><b>IMPUESTO{{ " (" + OrdTaxPercentage + "%)" }}</b></v-label>
                                            </v-col>
                                            <v-col class="s-col-form">
                                                <h5 class="text-right">

                                                    {{
                            orderLocal.OrdTaxAmount >= 1000
                              ? orderLocal.OrdTaxAmount.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTaxAmount > 0 ? orderLocal.OrdTaxAmount.toFixed(2): 0
                          }}
                                                </h5>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mt-4 mb-4"></v-divider>
                                        <v-row style="margin-top:-15px;">
                                            <v-col class="s-col-form">
                                                <v-label><b>TOTAL</b></v-label>
                                            </v-col>
                                            <v-col class="s-col-form">
                                                <h4 class="text-right">
                                                    {{
                            orderLocal.OrdTotal >= 1000
                              ? orderLocal.OrdTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTotal > 0 ? orderLocal.OrdTotal.toFixed(2) : 0

                          }}
                                                </h4>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card outlined width="100%" style="margin-top: 10px">
                                <v-card-title style="
                      margin-top: -10px;
                      margin-bottom: -10px;
                      font-family: Calibri;
                    ">
                                    <v-col cols="12">
                                        Favor de adjuntar Cotizacion. (Max. 100 Caracteres)
                                        <br />
                                        <b>(El nombre del archivo debe tener la siguiente
                                            estructura)</b>
                                        <b style="color: red">Ejem: NroCotización-Ruc-NombreArchivo</b>
                                        <br />
                                        <b>{{orderLocal.CttDocumentNumber+"-"+provider.SupDocumentNumber}}</b>
                                        <!-- {{
                        item.CttDocumentNumber +
                        "-" +
                        $fun.getUserName() +
                        "-Nombre archivo"
                      }} -->
                                        <v-file-input :rules="rules" show-size counter truncate-length="100" ref="myidcotizacion" :accept="'.pdf,.xlsx,.txt,.docx'" label="Adjuntar Cotizaciòn" v-model="Attach1" />
                                    </v-col>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </form>
            </v-col>
        </v-card>

        <vc-footer />
    </v-card>

</div>
</template>

<script>
//Services
import _sCotization from "@/services/Logistics/LgsCotizationService";

//Functions
import _validaERP from "@/plugins/validaERP";

//Components
import {
    quillEditor
} from "vue-quill-editor";

export default {
    components: {
        quillEditor

    },

    props: {
        value: {
            type: Number,
            default: 0
        },
        CtpID: {
            type: Number,
            default: 0
        },

        provider: {
            type: Object,
            default: {},
        },

        isExtern: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {

            orderLocal: {
                SupTelephone: ""
            },
            headersSAP: [{
                    text: "Tipo",
                    value: "TypeCotization",
                    width: 100,
                },

                {
                    text: "Código Art.",
                    value: "ArtCode",
                    width: 100,
                },
                {
                    text: "Descripción",
                    value: "CtdDescription",

                },
                {
                    text: "Cant",
                    value: "CtdQuantity",
                    width: 120,
                },

                {
                    text: "Und",
                    value: "CtdUnitMeasurement",
                    width: 100,
                },
                {
                    text: "Comentario",
                    value: "CtdObservation",
                    width: 240,
                },
                {
                    text: "Cant Stock.",
                    value: "OddQuantity",
                    width: 100,
                },
                {
                    text: "P. Uni. (Sin IGV)",
                    value: "OddUnitPrice",
                    width: 100,
                },
                {
                    text: "V. Neto",
                    value: "OddAmount",
                    width: 80,
                },
                /* {
                    text: "Comentario",
                    value: "EtdObservation",
                    width: 300,
                }, */
            ],
            itemsSAP: [],
            selectedSAP: [],
            typePayment: [],
            selectedPayment: null,
            paymentCondition: [],
            dayCredit: [],
            tax: null,
            OrdTaxSign: "",
            OrdTaxPercentage: "",
            editorOption: {
                // some quill options
            },

            Attach1: null,
            rules: [
                (value) =>
                !value ||
                value.size < 6000000 ||
                "El tamaño del archivo debe ser inferior a 6 MB!",
            ],
        };
    },

    created() {

        this.initialize();
        this.changeTotal();

    },

    mounted() {},

    watch: {
        selectedPayment() {
            this.paymentCondition = []
            this.dayCredit = []
            var pay = this.typePayment.filter(x => x.CppID == this.selectedPayment)[0]
            this.paymentCondition = pay.condition.filter(x => x.CppID == this.selectedPayment)
            this.dayCredit = pay.credit.filter(x => x.CppID == this.selectedPayment)
        }
    },

    methods: {

        initialize() {
            _sCotization
                .byID({
                    CttID: this.value,
                })
                .then((resp) => {
                    if (resp.status == 200) {
                        resp.data.Result.itemsDetails.forEach((element) => {
                            element.OddQuantity = element.CtdQuantity;
                        });

                        this.orderLocal = resp.data.Result;
                        this.orderLocal.SupConName = this.provider.SupConName
                        this.orderLocal.SupTelephone = this.provider.SupTelephone
                        this.orderLocal.SupEmail = this.provider.SupEmail

                        console.log("this.provider",this.provider);

                        this.itemsSAP = this.orderLocal.itemsDetails

                        this.typePayment = this.orderLocal.itemsPayment

                    }
                });
        },

        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },

        changeItem(item) {
            this.orderLocal.OrdSubTotal = 0;
            this.orderLocal.OrdTotalNet = 0;
            this.orderLocal.OrdTaxAmount = 0;
            this.orderLocal.OrdTotal = 0;

            let edit = Object.assign({},
                this.itemsSAP.find((x) => x.CtdID == item.CtdID)
            );
            let indexEdit = this.itemsSAP.indexOf(item);

            edit.OddAmount = edit.OddUnitPrice * edit.OddQuantity;
            console.log("edit.OddAmount", edit.OddAmount);

            this.itemsSAP.splice(indexEdit, 1, edit);

            this.itemsSAP.forEach((element) => {
                let unitPrice =
                    element.OddUnitPrice == null || element.OddUnitPrice == "" ?
                    0 :
                    element.OddUnitPrice;

                this.orderLocal.OrdTotalNet =
                    parseFloat(this.orderLocal.OrdTotalNet) +
                    parseFloat(unitPrice) *
                    (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));

                this.orderLocal.OrdSubTotal =
                    parseFloat(this.orderLocal.OrdTotalNet) +
                    ((parseFloat(this.orderLocal.OrdDirectCost || 0) +
                            parseFloat(this.orderLocal.OrdGeneralgto || 0) +
                            parseFloat(this.orderLocal.OrdUtilities || 0)) -
                        parseFloat(this.orderLocal.OrdDiscount || 0)
                    );
            });

            this.orderLocal.OrdTaxAmount =
                (parseFloat(this.orderLocal.OrdSubTotal) *
                    parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                100;

            this.orderLocal.OrdTotal =
                this.orderLocal.OrdSubTotal + this.orderLocal.OrdTaxAmount;
        },

        changeTax(item) {
            if (this.tax != null) {
                console.log("tax", this.tax);
                this.OrdTaxPercentage = this.tax.DedHelper
                this.OrdTaxSign = this.tax.DedAbbreviation;

                this.orderLocal.OrdTaxAmount =
                    (parseFloat(this.orderLocal.OrdSubTotal) *
                        parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                    100;

                this.changeTotal();

            }
        },

        changeTotal() {

            this.orderLocal.OrdTotalNet = 0;
            this.orderLocal.OrdSubTotal = 0;
            this.orderLocal.OrdTaxAmount = 0;
            this.orderLocal.OrdTotal = 0;

            if (this.itemsSAP !== undefined) {
                this.itemsSAP.forEach((element) => {
                    let unitPrice = element.OddUnitPrice == null ? 0 : parseFloat(element.OddUnitPrice);

                    this.orderLocal.OrdTotalNet =
                        parseFloat(this.orderLocal.OrdTotalNet) +
                        parseFloat(unitPrice) *
                        (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));

                    this.orderLocal.OrdSubTotal =
                        parseFloat(this.orderLocal.OrdTotalNet) +
                        ((parseFloat(this.orderLocal.OrdDirectCost || 0) +
                                parseFloat(this.orderLocal.OrdGeneralgto || 0) +
                                parseFloat(this.orderLocal.OrdUtilities || 0)) -
                            parseFloat(this.orderLocal.OrdDiscount || 0)
                        );

                    this.orderLocal.OrdTaxAmount =
                        (parseFloat(this.orderLocal.OrdSubTotal) *
                            parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                        100;
                });

            }
            this.orderLocal.OrdSubTotal = parseFloat(
                parseFloat(this.orderLocal.OrdSubTotal).toFixed(2)
            );
            this.orderLocal.OrdTaxAmount = parseFloat(
                parseFloat(this.orderLocal.OrdTaxAmount).toFixed(2)
            );

            let total = 0.0

            switch (this.OrdTaxSign) {
                case "+":
                    total = this.orderLocal.OrdSubTotal + this.orderLocal.OrdTaxAmount;
                    break;
                case "-":
                    total = this.orderLocal.OrdSubTotal - this.orderLocal.OrdTaxAmount;
                    break;
                    /* case "*":
                        total = this.orderLocal.OrdSubTotal * this.orderLocal.OrdTaxAmount;
                        break;
                    case "/":
                        total = this.orderLocal.OrdSubTotal / this.orderLocal.OrdTaxAmount;
                        break; */
                    //default: 
                    // total = this.orderLocal;
            }

            this.orderLocal.OrdTotal = total
        },

        save() {

            //console.log("getFileFormData", this.$fun.getFileFormData(array));

            //Validamos Campos
            var resp = _validaERP.valida({
                form: "#formsuppliercotization",
                items: {

                    ConID: {
                        required: this.paymentCondition.length > 0 ? true : false,
                        value: this.orderLocal.ConID,
                    },

                    DayID: {
                        required: this.dayCredit.length > 0 ? true : false,
                        value: this.orderLocal.DayID,
                    },

                    TypePayment: {
                        required: true,
                        value: this.selectedPayment,
                    },

                    TypePayment: {
                        required: true,
                        value: this.selectedPayment,
                    },
                    OrdDeliveryDay: {
                        required: true,
                        min: 1,
                        value: this.orderLocal.OrdDeliveryDay,
                    },
                    OrdWarrantyDay: {
                        required: true,
                        value: this.orderLocal.OrdWarrantyDay,
                    },
                    TypeCurrency: {
                        required: true,
                        value: this.orderLocal.TypeCurrency,
                    },

                    SupConName: {
                        required: true,
                        regular: true,
                        minlength: 3,
                        value: this.orderLocal.SupConName,
                    },

                    SupTelephone: {
                        required: true,
                        number: true,
                        minlength: 9,
                        maxlength: 9,
                        value: this.orderLocal.SupTelephone,
                    },

                    SupEmail: {
                        required: true,
                        email: true,
                        value: this.orderLocal.SupEmail,
                    },

                    CttExpirationOffer: {
                        required: true,
                        value: this.orderLocal.CttExpirationOffer,
                    },

                },
            });

            if (resp) {

                console.log(this.$moment(this.orderLocal.CttExpirationOffer).format(this.$const.FormatDateDB))
                console.log(this.$moment(this.$fun.getDate()).format(this.$const.FormatDateDB))


                if (this.$moment(this.orderLocal.CttExpirationOffer).format(this.$const.FormatDateDB) < this.$moment(this.$fun.getDate()).format(this.$const.FormatDateDB)) {
                    this.$fun.sweetAlert("Fecha de Oferta no puede ser menor a la fecha actual", "warning")
                    return
                }

                for (let i = 0; i < this.itemsSAP.length; i++) {

                    if (this.itemsSAP[i].OddQuantity > this.itemsSAP[i].CtdQuantity) {
                        this.$fun.alert(
                            "Cantidad inválido, No puede ser mayor a la solicitada, " +
                            this.itemsSAP[i].CtdDescription,
                            "warning"
                        );
                        this.selectedSAP = [this.itemsSAP[i]]
                        return;
                    }

                    if (
                        this.itemsSAP[i].OddQuantity > 0 &&
                        this.itemsSAP[i].OddUnitPrice == 0
                    ) {
                        this.$fun.alert(
                            "Precio inválido. " + this.itemsSAP[i].CtdDescription,
                            "warning"
                        );
                        this.selectedSAP = [this.itemsSAP[i]]

                        return;
                    }

                }

                this.orderLocal.OrdTaxPercentage = this.tax.DedHelper
                this.orderLocal.TypePercentage = this.tax.DedValue;
                this.orderLocal.OrdTaxSign = this.tax.DedAbbreviation;

                console.log(this.orderLocal.itemsParticipant);

                if (this.isExtern) {
                    var ctp = this.orderLocal.itemsParticipant.find(x => x.CtpRuc == this.$fun.getUserName());
                    if (ctp == undefined) {
                        this.$fun.sweetAlert("[Error], no se pudo capturar el ID del Participante.", "warning")
                        return
                    }

                    this.orderLocal.CtpID = ctp.CtpID
                } else {
                    this.orderLocal.CtpID = this.CtpID
                }


                if (this.Attach1 !== null) {
                    if (!this.Attach1.name.includes(this.provider.SupDocumentNumber)) {
                        this.$fun.sweetAlert("[RUC], Nombre de Adjunto no cumple con lo solicitado.", "warning");
                        return;
                    }

                    if (!this.Attach1.name.includes(this.orderLocal.CttDocumentNumber)) {
                        this.$fun.sweetAlert("[Nro. Cotización], Nombre de Adjunto no cumple con lo solicitado.", "warning");
                        return;
                    }
                }

                this.orderLocal.CtpRuc = this.provider.SupDocumentNumber
                this.orderLocal.CppID = this.selectedPayment
                this.orderLocal.details = this.itemsSAP
                this.orderLocal.SecStatus = 1;
                this.orderLocal.UsrCreateID = this.$fun.getUserID();

                var array = []
                array.push({
                    RqdID: this.orderLocal.CtpID,
                    RqdDescription: "Cotización",
                    RqdRequired: 1,
                    RqdAttach: this.Attach1,
                    RqdSize: 6000000,
                    RqdAccept: ".pdf,.xlsx,.txt,.docx",
                })

                console.log("save", this.orderLocal);

                if (this.orderLocal.OrdTotal > 0) {
                    this.$fun
                        .sweetAlert("¿Grabar Cotizacion?", "question")
                        .then((val) => {
                            if (val.value) {

                                this.$fun.uploadFiles(array, 3).then(file => {

                                    this.orderLocal.Attach = file.data[0].RqdAttach
                                    _sCotization
                                        .saveprovider(this.orderLocal)
                                        .then((resp) => {
                                            if (resp.status == 200) {
                                                this.$fun.alert(
                                                    "Datos guardados correctamente",
                                                    "success",
                                                    false,
                                                    "center"
                                                );
                                                this.$router.go(0);
                                            }
                                        });

                                })
                            }
                        });
                }
            }
        },

    },
};
</script>

<style lang="scss" scoped>
@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}

.bounce {
    animation: bounce 1s infinite;
}
</style>
