<template>
<div>

    <v-row class="mt-0 " justify="center">

        <v-col cols="12">
            <v-card elevation="0" style="margin-top: 2px;border-radius:10px;margin-bottom: 10px">
                <div class="card-body">

                    <h4 class="card-title" style="font-size: 16px;">PREGUNTAS FRECUENTES</h4>
                    <v-divider></v-divider>
                    <v-row>

                    </v-row>
                </div>
            </v-card>
            <v-card style="border-radius:10px" elevation="0" class="pb-3 mt-0">
                <v-row justify="center" style="margin:auto">
                    <v-col cols="12" lg="10" md="10">
                        <v-card style="border-radius:10px" elevation="0" class="pb-0 mt-0">
                            <v-row>
                                <v-col cols="12">

                                    <div class="mb-3 card mt-0 pr-4 pl-2" style="border-radius: 10px;">

                                        <!-- <div class="card-header-tab card-header" style="font-size:18px;align-self: center;font-weight: 700;text-transform: initial;">
                                            Preguntas Frecuentes
                                        </div> -->
                                        <div class="no-gutters row">

                                            <div class="col-sm-12 col-md-12 col-xl-12">
                                                <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                                                    <div class="widget-chart-content">
                                                        <div class="card-body">
                                                            <v-expansion-panels class="mt-1" focusable elevation="0" v-for="(item, index) in Object.keys(questionBy)" :key="index">
                                                                <v-expansion-panel class="pt-0 pb-0">
                                                                    <v-expansion-panel-header style="min-height: 0px; height: 0px">
                                                                        <!-- <v-icon left> mdi-checkbox-marked-circle </v-icon> -->
                                                                        <b>
                                                                            <v-icon left style="font-size:16px"> mdi-checkbox-marked-circle </v-icon>{{ item }}
                                                                        </b>
                                                                    </v-expansion-panel-header>

                                                                    <v-expansion-panel-content style="margin-top: 15px;">

                                                                        <v-expansion-panels class="mt-1" elevation="0" v-for="(d, index) in questionBy[item]" :key="index">
                                                                            <v-expansion-panel class="pt-0 pb-0">

                                                                                <v-expansion-panel-header style="">
                                                                                    <b>{{index+1}}. {{ d.QueName}}</b>
                                                                                </v-expansion-panel-header>

                                                                                <v-expansion-panel-content>
                                                                                    <v-col cols="12">
                                                                                        <p v-html="d.QueAnswer"></p>
                                                                                    </v-col>

                                                                                </v-expansion-panel-content>
                                                                            </v-expansion-panel>
                                                                        </v-expansion-panels>
                                                                    </v-expansion-panel-content>
                                                                </v-expansion-panel>
                                                            </v-expansion-panels>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="divider m-0 d-md-none d-sm-block"></div>
                                            </div>

                                        </div>

                                    </div>

                                </v-col>
                            </v-row>
                        </v-card>

                    </v-col>

                </v-row>
            </v-card>
        </v-col>

    </v-row>
</div>
</template>

<script>
import _sQuestion from "@/services/Collection/CobQuestionFrecuentService";

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon,

    },
    props: {

    },
    data() {

        return {
            question: [],
            questionBy: []
        };
    },

    mounted() {

    },

    created() {
        this.initialize();
    },

    methods: {
        initialize() {
            _sQuestion.getQuestion(this.$fun.getUserInfo().LexID).then(resp => {
                if (resp.status == 200) {
                    this.question = resp.data.Result
                    this.questionBy = _.groupBy(this.question, "TypeQuestionName");
                }
            })
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
