<template>
<div>

    <vc-loading :processing="processing" :errors="errors" :title="'Cargando datos.'" @close="processing=false;" />
    <v-row class="mt-4" justify="center">

        <v-col cols="12">
            <v-card style="border-radius:10px" elevation="0" class="pb-3 mt-3 opacity-9">
                <v-row justify="center" style="margin:auto">
                    <v-col cols="12" lg="6" md="8">
                        <v-stepper v-model="e1">
                            <v-stepper-header>
                                <v-stepper-step :complete="e1 > 1" step="1">Detalle de pago</v-stepper-step>
                                <v-divider></v-divider>
                                <!-- <v-stepper-step step="2">Pagar</v-stepper-step> -->
                            </v-stepper-header>

                            <v-stepper-items>
                                <v-stepper-content step="1">
                                    <v-card style="border-radius:10px" elevation="0" class="pb-0 mt-0">
                                        <v-row>
                                            <v-col cols="12">

                                                <div class="mb-3 card mt-0">

                                                    <div class="card-header-tab card-header" style="font-size:18px;align-self: center;font-weight: 700;text-transform: initial;">
                                                        Revisa los detalles de tu pago
                                                    </div>
                                                    <div class="no-gutters row">

                                                        <div class="col-sm-12 col-md-12 col-xl-12">
                                                            <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                                                                <div class="widget-chart-content">
                                                                    <div class="widget-subheading"></div>
                                                                    <v-row>
                                                                        <v-col cols="12" style="display: flex;">
                                                                            Código de venta<v-spacer class="mr-2"></v-spacer><b>{{itemOC.correlativo}}</b>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-divider></v-divider>
                                                                    <v-row>
                                                                        <v-col cols="12" style="display: flex;">
                                                                            Proyecto<v-spacer class="mr-2"></v-spacer><b>{{itemOC.proyecto}}</b>
                                                                        </v-col>
                                                                        
                                                                    </v-row>
                                                                    <v-divider></v-divider>
                                                                    <v-row>
                                                                        <v-col cols="12" style="display: flex;">
                                                                            Nro. Cuota
                                                                            <v-spacer class="mr-2"></v-spacer><b>{{itemPay.nrocuota}}</b>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-divider></v-divider>
                                                                    <v-row>
                                                                        <v-col cols="12" style="display: flex;">
                                                                            {{itemOC.tipoInmueble_1}}
                                                                            <v-spacer class="mr-2"></v-spacer><b>{{itemOC.nroInmueble_1}}</b>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-divider></v-divider>
                                                                    <v-row>
                                                                        <v-col cols="12" style="display: flex;">
                                                                            Vencimiento<v-spacer class="mr-2"></v-spacer><b>{{$fun.formatDateView(itemPay.fechavctocuota)}}</b>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-divider></v-divider>
                                                                    <v-row>
                                                                        <v-col cols="12" style="display: flex;">
                                                                            Monto<v-spacer class="mr-2"></v-spacer><b>{{itemPay.saldocuota.toLocaleString("es-MX", {
                                                                                                        minimumFractionDigits: 2,
                                                                                                        maximumFractionDigits: 2,
                                                                                                    })}} USD</b>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-divider></v-divider>
                                                                </div>

                                                            </div>
                                                            <div class="divider m-0 d-md-none d-sm-block"></div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </v-col>
                                        </v-row>
                                    </v-card>

                                    <v-card style="border-radius:10px" elevation="0" class="pb-0 mt-0">

                                        <v-col cols="12">

                                            <div class="mb-3 card mt-0">
                                                <div class="no-gutters row">

                                                    <div class="col-sm-12 col-md-12 col-xl-12">
                                                        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                                                            <div class="widget-chart-content">
                                                                <v-checkbox hide-details v-model="accept"></v-checkbox> Acepto las <a href="">Politicas de Privacidad y Términos y condiciones</a>
                                                            </div>
                                                            <div class="widget-chart-content" v-if="showAlert">
                                                                <label style="color:red">Para continuar debe aceptar las Politicas de Privacidad y Términos y condiciones</label>
                                                            </div>
                                                        </div>
                                                        <div class="divider m-0 d-md-none d-sm-block"></div>
                                                    </div>

                                                </div>

                                            </div>

                                        </v-col>
                                    </v-card>

                                    <v-card elevation="0" style="margin-top: 2px;border-radius:10px" dense flat class="body-2 font-weight-bold" :color="'#f5f5f5'">
                                        <div class="card-body">
                                            <v-spacer></v-spacer>
                                            <v-btn outlined elevation="0" class="capitalize" color="info" @click="save()">
                                                Realizar Pago
                                            </v-btn>
                                            <v-divider vertical></v-divider>
                                            <v-btn outlined elevation="0" class="capitalize ml-1" color="error" @click="$emit('close')">
                                                Cancelar
                                            </v-btn>
                                        </div>
                                    </v-card>

                                </v-stepper-content>

                                <v-stepper-content step="2">
                                    <v-btn text @click="e1 = 1" class="capitalize">Regresar</v-btn>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>

                    </v-col>
                </v-row>
            </v-card>
        </v-col>

    </v-row>
</div>
</template>

<script>
/* import {
    openForm
} from '@/plugins/niubiz.js'; */

import _sPayGateway from '@/services/PaymentGateway/PagPayGatewayService.js'
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon
    },
    props: {
        itemPay: {
            type: Object,
            default: {}
        },
        itemOC: {
            type: Object,
            default: {}
        },
        customer: {
            type: Object,
            default: {}
        }
    },
    data() {

        return {

            tabSelected: "",
            currentItem: "tab-",
            items: [""],
            accept: false,
            showAlert: false,

            e1: 1,

            //Loading
            processing: false,
            errors: null,

            item: {
                sessiontoken: "",
                merchantid: "",
                purchasenumber: "",
                action: "",
                amount: 0,
                customer: {
                    nombres: "",
                    apellidosPaternos: "",
                    correoElectronico: ""
                }
            },
            domain: window.location.host,
        };
    },

    mounted() {},

    created() {

    },

    methods: {

        initialize() {
            this.processing = true
            _sPayGateway.initialize({
                document: this.$fun.getUserName(),
                correlativo: this.itemOC.correlativo,
                amount: this.itemPay.saldocuota,
                correoElectronico:this.customer.correoElectronico,
                userid: this.$fun.getUserID()
            }).then(resp => {
                    this.item = {
                        sessiontoken: resp.data.Result.sessiontoken,
                        merchantid: resp.data.Result.merchantid,
                        purchasenumber: resp.data.Result.purchasenumber,
                        action: resp.data.Result.action,
                        amount: resp.data.Result.amount,
                        customer: {
                            nombres: this.customer.nombres,
                            apellidosPaternos: this.customer.apellidosPaternos + " " + this.customer.apellidosMaterno,
                            correoElectronico: this.customer.correoElectronico,
                        }
                        
                    }

                    console.log("this.item",this.item);
                    this.openForm()
                    this.processing = false
                },
                (e) => {
                    this.errors = e.response;
                    this.processing = true;
                })
        },
        openForm() {
            var config = {
                sessiontoken: this.item.sessiontoken,
                channel: 'web',
                merchantid: this.item.merchantid,
                purchasenumber: this.item.purchasenumber,
                amount: this.item.amount,
                expirationminutes: '5',
                timeouturl: 'https://' + this.domain,
                merchantlogo: 'https://imperu.com.pe/Logos/LogoImpAzul.png',
                formbuttoncolor: '#000000',
                //hidexbutton: 'true',
                cardholdername: this.item.customer.nombres,
                cardholderlastname: this.item.customer.apellidosPaternos,
                cardholderemail: this.item.customer.correoElectronico,
                usertoken: this.item.customer.correoElectronico,
                action: this.item.action + this.item.amount + "/" + this.item.purchasenumber + "/" + this.itemPay.idCuotaCobrar + "/" + this.itemPay.idCronogramaCliente + "/"+ this.itemPay.nrocuota + "/" + this.$fun.getUserID(),
            };

            VisanetCheckout.configure(config);
            VisanetCheckout.open();
        },

        save() {

            if (!this.accept) {
                this.showAlert = true
                return
            }

            this.initialize()
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
