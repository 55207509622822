<template>
<div>

    <vc-loading :processing="processing" :errors="errors" :title="'Cargando datos.'" @close="processing=false;" />
    <vc-toolbar :title="'Detalle de Pagos'" close @close="$emit('close')" />
    <v-row style="margin:auto">

        <!--  <v-col cols="12"> -->

        <v-card style="border-radius:7px" elevation="0" class="pb-0 mt-0" v-for="(pay, index) in items" :key="index">
            <!--  <v-row> -->
            <v-col cols="12">

                <div class="mb-3 card mt-0">
                    <div class="no-gutters row">

                        <div class="col-sm-12 col-md-12 col-xl-12">
                            <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                                <div class="widget-chart-content">
                                    <div class="widget-subheading">USD</div>

                                    <div class="widget-numbers" style="font-size:20px">
                                        <span>{{pay.montoPago.toLocaleString("es-MX", {
                                                                                                        minimumFractionDigits: 2,
                                                                                                        maximumFractionDigits: 2,
                                                                                                    })}}</span>
                                    </div>
                                    <div class="widget-description" style="color:#1f4d9d">
                                        F.P: {{$fun.formatDateView(pay.fechaPago)}}
                                    </div>
                                    {{pay.concepto}}
                                </div>

                                <!--  <v-chip x-small class="mr-3" :color="pay.estadocuotacolor">{{pay.estadocuota}}</v-chip>

                                        <v-btn elevation="0" v-if="pay.estadopagocuota == 'Pendiente'" small color="#16a94b" dark class="capitalize mr-2"> <i class="fas fa-magnifying-glass-dollar text-white mr-1" style="font-size:20px"></i> Pagar</v-btn> -->
                            </div>
                            <div class="divider m-0 d-md-none d-sm-block"></div>
                        </div>

                    </div>

                </div>

            </v-col>
            <!-- </v-row> -->
        </v-card>
        <!-- </v-col> -->

    </v-row>
</div>
</template>

<script>
import _sPayGateway from '@/services/PaymentGateway/PagPayGatewayService.js'

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon,

    },
    props: {
        item: {
            type: Object,
            default: {}
        }
    },
    data() {

        return {
            items: [],
            //Loading
            processing: false,
            errors: null,

        };
    },

    mounted() {
        this.getPayment()
    },

    created() {
        
    },

    methods: {

        getPayment() {
            if (this.item !== null) {
                this.processing = true
                _sPayGateway.getPayment(this.item.idCuotaCobrar).then(resp => {
                        if (resp.status == 200) {
                            this.processing = false
                            this.items = resp.data.Result

                        }
                    },
                    (e) => {
                        this.errors = e.response
                        this.processing = true;
                    })
            }

        },
    },
};
</script>

<style lang="scss" scoped>

</style>
