import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);
import es from "vuetify/lib/locale/es";
import "@mdi/font/css/materialdesignicons.min.css";
import 'material-design-icons-iconfont/dist/material-design-icons.css'


export default new Vuetify({
    lang: {
        locales: { es },
        current: "es",
    },
    theme: {
        primary: '#3f6ad8',
        secondary: '#444054',
        accent: '#794c8a',
        error: '#d92550',
        info: '#78C3FB',
        success: '#3ac47d',
        warning: '#f7b924',
        options: {
            customProperties: true,
            iconfont: "fa",
        },
    },
    options: {
        customProperties: true
    },
    icons: {
        iconFont: "md",
    },
    iconfont: 'md' || 'mdi',
});