<template>
	<div style="padding-top: -2px; margin-top: -2px">
		<vc-select-generic
			ref="SelectConcepServiceAccount"
			autocomplete
			class="text"
			itemtext="ConName"
			itemvalue="ConID"
			:disabled="disabled"
			id="ConID"
			:value="value"
			:return-object="returnObject"
			@input="input($event)"
			:config="config"
			:label="label"
			:full="full"
			:autofocus="autofocus"
			:text="text"
			:clearable="clearable"
			:noDefault="noDefault"
			:outline="outline"
		></vc-select-generic>
	</div>
</template>

<script>
	import _sConceptServiceAccount from "@/services/General/GenConceptServiceAccountService.js"

	export default {
		name: "SelectConcepServiceAccount",
		props : {
			autofocus: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			clearable: {
				type: Boolean,
				default: false,
			},
			value: null,
			text: {
				type: String,
				default: ""
			},
			label: {
				type: String,
				default: "",
			},
			returnObject: {
				type: Boolean,
				default: false,
			},
			noDefault: {
				type: Boolean,
				default: false,
			},
			full: {
				type: Boolean,
				default: false,
			},

			/* outlined: {
				type: Boolean,
				default: true
			}, */

			ConID :{
				type : Number,
				default : 0
			}
		},
		data() {
			return {
				item: 0,
				val: "",
				items: [],
				config: {},
			}
		},
		watch: {
			value(value) {
				this.item = value;
			},
			ConID() {
            this.config.params.ConID = isNaN(this.ConID) ? 0 : this.ConID
        },
		},

		methods: {
			focus() {
				this.$refs.sarea.focus();
			},
			input(val) {
				this.$emit("input", val);
			},
		},

		created() {
			
			this.config = {
				url: _sConceptServiceAccount.getUrlSearchConceptServiceAccount(),
				title: this.label,
				params: {
					ConID : this.ConID
					/* search: '' */
				},
			};
		},
	}
</script>