let children = [];

children.push({
    path: "/cobranzas/anuncios",
    name: "COB_ADVERTISEMENT",
    component: () =>
        import ("../views/Collection/CobAdvertisement.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Anuncios", href: "/cobranzas/anuncios" },
        ],
    },
});
children.push({
    path: "/cobranzas/preguntasfrecuentas",
    name: "COB_QUESTION_FRECUENT",
    component: () =>
        import ("../views/Collection/CobQuestionFrecuent.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Preguntas frecuentes", href: "/cobranzas/preguntasfrecuentas" },
        ],
    },
});

children.push({
    path: "/finish/payment/",
    name: "COB_FINISH_PAYMENT",
    component: () =>
        import ("../views/PaymentGateway/CheckoutAuth.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Pago Finalizado", disabled: true },
        ],
    },
});

children.push({
    path: "/payment/schedule/",
    name: "COB_SCHEDULE",
    component: () =>
        import ("../views/PaymentGateway/PagSchedule.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cronograma", disabled: true },
        ],
    },
});

children.push({
    path: "/cobranzas/serviciosprogramados",
    name: "COB_SCHEDULED_SERVICE",
    component: () =>
        import ("../views/Collection/CobScheduledMessaging.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Servicios programados", href: "/cobranzas/serviciosprogramados" },
        ],
    },
});



export { children };