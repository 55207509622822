<template>
<div>
    <vc-text :label="label" :disabled="disabled" :readonly="readonly" ref="textPerson" v-model="prs.PrsDocumentNumber" :autofocus="autofocus" type="tel" @keyupEnter="toggleSaveData(prs.PrsDocumentNumber)" @keypress="validation($event)">
        <template v-slot:append>
            <v-icon style="cursor:pointer;margin-top:4px;" @click="dialogSearchPerson = true">
                mdi-magnify
            </v-icon>
        </template>
        <template v-if="!noEdit" v-slot:append-outer>
            <v-icon style="cursor:pointer;margin-top:4px;" @click="addOrEdit()" color="success darken-1">
                {{ prs.PrsID != null && prs.PrsID != 0 ? "mdi-pencil" : "mdi-plus-circle-outline" }}
            </v-icon>
        </template>
    </vc-text>

    <!--MODAL PARA BUSCAR PERSONAS-->
    <v-dialog v-if="dialogSearchPerson" v-model="dialogSearchPerson" persistent max-width="850" transition="dialog-bottom-transition">
        <v-card rounded="">


            <!-- <v-col lg="12"> -->
                <vc-search-person @returnPerson="returnPerson($event)" @close="dialogSearchPerson = false" />
                <!-- <s-search-person :typeperson="typeperson" @returnPerson="returnPerson($event)" /> -->
            <!-- </v-col> -->
        </v-card>
    </v-dialog>
    <!--MODAL AGREGAR O EDITAR PERSONA-->
    <v-dialog v-if="dialogEditPerson" v-model="dialogEditPerson" persistent max-width="850px" transition="dialog-bottom-transition">
        <vc-add-person ref="textAddPerson" @personSaved="personSaved($event)" @toclose="toclose(2)" :predetermined="predetermined" :typeperson="typeperson" :propPerson="personEdit" />
    </v-dialog>

</div>
</template>

<script>
import vcSearchPerson from './Person/vcSearchPerson.vue';
import vcAddPerson from './Person/vcAddPerson.vue';
import Service from "@/services/General/GenPersonService";

export default {
    name: "sToolbarPerson",
    components: {
        vcSearchPerson,
        vcAddPerson

    },
    props: {

        defaultDocument: {
            type: String,
            default: "",
        },
        searchDefault: {
            type: Boolean,
            default: false,
        },
        value: null,
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
            default: "Documento",
        },
        typeperson: {
            type: Number,
            default: 1,
        },
        isValidation: {
            type: Number,
            default: 0,
        },
        predetermined: {
            type: Boolean,
            default: false,
        },
        noEdit: {
            type: Boolean,
            default: false,
        },
        add: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        /*  if (this.value != null) {
             this.prs.PrsDocumentNumber = this.value;
             if (this.searchDefault) this.toggleSaveData(this.prs.PrsDocumentNumber);
         } */
    },
    watch: {
        value(val) {
            this.prs.PrsID = 0;
            this.prs.PrsDocumentNumber = val;
        },
        "prs.PrsDocumentNumber"() {
            this.$emit("input", this.prs.PrsDocumentNumber);
        },
    },
    methods: {

        validation(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        personSaved(item) {
            this.prs.PrsDocumentNumber = item.GenPerson.PrsDocumentNumber;
            this.returnPerson(this.prs);
        },
        focus() {
            this.$refs.textPerson.focus();
        },
        addOrEdit() {

            if (this.prs.PrsID == null || this.prs.PrsID == 0) {
                this.$emit("returnPerson", null);
                this.dialogEditPerson = true;

            } else {
                this.personEdit = this.prs;
                this.dialogEditPerson = true;
                this.$nextTick(() => {
                    this.$refs.textAddPerson.search();
                });
            }
        },
        returnPerson(item) {
            if (item != null)
                this.toggleSaveData(item.PrsDocumentNumber);
            this.dialogSearchPerson = false;
        },

        toggleSaveData(value) {
            this.person = null;
            if (value == null) return;
            if (value.length > 3) {
                let obj = {
                    TypePerson: this.typeperson,
                    PrsDocumentNumber: value,
                    UsrID: this.$fun.getUserID(),
                    CpyID: localStorage.getItem('CpyID')
                };

                Service.personSearch(obj, this.$fun.getUserID()).then((response) => {

                    console.log("personSearchpersonSearchpersonSea", response);
                    if (response.status == 200) {
                        this.prs = Object.assign(response.data.Result, {});
                        this.$emit("returnPerson", this.prs);
                    }
                });
            }
        },
        toclose(typeperson) {
            this.dialogEditPerson = false;
            this.personEdit = null;
        },
    },
    data: () => ({
        dialogSearchPerson: false,
        personEdit: null,
        dialogEditPerson: false,
        dialog3: false,
        foundvalue: false,
        color: "success",
        prs: {
            PrsDocumentNumber: ""
        },
        config: {
            model: {
                PrsID: "ID",
                PrsDocumentNumber: "string",
                TypePersonDocument: "int",
            },
        },
    }),
};
</script>
