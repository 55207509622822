<template>
  <div class="app-footer">
    <div class="app-footer__inner">
       
      <!-- <div class="app-footer-left">
        <FooterDots />
      </div> -->
      <div class="app-footer-right">
        <!-- <FooterMenu /> -->
         Copyright &copy; <a class="ml-1 mr-1" href="https://imperu.com.pe">Inmobiliaria Miraflores Perú </a> 2023
      </div>
    </div>
  </div>
</template>

<script>
import FooterDots from "./Footer/FooterDots";
import FooterMenu from "./Footer/FooterMenu";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

library.add(faEllipsisV);
export default {
  name: "Footer",
  components: {
    FooterDots,
    FooterMenu
  }
};
</script>


<!-- <template>
    <div class="app-footer" style="height:20px;">
        <div class="app-footer__inner text-center" style="height:20px;">
            Copyright 2023 NCR Business
        </div>
    </div>
</template>

<script>

    import {library} from '@fortawesome/fontawesome-svg-core'
    import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

    library.add(
        faEllipsisV,
    );
    export default {
        name: "Footer",
        components: {
        }
    };
</script>
 -->