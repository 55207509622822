<template>
<div>

    <v-row class="mt-0" justify="center">

        <v-card elevation="0" style="margin-top: 8px;border-radius:10px;margin-bottom: 10px">
            <div class="card-body">

                <h4 class="card-title" style="font-size: 16px;">DATOS PERSONALES</h4>
                <v-divider></v-divider>
                <v-row>

                </v-row>
            </div>
        </v-card>
        <v-card style="border-radius:10px" elevation="0" class="pb-3 mt-0">
            <v-row justify="center" style="margin:auto">
                <v-col cols="12" lg="8" md="8">
                    <gen-supplier-portal />
                </v-col>

            </v-row>
        </v-card>
    </v-row>
</div>
</template>

<script>
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
import GenSupplierPortal from '@/views/General/GenSupplier/GenSupplierPortal.vue';

export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon,
        GenSupplierPortal,

    },
    props: {
        item: null
    },
    data() {

        return {

        };
    },

    mounted() {

    },

    created() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>

</style>
