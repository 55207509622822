<template>
<!-- <v-app class="scroll"> -->
<div id="app" class="app scroll portal-login">
    <router-view></router-view>
    <vc-loading :processing="processing" :errors="errors" @close="processing=false;" />

    <v-dialog v-if="showLoginModal" v-model="showLoginModal" max-width="400" persistent style="z-index:9999">
        <v-card>
            <vc-header title="Inicio de Sesion" style="box-shadow: -2px -15px 15px 11px"></vc-header>

            <v-row class="mb-2 pl-1 pr-1 pt-1" style="margin:auto;margin-top:0px">
                <v-card outlined elevation="0" class="pb-4">
                    <div class="">
                        <form id="expired" style="!important;">
                            <div class="mb-3">
                                <vc-text label="Usuario" id="UsrName" placeholder="Usuario" v-model="UsrName" ref="UsrName" class="px-0">
                                    <template v-slot:prepend-inner>
                                        <v-icon class="py-1">mdi mdi-account</v-icon>
                                    </template>
                                </vc-text>
                            </div>
                            <div class="mb-3">
                                <vc-text label="Password" id="UsrPassword" v-model="UsrPassword" :type="visiblePassword ? 'text' : 'password'" ref="UsrPassword" placeholder="Password" class="px-0 mt-2" @keyupEnter="validateUser()" @click:append="visiblePassword = !visiblePassword">
                                    <template v-slot:prepend-inner>
                                        <v-icon class="py-1">mdi mdi-lock</v-icon>
                                    </template>
                                    <template v-slot:append>
                                        <v-icon class="py-1" @click="visiblePassword = !visiblePassword">{{ visiblePassword ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
                                    </template>
                                </vc-text>
                            </div>

                        </form>

                        <div class="text-center">
                            <bc-button class="mt-4" variant="gradient" color="success" fullWidth size="lg" @click="validateUser()">Ingresar</bc-button>
                        </div>
                    </div>
                </v-card>
            </v-row>

            <vc-footer style="box-shadow: -2px 15px 15px 11px">
                <template v-slot:options>
                    <v-btn elevation="0" class="capitalize" text color="error" @click="redirectToLogin">
                        Logout
                    </v-btn>
                </template>
            </vc-footer>
        </v-card>
    </v-dialog>

    <!-- <v-dialog v-if="showTimeSession" v-model="showTimeSession" max-width="400" persistent>
        <vc-modal-time-session @logout="redirectToLogin" @onLine="onLine" @onSesion="onSesion" />
    </v-dialog> -->

</div>
<!-- </v-app> -->
</template>

<script>
import _validaERP from "@/plugins/validaERP";
import _sAuth from "@/services/AuthService";

export default {
    components: {},
    name: "App",
    data() {
        return {
            showLoginModal: false,
            showTimeSession: false,
            UsrName: "",
            UsrPassword: "",
            validatingUser: false,
            visiblePassword: false,

            processing: false,
            errors: null,
            timerId: null,
            usrExtern: null,
        }
    },

    created() {

        this.usrExtern = this.usrExtern || JSON.parse(localStorage.getItem("UsrExtern"))

        console.log("this.usrExtern",this.usrExtern)

        // Escucha el evento de sesión expirada desde el event bus
        this.$eventBus.$on('sessionExpired', () => {
            console.log("estoy recibiendo evento bus");
            this.showLoginModal = true
        });

        /* this.showTimeSession = JSON.parse(localStorage.getItem('showModal')) || false;
        this.showLoginModal = JSON.parse(localStorage.getItem('showLoginModalSesion')) || false; */

    },
    computed: {},

    watch: {},
    methods: {

        validateUser() {

            console.log(this.$fun.getUserID());

            //let usrExtern = JSON.parse(localStorage.getItem("UsrExtern"))

            //Validamos Campos
            var resp = _validaERP.valida({
                form: "#expired",
                items: {
                    UsrName: {
                        required: true,
                        value: this.UsrName,
                    },
                    UsrPassword: {
                        required: true,
                        value: this.UsrPassword,
                    },
                },
            });

            if (resp) {
                if (this.$fun.getUserID() == null || this.$fun.getUserID() == undefined) {
                    this.$fun.alert("[Error al autenticar]", "warning")
                    if (this.usrExtern == null) {
                        this.$router.push("/");
                    } else {
                        if (this.usrExtern) {
                            this.$router.push("/login");
                        } else {
                            this.$router.push("/micuenta");
                        }
                    }
                    return
                }

                this.validatingUser = true;
                if (
                    this.UsrName.length > 0 &&
                    this.UsrPassword.length > 0
                ) {

                    this.processing = true
                    _sAuth
                        .createToken({
                            UsrID: this.$fun.getUserID(),
                            UsrName: this.UsrName,
                            UsrPassword: this.UsrPassword
                        })
                        .then((response) => {
                            if (response.status == 200) {
                                this.processing = false;

                                //localStorage.removeItem("token")
                                localStorage.setItem("token", response.data.Result.Data.Token);
                                localStorage.setItem('showLoginModalSesion', false)

                                this.showLoginModal = false

                            }
                        })
                        .catch((e) => {
                            this.validatingUser = false;
                            this.errors = e.response
                            this.processing = false;
                        });
                } else this.validatingUser = false;
            }
        },

        redirectToLogin() {

            //var usrExtern = JSON.parse(localStorage.getItem("UsrExtern"))
            localStorage.removeItem("token");
            localStorage.clear();

            if (this.usrExtern == null) {
                this.$router.push("/");
            } else {
                if (this.usrExtern) {
                    this.$router.push("/login");
                } else {
                    this.$router.push("/micuenta");
                }
            }
        },

        
        /* initActivityListener() {
            let timeout;
            const inactivityTime = 900000; // 900000 10 minutos (tiempo de inactividad en milisegundos)

            const resetTimer = () => {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    this.handleInactive();
                }, inactivityTime);
            };

            document.addEventListener('mousemove', resetTimer);
            document.addEventListener('keydown', resetTimer);
            document.addEventListener('click', resetTimer);
        },
        handleInactive() {

            const token = localStorage.getItem("token");
            if (!this.showLoginModal && !this.showTimeSession && token !== null) {
                localStorage.setItem("showModal", true)
                this.showTimeSession = JSON.parse(localStorage.getItem('showModal'));
            }
        }, */

        /* onLine() {

            this.showTimeSession = false
            localStorage.setItem("showModal", false)
            localStorage.setItem('showLoginModalSesion', false)

            if (usrExtern) {
                this.$router.go(0)
            } else {
                this.showLoginModal = false
            }

        },
        onSesion() {
            this.showTimeSession = false;
            localStorage.setItem('showLoginModalSesion', true)
            this.showLoginModal = JSON.parse(localStorage.getItem('showLoginModalSesion'));
        } */

    },

    mounted() {
        /* this.initActivityListener(); */
    },
}
</script>

<style lang="scss">
@import "assets/base.scss";
@import "./styles/app.css";

.portal-login {
    //background: url("./assets/images/fondo.png") no-repeat fixed center bottom;
    background-size: 100%;
    height: 100vh;
}
</style>

<!-- SOLO PORNER CUANDO SE PUBLIQUE LA LANDING MARATON
@import "assets/styleCard.css";
@import "assets/marathon/css/styleMarathon.css";
@import "assets/marathon/css/bootstrap.css";
@import "assets/marathon/css/fonts.css";
@import "assets/marathon/css/style.css"; -->
