<template>
	<div style="padding-top: -2px; margin-top: -2px">
		<vc-select-generic
			ref="sdepartment"
			autocomplete
			class="text"
			itemtext="DptName"
			itemvalue="DptID"
			:disabled="disabled"
			id="DptName"
			:value="value"
			:return-object="returnObject"
			@input="input($event)"
			:config="config"
			:label="label"
			:full="full"
			:autofocus="autofocus"
			:text="text"
			:clearable="clearable"
			:noDefault="noDefault"
			:outlined="outlined"
		></vc-select-generic>
	</div>
</template>

<script>
	import _sDepartment from "@/services/General/GenDepartmentService.js"

	export default {
		name: "SelectDepartment",
		props : {
			autofocus: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			clearable: {
				type: Boolean,
				default: false,
			},
			value: null,
			text: {
				type: String,
				default: ""
			},
			label: {
				type: String,
				default: "",
			},
			returnObject: {
				type: Boolean,
				default: false,
			},
			noDefault: {
				type: Boolean,
				default: false,
			},
			full: {
				type: Boolean,
				default: false,
			},

			outlined: {
				type: Boolean,
				default: true
			},
			CceLevel : {
				type : Number,
				default : 100
			}
		},
		data() {
			return {
				item: 0,
				val: "",
				items: [],
				config: {},
			}
		},
		watch: {
			value(value) {
				this.item = value;
			},
		},

		methods: {
			focus() {
				this.$refs.sdepartment.focus();
			},
			input(val) {
				this.$emit("input", val);
			},
		},

		created() {
			
			this.config = {
				url: _sDepartment.getUrlSearchDepartment(),
				title: this.label,
				params: {
					 
				},
			};
		},
	}
</script>