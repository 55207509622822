<template>
<div style="padding-top: -2px; margin-top: -2px">
    <vc-select-generic ref="" autocomplete class="text" itemtext="NtpFullName" itemvalue="WrkID" :disabled="disabled" id="WrkID" :value="value" :return-object="returnObject" @input="input($event)" :config="config" :label="label" :full="full" :autofocus="autofocus" :text="text" :clearable="clearable" :noDefault="noDefault" :outlined="outlined">
    </vc-select-generic>
</div>
</template>

<script>
import _sWorkerService from "@/services/General/GenWorkerService";
export default {
    name: "",
    components: {},
    props: {
        autofocus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        value: null,
        text: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "",
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        noDefault: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },

        outlined: {
            type: Boolean,
            default: true
        },
    },
    watch: {
        value(value) {
            this.item = value;
        },
    },
    methods: {
        focus() {

        },
        input(val) {
            this.$emit("input", val);
        },
    },
    data: () => ({
        item: 0,
        val: "",
        items: [],
        config: {},
    }),

    created() {
        this.config = {
            url: _sWorkerService.getUrlSearchWorker(),
            title: this.label,
            params: {},
        };
    },
};
</script>
