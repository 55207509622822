let children = [];


children.push({
    path: "/marketing/campanias",
    name: "MKT_CAMPAIGN",
    component: () =>
        import ("../views/Marketing/MktCampaign.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Marketing", disabled: true },
            { text: "Campañas", href: "/marketing/campanias" },
        ],
    },
});

children.push({
    path: "/marketing/configurarcampanias",
    name: "MKT_CAMP_PERIOD",
    component: () =>
        import ("../views/Marketing/MktCampaignPeriods.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Marketing", disabled: true },
            { text: "Campañas", href: "/marketing/configurarcampanias" },
        ],
    },
});

children.push({
    path: "/marketing/cargamasiva",
    name: "MKT_CAMPAIGN_MASIVE",
    component: () =>
        import ("../views/Marketing/MktCampaignMasive.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Marketing", disabled: true },
            { text: "Carga Masiva", href: "/marketing/cargamasiva" },
        ],
    },
});





export { children };