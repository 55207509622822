let children = [];

children.push({
    path: "/querymanager/configuration",
    name: "QRY_CONFIGURATION",
    component: () =>
        import ("../views/QueryManager/QryConfiguration.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Query Manager", disabled: true },
            { text: "Configuración", href: "/querymanager/configuration" },
        ],
    },
});

children.push({
    path: "/querymanager/transactsql",
    name: "QRY_TRNSACTSQL",
    component: () =>
        import ("../views/QueryManager/QryTransactSql.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Query Manager", disabled: true },
            { text: "Transact SQL", href: "/querymanager/transactsql" },
        ],
    },
});

children.push({
    path: "/querymanager/show",
    name: "QRY_QUERYSHOW",
    component: () =>
        import ("../views/QueryManager/QryShow.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Query Manager", disabled: true },
            { text: "Show", href: "/querymanager/show" },
        ],
    },
});


children.push({
    path: "/querymanager/permisiousuarioreporte",
    name: "QRY_USER_REPORT_PERMITION",
    component: () =>
        import ("../views/QueryManager/QryConfigurationUserPermition/QryConfigurationUserPermition.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Query Manager", disabled: true },
            { text: "Permisos Usuario Reporte", href: "/querymanager/permisiousuarioreporte" },
        ],
    },
});



export { children };