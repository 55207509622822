import Service from "../Service";

const resource = "GenDefinition/";

export default {
    save(def, requestID) {
        return Service.post(resource + "save", def, {
            params: {},
        });
    },
    update(def, requestID) {
        return Service.put(resource + "update", def, {
            params: {},
        });
    },
    delete(def, requestID) {
        return Service.delete(resource + "delete", {
            data: def
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {},
        });
    },

    list(parameters, requestID) {
        return Service.post(
            resource + "list", parameters, {
                params: {},
            }
        );
    },
    definitiongroupURL() {
        return resource + "definitiongroup";
    },

    definitiongroup(defID, dgrID, requestID) {
        return Service.post(
            this.definitiongroupURL(), {}, {
                params: { defID: defID, dgrID: dgrID },
            }
        );
    },
};