<template>
<div>
    <v-label>
		<b>{{label}}</b>
	</v-label>
    <vc-select-generic 
		style="margin-top:-18px" 
		autocomplete 
		class="text" 
		itemtext="PrjName" 
		itemvalue="PrjID" 
		id="PrjName" 
		:value="value" 
		@input="input($event)" 
		:config="config" 
		:full="full" 
		:text="text" 
		:clearable=clearable 
		:noDefault=noDefault
	>
    </vc-select-generic>
</div>
</template>

<script>
import _sProject from "@/services/General/GenProjectService";
export default {
    name: "SelectProject",
    components: {},
    props: {
        clearable: {
            type: Boolean,
            default: false,
        },
        value: null,
        text: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "",
        },
        noDefault: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value(value) {
            this.item = value;
        },
    },
    methods: {
        input(val) {
            this.$emit("input", val);
        },

    },
    data: () => ({
        item: 0,
        val: '',
        items: [],
        config: {},
    }),

    created() {
		this.config = {
            url: _sProject.getUrlSearchProject(),
            title: this.label,
            params: {
                /* search: '', */
                /* CpyID: localStorage.getItem('CpyID') */
                /* 
                requestID: this.$fun.getUserID() */
            }
        }

    }

}
</script>
