import { render, staticRenderFns } from "./GenSupplierBank.vue?vue&type=template&id=035ba055&scoped=true&"
import script from "./GenSupplierBank.vue?vue&type=script&lang=js&"
export * from "./GenSupplierBank.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "035ba055",
  null
  
)

export default component.exports