<template>
<div>
    <v-row>
        <v-col>
            <vc-crud @rowSelected="rowSelected($event)" no-full searchInput close @close="$emit('close')" @doubleClick="dobleClick" title="Proveedores" :config="config">

            </vc-crud>
        </v-col>
    </v-row>

</div>
</template>

<script>
import _sSupplier from '@/services/General/GenSupplierService'
export default {
    name: "",
    components: {},
    props: {},

    data: () => ({
        config: {
            model: {
                SupID: "ID",
            },
            service: _sSupplier,
            headers: [{
                    text: "Ruc",
                    value: "SupDocumentNumber",
                    sortable: false
                },
                {
                    text: "Proveedor",
                    value: "SupName",
                    sortable: false
                },
                {
                    text: "Nombre de Contacto",
                    value: "SupConName",
                    sortable: false
                },
                {
                    text: "Telefono",
                    value: "SupTelephone",
                    sortable: false
                },
                {
                    text: "Correo Electronico",
                    value: "SupEmail",
                    sortable: false
                },
            ],
        },

    }),
    methods: {
        rowSelected(item) {
            console.log(item);
        },
        dobleClick(item) {
            this.$emit('dblClick', item)
        }
    },
    created() {},
};
</script>
