import Service from "./Service";
const menu = "Account/";

export default {
    login(UsrName, UsrPassword, CpyID = 0, LexID = 0) {
        localStorage.clear();

        return Service.post(menu + "login", {
            UsrName: UsrName,
            UsrPassword: UsrPassword,
            CpyID: CpyID,
            LexID: LexID,
                //databaseName: databaseName
        });
    },

    register(obj) {
        return Service.post(menu + "registeruser", obj, {
            params: { },
        });
    },

    validateAccount(UsrID) {
        return Service.get(menu + "validateaccount", {
            params: { UsrID: UsrID },
        });
    },

    changePassword(password, passwordOld, usr) {
        usr.UsrPassword = password;
        usr.UsrPasswordOld = passwordOld
        return Service.post(menu + "changepassword", usr, {
            params: { requestID: usr.UsrID },
        });
    },

    initialize(UsrID, token) {
        return Service.get("/UserOption/ListByUser", {
            params: { UsrID: UsrID },
        });
    },

    userOption(UsrID, optCode) {
        return Service.get("/UserOption/UserOptionAllow", {
            params: { optCode: optCode, UsrID: UsrID },
        });
    },

    createToken(obj) {
        return Service.post(
            menu + "createhtoken", obj, {
                params: {
                    //requestID: UsrID,
                },
                headers:{
                    "X-Role": "Imp-User"
                }
            },
        );
    },

    refreshToken(obj) {
        return Service.post(
            menu + "refreshtoken", obj, {
                params: {
                    //requestID: UsrID,
                },
            },
        );
    },
};