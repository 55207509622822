<template>
<v-card elevation="0">
    <vc-header v-if="visibleTitle" title="Persona" />
    <v-card-text>
        <form id="person">
        <v-divider></v-divider>
            <v-row>
                <v-col class="s-col-form" sm="3" md="3">
                    <vc-select-definition id="TypePersonDocument" :def="1007" label="Tipo Documento" v-model="person.GenPerson.TypePersonDocument" />
                </v-col>
                <v-col class="s-col-form" sm="3" md="3">
                    <vc-text id="PrsDocumentNumber" @keyupEnter="txtDocument()" label="Documento*" number @blur="search()" ref="document" :autofocus="person.GenPerson.PrsDocumentNumber.length == 0" v-model="person.GenPerson.PrsDocumentNumber" />
                </v-col>
                <v-col class="s-col-form" sm="6" md="6" v-if="typeperson == 1">
                    <vc-text ref="textNtpName" id="NtpName" label="Nombre*" @keyupEnter="save()" v-model="person.NtpName" :autofocus="person.GenPerson.PrsDocumentNumber.length > 0" />
                </v-col>
                <!-- <v-col class="s-col-form" sm="6" md="6" v-if="typeperson == 2">
                    <vc-text ref="textJrpName" label="Nombre Juridico*" v-model="person.JrpName" :autofocus="person.GenPerson.PrsDocumentNumber.length > 0" />
                </v-col> -->
            </v-row>

            <v-row v-if="typeperson == 1">
                <v-col class="s-col-form" sm="6" md="6">
                    <vc-text id="NtpPaternalSurname" label="Apellido Paterno*" v-model="person.NtpPaternalSurname" @keyupEnter="save()" />
                </v-col>
                <v-col class="s-col-form" sm="6" md="6">
                    <vc-text id="NtpMaternalSurname" label="Apellido Materno*" @keyupEnter="save()" v-model="person.NtpMaternalSurname" />
                </v-col>
            </v-row>
        </form>
    </v-card-text>
    <vc-footer save close @save="save()" @close="close()"></vc-footer>
</v-card>
</template>

<script>
import Service from "@/services/General/GenPersonService";
import _sGeneric from "@/services/General/GenGenericService";
import _validaERP from '@/plugins/validaERP';

export default {
    name: "sAddPerson",
    components: {

    },
    props: {
        propPerson: {
            type: Object,
            default: null,
        },
        visibleTitle: {
            type: Boolean,
            default: true,
        },
        typeperson: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            editing: false,
            config: {},
            dialog: false,

            person: {
                NtpName: "",
                NtpPaternalSurname: "",
                NtpMaternalSurname: "",
                NtpID: 0,
                GenPerson: {
                    TypePersonDocument: 0,
                    PrsDocumentNumber: "",
                },
            },
        };
    },
    methods: {

        txtDocument() {
            if (this.typeperson == 1) this.$refs.textNtpName.focus();
        },

        search() {
            this.clear();
            let document = this.person.GenPerson.PrsDocumentNumber.length <= 0 ? '00000000': this.person.GenPerson.PrsDocumentNumber
            console.log("document",document);

            Service.getNaturalPerson(document, this.$fun.getUserID()).then((r) => {
                if (r.status == 200) {
                    this.editing = true;
                    this.personEdit = r.data.Result;

                    if (this.personEdit != null) {
                        if (this.personEdit.GenPerson != null) {
                            this.person = {
                                ...this.personEdit
                            };
                            this.person.GenPerson.TypePersonDocument = 1;

                        } else if (this.propPerson.PrsDocumentNumber) this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;

                        if (this.typeperson == 1) this.$refs.textNtpName.focus();
                        else this.$refs.textJrpName.focus();
                    }
                    this.editing = false;
                }
                this.$emit("search", this.person);

                console.log("personEdit",this.personEdit);
            });
        },
        clear() {

            let doc;
            if (this.person.GenPerson.PrsDocumentNumber.length > 0) doc = this.person.GenPerson.PrsDocumentNumber;
            else doc = "";
            this.person = {
                NtpName: "",
                NtpPaternalSurname: "",
                NtpMaternalSurname: "",
                NtpID: 0,
                GenPerson: {
                    TypePersonDocument: 1,
                    PrsDocumentNumber: doc,
                },
            };
        },
        save() {

            this.person.TypePerson = this.typeperson;

            //Validamos Campos
            var resp = _validaERP.valida({
                form: '#person',
                items: {
                    PrsDocumentNumber: {
                        required: true,
                        value: this.person.GenPerson.PrsDocumentNumber,
                    },
                    NtpName: {
                        required: true,
                        value: this.person.NtpName,
                    },
                    NtpPaternalSurname: {
                        required: true,
                        value: this.person.NtpPaternalSurname,
                    },
                    NtpMaternalSurname: {
                        required: true,
                        value: this.person.NtpMaternalSurname,
                    },

                }
            })

            this.person.PrsDocumentNumber = this.person.GenPerson.PrsDocumentNumber.trim();
            this.person.TypePersonDocument = this.person.GenPerson.TypePersonDocument
            this.person.NtpName = this.person.NtpName.toUpperCase();
            this.person.NtpPaternalSurname = this.person.NtpPaternalSurname.toUpperCase();
            this.person.NtpMaternalSurname = this.person.NtpMaternalSurname.toUpperCase();
            this.person.CpyID = localStorage.getItem('CpyID')
            this.person.UsrCreateID = this.$fun.getUserID()

            let p = {
                ...this.person
            };

            console.log("save",p);

            if (resp) {

                Service.save(p, this.$fun.getUserID()).then(() => {
                    this.$emit("personSaved", this.person);
                    this.$emit("save", this.person);
                    this.person.GenPerson.PrsDocumentNumber = "";
                    this.clear();
                    this.$fun.alert("Guardado Correctamente", "success");
                    this.close();
                });

            }

        },
        close() {
            this.$refs.document.focus();
            this.$emit("toclose", 2);
            this.clear();
        },
    },
    watch: {
        propPerson() {
            if (this.propPerson != null) {
                if (this.propPerson.GenPerson != null) {
                    this.person = {
                        ...this.propPerson
                    };

                    this.person.GenPerson.TypePersonDocument = 1;
                } else {
                    this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;
                }
            }
        },

    },
    created() {
        if (this.propPerson != null)
            if (this.propPerson.GenPerson != null) {
                this.person = Object.assign(this.propPerson, {});
            } else this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;
    },
};
</script>
