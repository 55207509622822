<template>
<div>

    <vc-loading :processing="processing" :errors="errors" @close="processing=false;" />

    <div v-if="dialogDissaproved">
        <v-dialog v-model="dialogDissaproved" width="400" persistent>
            <v-card>
                <vc-header title="Motivo" close @close="dialogDissaproved=false"></vc-header>
                <!-- <v-container> -->
                <v-row style="margin:auto">
                    <v-col cols="12">
                        <vc-textarea label="Motivo de Desaprobación" autofocus v-model="transactionApproved.AtsObservation" />
                    </v-col>
                    <v-col cols="12">
                        <v-btn color="primary" block small @click="disapproved()">Desaprobar</v-btn>
                    </v-col>
                </v-row>
                <!-- </v-container> -->
            </v-card>
        </v-dialog>
    </div>
        <v-footer style="border-radius: 5px;font-family:;" dense flat class="body-1 font-weight-bold" :color="'white'" >
        <!--  <v-toolbar class="elevation-0" dense height="43" color="white" style="border-radius: 0px;font-family:Calibri">
 -->
       <!--  <v-toolbar-title>
            <p style="margin-top:20px !important;font-size:16px;font-weight:600 ;color:#858585;">
                {{ title }}</p>
        </v-toolbar-title> -->
         <p class="mb-0 mt-0" style="margin-top:0px !important;font-size:14px;font-weight:600 ;color:#858585;">
                {{ title }}</p>


        <v-spacer></v-spacer>
        <slot name="options"> </slot>

        <v-tooltip bottom="" v-if="approve">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" class="capitalize ml-1 mr-1" v-on="on" small @click="approved()">
                    <v-icon color="green" style="font-size:16px;margin-right:5px;"> far fa-thumbs-up</v-icon>Aprobar
                </v-btn>
            </template>
            <span>Aprobar</span></v-tooltip>

        <v-tooltip bottom="" v-if="approve">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" class="capitalize ml-1 mr-1" v-on="on" small @click="disapprovedOpen()">
                    <v-icon color="red" style="font-size:16px;margin-right:5px;"> far fa-thumbs-down</v-icon>Desaprobar
                </v-btn>
            </template>
            <span>Desaprobar</span></v-tooltip>
        <!-- Fin Transacciones -->

        <!-- //Inicio Botones -->
        <!-- <v-tooltip bottom="" v-if="add && $fun.getSecurity().IsAllowAdd">
            <template v-slot:activator="{ on }"> 
        <v-btn v-if="add && $fun.getSecurity().IsAllowAdd" text icon small append elevation="0" @click="$emit('add')" class="ml-1 mr-1 capitalize">
            <v-icon class="mr-0" style="font-size:14px;">fa-plus</v-icon>

        </v-btn>-->

        <!-- <v-tooltip bottom="" v-if="add && $fun.getSecurity().IsAllowAdd">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" small append elevation="0" @click="$emit('add')" class="capitalize m-1 ml-1 mr-1">
                    <v-icon color="success" style="font-size:12px;margin-right:5px;">fa-plus</v-icon> Nuevo
                </v-btn>
            </template>
            <span>Nuevo</span>
        </v-tooltip> -->
        
        <v-tooltip bottom="" v-if="add && $fun.getSecurity().IsAllowAdd">
            <template v-slot:activator="{ on }">
                <v-btn  v-on="on" small elevation="0" color="" @click="$emit('add')"   class="capitalize m-1 ml-1 mr-1">
                    <v-icon color="success" style="font-size:12px;margin-right:5px;">fa-plus</v-icon> Nuevo
                </v-btn>
            </template>
            <span>Nuevo</span>
        </v-tooltip>
        <!--  </template>
            <span>Agregar</span>
        </v-tooltip>
 -->

        <v-tooltip bottom="" v-if="save && $fun.getSecurity().IsAllowSave">
            <template v-slot:activator="{ on }">
                <v-btn small elevation="0" color="" v-on="on" class="capitalize save ml-1 mr-1" @click="$emit('save')">
                    <v-icon style="font-size:16px;margin-right:5px;" color="info">mdi-content-save</v-icon> Guardar
                </v-btn>
            </template>
            <span>Guardar</span>
        </v-tooltip>

        <!--   <v-tooltip bottom="" v-if="edit && $fun.getSecurity().IsAllowEdit">
            <template v-slot:activator="{ on }">

        <v-btn v-if="edit && $fun.getSecurity().IsAllowEdit" icon text small @click="$emit('edit')" class="ml-1 mr-1 capitalize">
            <v-icon class="mr-0" style="font-size:12px;">fa-pencil</v-icon>

        </v-btn>-->

        <v-tooltip bottom="" v-if="edit && $fun.getSecurity().IsAllowEdit">
            <template v-slot:activator="{ on }">
                <v-btn  small elevation="0" color="" v-on="on" @click="$emit('edit')" class="  ml-0 mr-0 capitalize">
                    <v-icon color="info" style="font-size:10px;margin-right:5px;">fa-pencil</v-icon> Editar
                </v-btn>
            </template>
            <span>Editar</span>
        </v-tooltip>

        <!-- </template>
            <span>Editar</span>
        </v-tooltip> -->

        <div v-if="item !== null && $fun.getSecurity().IsAllowDelete">
            <v-tooltip bottom="" v-if="restore && item.SecStatus == 0">
                <template v-slot:activator="{ on }">
                    <v-btn elevation="0" small v-on="on" @click="$emit('delete', 1)" class="mt-1 ml-1 mr-1 capitalize">
                        <v-icon style="font-size:18px;margin-right:5px;">mdi-checkbox-marked-circle-outline</v-icon>Habilitar
                    </v-btn>
                </template>
                <span>Habilitar</span>
            </v-tooltip>

            <!-- && item.SecStatus == 1 -->

            <!-- <v-tooltip bottom="" v-if="remove">
                <template v-slot:activator="{ on }"> -->
            <v-tooltip bottom="" v-if="remove">
                <template v-slot:activator="{ on }">
                    <v-btn small elevation="0" color="" v-on="on" @click="$emit('delete', 0)" class="  ml-1 mr-0 capitalize">
                        <!-- <v-icon style="font-size:16px;">fa-xmark</v-icon> -->
                        <v-icon style="font-size:10px;margin-right:5px;">fa fa-trash</v-icon>Eliminar

                        <!-- mdi-close -->
                    </v-btn>
                </template>
                <span>Eliminar</span>
            </v-tooltip>
            <!--  <v-btn v-if="remove" icon text small @click="$emit('delete', 0)" class="ml-1 mr-1 capitalize">

                <v-icon class="mr-0" style="font-size:16px;">mdi-close</v-icon>

            </v-btn>-->
            <!--  </template>
                <span>Eliminar</span>
            </v-tooltip> -->
        </div>

        <v-tooltip bottom="" v-if="view && $fun.getSecurity().IsAllowView">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" small v-on="on" @click="$emit('view')" class="capitalize save ml-1 mr-1">
                    <v-icon style="font-size:14px;margin-right:5px;">far fa-eye</v-icon>Visualizar
                </v-btn>
            </template>
            <span>Ver</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="close">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" class="capitalize ml-1 mr-1" v-on="on" small @click="$emit('close')">
                    <v-icon style="font-size:18px;margin-right:5px;">mdi-close</v-icon>Salir
                </v-btn>
            </template>
            <span>Salir</span>
        </v-tooltip>

        <!-- //Inicio Botones -->

        <!--  //Fin Botones -->

        <!-- <div v-if="search">
            <v-menu v-if="!searchInput" v-model="menuSearch" :close-on-content-click="false" offset-x>
                <template v-slot:activator="{ on, attrs }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ ons }">
                            <div v-on="ons" style="display:inline;">
                                <v-btn text small v-on="on" v-bind="attrs">
                                    <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <span>Buscar</span>
                    </v-tooltip>
                </template>
                <v-card rounded="">
                    <v-list>
                        <v-col style="padding-top:0px !important;padding-bottom:0px !important;">
                            <v-text-field label="Buscar" style="margin:5px;" class="s-toolbar-search" ref="buscar" v-model="searchText" :append-icon="searchBottom ? '' : 'mdi-magnify'" autofocus="" outlined dense clearable hide-details>
                                <template v-slot:prepend>
                                    <v-btn icon x-small v-if="searchBottom" @click="getAnswer()">
                                        <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-list>
                </v-card>
            </v-menu>
        </div>
        <div>
            <v-text-field v-if="searchInput" style="margin-top:-1px;width:150px !important;" ref="buscar" height="30" class="" width="60" :append-icon="searchBottom ? '' : 'mdi-magnify'" v-model="searchText" dense clearable hide-details>
                <template v-slot:append>
                    <v-btn icon x-small v-if="searchBottom" @click="getAnswer()">
                        <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                    </v-btn>
                </template>
            </v-text-field>
        </div> -->
        <!-- </v-toolbar> -->

    </v-footer>

</div>
</template>

<script>
export default {
    name: "vcFooter",
    props: {
        title: {
            type: String,
            default: "Vuetify Confirm Dialog Example",
        },

        noDark: {
            type: Boolean,
            default: true,
        },
        close: {
            type: Boolean,
            default: false,
        },
        searchInput: {
            type: Boolean,
            default: false,
        },
        searchBottom: {
            type: Boolean,
            default: false
        },
        search: {
            type: Boolean,
            default: false,
        },

        //Eventos
        save: {
            type: Boolean,
            default: false,
        },
        add: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        remove: {
            type: Boolean,
            default: false,
        },
        restore: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Boolean,
            default: false,
        },
        view: {
            type: Boolean,
            default: false,
        },

        entity: {
            type: Object,
            default: null,
        },

        //Transacciones
        approve: {
            type: Boolean,
            default: false,
        },
        optionemit: {
            type: Boolean,
            default: false,
        },
        transactionApproved: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        menuSearch: "",
        searchText: "",
        item: {
            SecStatus: 1
        },
        dialogDissaproved: false,
        //Loading
        processing: false,
        errors: null,
    }),
    mounted() {},
    methods: {

        disapprovedOpen() {
            if (this.transactionApproved == null) {
                this.$emit("disapproved");
            } else {
                this.dialogDissaproved = true
            }
            console.log(this.transactionApproved)
        },
        disapproved() {

            if (this.transactionApproved)

                this.$fun.sweetAlert("Seguro de desaprobar Documento?", "question").then((r) => {
                    if (r.value) {
                        this.processing = true
                        this.transactionApproved.AtsStatus = 3;
                        this.transactionApproved.UsrCreateID = this.$fun.getUserID();

                        _sApprovalTransaction.approved(this.transactionApproved, this.$fun.getUserID()).then((rq) => {
                                this.processing = false;
                                this.$fun.sweetAlert("Documento desaprobado", "success");
                                this.$emit("close");
                            },
                            (e) => {
                                this.errors = e.response
                                this.processing = true;
                            });
                    }
                });
            else this.$emit("disapproved");
        },
        approved() {
            /* console.log("approved", this.optionemit);
            if (this.optionemit == true) {
                console.log("optionemit", this.optionemit);
                this.$emit("approved");
                return
            } */

            if (this.transactionApproved && !this.optionemit) {
                console.log("transactionApproved", this.transactionApproved);

                this.$fun.sweetAlert("Seguro de aprobar Documento?", "question").then((r) => {
                    if (r.value) {
                        this.processing = true

                        this.transactionApproved.AtsStatus = 2;
                        this.transactionApproved.UsrCreateID = this.$fun.getUserID();

                        _sApprovalTransaction.approved(this.transactionApproved, this.$fun.getUserID()).then((rq) => {
                                this.processing = false;
                                this.$fun.sweetAlert("Aprobado Correctamente", "success");
                                this.$emit("close");
                            },
                            (e) => {

                                this.errors = e.response
                                this.processing = true;
                            });
                    }
                });
            } else this.$emit("approved");
        },
        getAnswer() {
            if (this.searchText == null) this.searchText = "";
            this.$emit("search", this.searchText);
        },

        approved() {
            this.$emit("approved");
        },

        disapproved() {
            this.$emit("disapproved");
        }
    },
    watch: {
        searchText() {
            if (!this.searchBottom)
                this.debouncedGetAnswer();
        },
        entity() {
            this.item = this.entity
            /* console.log("entityentityentityentity", this.item, this.restore) */
        },
    },
    computed: {
        colorToolbar() {
            return localStorage.getItem('colorToolbar');
        }
    },
    created() {
        this.item = this.entity
        /*  console.log("entity al levantar", this.item, this.restore) */
        this.debouncedGetAnswer = _.debounce(this.getAnswer, 500);
    },
};
</script>

<style lang="scss" scoped></style>
