let children = [];

children.push({
    path: "/card/encuesta",
    name: "CRD_SURVEY",
    component: () =>
        import ("../views/Card/CrdSurvey.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Card", disabled: true },
            { text: "Encuesta", href: "/card/encuesta" },
        ],
    },
});

children.push({
    path: "/card/resources",
    name: "CRD_RESOURCES",
    component: () =>
        import ("../views/Card/CrdResources.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Card", disabled: true },
            { text: "Resources", href: "/card/resources" },
        ],
    },
});

children.push({
    path: "/card/openinghours",
    name: "CRD_HOURS",
    component: () =>
        import ("../views/Card/CrdHours.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Card", disabled: true },
            { text: "Horario de atención", href: "/card/openinghours" },
        ],
    },
});

children.push({
    path: "/card/followup",
    name: "CRD_FOLLOWUP",
    component: () =>
        import ("../views/Card/CrdFollowUp.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Card", disabled: true },
            { text: "Seguimiento", href: "/card/followup" },
        ],
    },
});




export { children };