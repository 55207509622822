<template>
<div class="d-flex">

    

    <div class="header-btn-lg pr-0">
        <div class="widget-content p-6">
            <div class="widget-content-wrapper">               

                <div class="widget-content-left ml-3 header-user-info" style="margin-right:100px">
                    
                    <div class="widget-heading" v-if="user.UsrExtern == 1">{{user.UsrName}}</div>
                    <div class="widget-heading" v-else>{{user.GenWorkerCE !== null ? user.NtpFullName : user.UsrName}}</div>
                    <div class="widget-subheading">{{user.GenWorkerCE != null ? user.GenWorkerCE.PstName : 'Usuario externo'}}</div>
                    
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueCircle from "vue2-circle-progress";
import Trend from "vuetrend";

import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faAngleDown,
    faCalendarAlt,
    faTrashAlt,
    faCheck,
    faFileAlt,
    faCloudDownloadAlt,
    faFileExcel,
    faFilePdf,
    faFileArchive,
    faEllipsisH,
} from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

library.add(
    faAngleDown,
    faCalendarAlt,
    faTrashAlt,
    faCheck,
    faFileAlt,
    faCloudDownloadAlt,
    faFileExcel,
    faFilePdf,
    faFileArchive,
    faEllipsisH,
);

export default {

    components: {
        'font-awesome-icon': FontAwesomeIcon,
        VuePerfectScrollbar,
        VueCircle,
        trend: Trend,

    },
    props: {
        configUser: {
            type: Object,
            default: null,
        },
        user: {
            type: Object,
            default: {
                PrsDocumentNumber: ""
            },
        },
    },
    data: () => ({
        UsrName: "",
        itemsAlert: [],
        itemsAlert_byTrs: [],
        itemsAlertPending: [],
        countAlert: 0,
        showChangePassword: false,

        fill1: {
            gradient: ["#00b09b", "#96c93d"]
        },
        fill2: {
            gradient: ["#ff0844", "#ffb199"]
        },
        fill3: {
            gradient: ["#f6d365", "#fda085"]
        },
        showDrawerSection: false
    }),

    created() {
        this.UsrName = localStorage.getItem('UsrName');
    },

    methods: {
    }
}
</script>
