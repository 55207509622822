<template>
<div style="padding-top:-2px;margin-top:-2px;">
    <v-label><b>{{ label }}</b></v-label>
    <vc-select-generic autocomplete style="margin-top:-18px" class="text" itemtext="GenDescription" itemvalue="GenID" :disabled="disabled" id="GenDescription" :value="value" :return-object="returnObject" @input="input($event)" :config="config" :full=true :text="text" :clearable="clearable" :noDefault="noDefault">
    </vc-select-generic>
</div>
</template>

<script>
import _sGenericService from "@/services/General/GenGenericService";
export default {
    name: "SelectProperty",
    components: {},
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        value: null,
        text: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "Tabla",
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        typeparameters: {
            type: Boolean,
            default: false,
        },
        noDefault: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
        typeprocess: {
            type: Number,
            default: 1,
        },
    },
    watch: {
        value(value) {
            this.item = value;
        },
    },
    methods: {
        input(val) {
            this.$emit("input", val);
        },
    },
    data: () => ({
        item: 0,
        val: "",
        items: [],
        config: {},
    }),

    created() {
        this.config = {
            url: _sGenericService.getURLTransaction(),
            title: this.label,
            params: {
                search: "",
                requestID: this.$fun.getUserID(),
                TypeProcess: this.typeprocess,
                UsrID: this.$fun.getUserID(),
                CpyID: localStorage.getItem('CpyID')
            },
        };
    },
};
</script>
