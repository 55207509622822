<template>
<div class="form-group">
    <div :class="hasIcon(icon)">
        <span v-if="iconDir === 'left'" class="input-group-text">
            <i :class="getIcon(icon)"></i>
        </span>
        <!-- <b-form-input class="form-control" :class="getClasses(size, valid)" :placeholder="placeholder"></b-form-input> -->
        <input :type="type" class="form-control" :class="getClasses(size, valid)" :name="name" :id="id" :value="value" :placeholder="placeholder" :isRequired="isRequired" />
        <span v-if="iconDir === 'right'" class="input-group-text">
            <i :class="getIcon(icon)"></i>
        </span>
    </div>
</div>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: "default",
        },
        valid: {
            type: Boolean,
            default: false,
        },
        icon: String,
        iconDir: String,
        name: String,
        id: String,
        value: String,
        placeholder: String,
        type: String,
        isRequired: Boolean,
    },
    data() {
    return {
      valueModel: "",
    };
  },
    methods: {
        getClasses: (size, valid) => {
            let sizeValue, isValidValue;

            sizeValue = size ? `form-control-${size}` : null;

            isValidValue = valid ? `${valid}` : "invalid";

            return `${sizeValue} ${isValidValue}`;
        },
        getIcon: (icon) => (icon ? icon : null),
        hasIcon: (icon) => (icon ? "input-group" : null),
    },
};
</script>
