import Service from "../Service";

const resource = "LgsOrderPurcharseAttended/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {},
        });
    },

    byID(parameters, requestID) {
        return Service.post(resource + "byid", parameters, {
            params: {},
        });
    },


    save(obj) {
        return Service.post(resource + "save", obj, {
            params: {},
        });
    },

    anulated(obj) {
        return Service.put(resource + "anulated", obj, {
            params: {},
        });
    },

    generatedConformity(OrdID, OttDocumentNumber) {
        return Service.get(resource + "generatedconformity", {
            params: {
                OrdID: OrdID,
                OttDocumentNumber: OttDocumentNumber
            },
        });
    },

    /* update(obj, requestID) {
        return Service.put(resource + "update", obj, {
            params: {},
        });
    }, */

    savefiles(obj, requestID) {
        return Service.post(resource + "savefiles", obj, {
            params: {},
        });
    },


};