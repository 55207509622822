<template>
<div style="">
    <vc-loading :processing="processing" :errors="errors" @close="processing=false;" />
    <div>
        <v-card>
            <vc-header title="Motivo" close @close="$emit('close')">
                <template v-slot:options>
                    <v-btn elevation="0" class="capitalize ml-1 mr-1" small @click="anulated">
                        <v-icon color="" style="font-size:16px;margin-right:5px;"> fa-solid fa-ban</v-icon>Anular
                    </v-btn>
                </template>
            </vc-header>
            <!-- <v-container> -->
            <form id="formanulated">
                <v-row style="margin:auto">
                    <v-col cols="12">
                        <!-- <vc-textarea label="Motivo de Anulación" autofocus v-model="Observation" /> -->
                        <vc-select-definition id="TypeAnulated" :def="def" :dgr="dgr" label="Motivo de anulación" v-model="TypeAnulated" />
                    </v-col>
                </v-row>
            </form>

        </v-card>
    </div>

</div>
</template>

<script>
import _validaERP from '@/plugins/validaERP';

export default {
    name: "",
    props: {
        def: 0,
        dgr: 0
    },

    data: () => ({
        TypeAnulated: 0,
        //Loading
        processing: false,
        errors: null,

    }),
    mounted() {},
    methods: {
        anulated() {

            var resp = _validaERP.valida({
                form: '#formanulated',
                items: {
                    TypeAnulated: {
                        required: true,
                        value: this.TypeAnulated,
                    },
                }
            })
            if (resp) {
                this.TypeAnulated = this.TypeAnulated || ""
                this.$emit('anulated', this.TypeAnulated)
            }

        }
    },

};
</script>

<style lang="scss" scoped></style>
