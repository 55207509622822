<template>
<div v-if="showNotification" class="notification" :class="{ visible: showNotification }" @click="handleClick" >
    <div class="notification-icon">
        <i class="fas fa-warning"></i>
    </div>
    <div class="notification-content">
        <h3>¡Warning!</h3>
        <p>{{message}}</p>
    </div>
    <div class="notification-close ml-5" @click="dismissNotification">
        <i class="fas fa-times" @click="dismissNotification"></i>
    </div>
</div>
</template>

<script>
export default {
    props:{
        message: null
    },
    data() {
        return {
            showNotification: false,
        };
    },
    mounted() {
        this.showNotification = true;
        setTimeout(() => {
            this.dismissNotification();
        }, 10000);
    },
    methods: {
        handleClick() {
            this.openAppointmentModal();
            
        },
        dismissNotification() {
            this.showNotification = false;
        },
        openAppointmentModal() {
            this.$emit("notificacion")
            this.showNotification = false;
        },
    },
};
</script>

<style scoped>
.notification {
    position: fixed;
    bottom:70px;
    right: 20px;
    background-color: #fce8d8;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    animation: bell-animation 2s infinite alternate;
    margin-left: 20px;
    z-index: 9999;
}

.notification.visible {
    opacity: 1;
}

@media (min-width: 768px) {
    .notification {
        width: 40%;
    }
}

@keyframes bell-animation {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}

.notification-icon {
    margin-right: 10px;
}

.notification-icon i {
    font-size: 24px;
    color: #ff5722;
    /* Color naranja amigable */
}

.notification-content {
    flex: 1;
}

.notification-content h3 {
    font-size: 16px;
    margin: 0;
    margin-bottom: 5px;
    color: #5d7fbb;
    /* Color naranja amigable */
}

.notification-content p {
    font-size: 14px;
    margin: 0;
}

.notification-close {
    color: #999;
    font-size: 14px;
    cursor: pointer;
}

.notification-close:hover {
    color: #333;
}
</style>
