import Service from "../Service";

const resource = "PagPaymentGateway/";

export default {
    initialize(obj) {
        return Service.post(resource + "initialize", obj , {
            params: {
            },
        });
    },
    getCustomer(document) {
        return Service.get(resource + "customer", {
            params: {
                document: document
            },
        });
    },

    getSchedule(idproforma) {
        return Service.get(resource + "shedule", {
            params: {
                idproforma: idproforma
            },
        });
    },

    getPayment(idCuotaCobrar) {
        return Service.get(resource + "payment", {
            params: {
                idCuotaCobrar: idCuotaCobrar
            },
        });
    },

    byID(Key, UsrCreateID) {
        return Service.get(resource + "byID", {
            params: {
                Key: Key,
                UsrCreateID:UsrCreateID
            },
        });
    },

    dataExcel(obj) {
        return Service.post(resource + "dataexcel", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {},
        });
    },


};