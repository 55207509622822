<template>
<div>

    

    <v-row class="mt-0" justify="center">

        <v-col cols="12">
            <v-card elevation="0" style="margin-top: 2px;border-radius:10px;margin-bottom: 10px">
                <div class="card-body">

                    <h4 class="card-title" style="font-size: 16px;">DATOS PERSONALES</h4>
                    <v-divider></v-divider>
                    <v-row>

                    </v-row>
                </div>
            </v-card>
            <v-card style="border-radius:10px" elevation="0" class="pb-3 mt-0">
                <v-row justify="center" style="margin:auto">
                    <v-col cols="12" lg="6" md="8">
                        <v-card style="border-radius:10px" elevation="0" class="pb-0 mt-0">
                            <v-row>
                                <v-col cols="12">

                                    <div class="mb-3 card mt-0 pr-4 pl-2" style="border-radius: 10px;">

                                        <!-- <div class="card-header-tab card-header" style="font-size:18px;align-self: center;font-weight: 700;text-transform: initial;">
                                            Datos Personales
                                        </div> -->
                                        <div class="no-gutters row">

                                            <div class="col-sm-12 col-md-12 col-xl-12">
                                                <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                                                    <div class="widget-chart-content">
                                                        <div class="widget-subheading"></div>
                                                        <v-row>
                                                            <v-col cols="12" style="display: flex;">
                                                                Doc. Identidad<v-spacer class="mr-2"></v-spacer><b>{{item.nroDocumentoTitular}}</b>
                                                            </v-col>
                                                        </v-row>
                                                        <v-divider></v-divider>
                                                        <v-row>
                                                            <v-col cols="12" style="display: flex;">
                                                                Nombres<v-spacer class="mr-2"></v-spacer><b>{{item.nombreCompleto}}</b>
                                                            </v-col>
                                                        </v-row>
                                                        <v-divider></v-divider>
                                                        <v-row>
                                                            <v-col cols="12" style="display: flex;">
                                                                Correo Electronico
                                                                <v-spacer class="mr-2"></v-spacer><b>{{item.correoElectronico}}</b>
                                                            </v-col>
                                                        </v-row>
                                                        <v-divider></v-divider>
                                                        <v-row>
                                                            <v-col cols="12" style="display: flex;">
                                                                Telefono<v-spacer class="mr-2"></v-spacer><b>{{item.telefonoCelular}}</b>
                                                            </v-col>
                                                        </v-row>
                                                        <v-divider></v-divider>
                                                        <v-row>
                                                            <v-col cols="12" style="display: flex;">
                                                                Estado<v-spacer class="mr-2"></v-spacer><b>{{item.estado_Civil}}</b>
                                                            </v-col>
                                                        </v-row>
                                                        <v-divider></v-divider>
                                                        <v-row>
                                                            <v-col cols="12" style="display: flex;">
                                                                Genero<v-spacer class="mr-2"></v-spacer><b>{{item.genero}}</b>
                                                            </v-col>
                                                        </v-row>
                                                        <v-divider></v-divider>
                                                        <v-row>
                                                            <v-col cols="12" style="display: flex;">
                                                                Direccion<v-spacer class="mr-2"></v-spacer><b>{{item.direccion}}</b>
                                                            </v-col>
                                                        </v-row>
                                                        <v-divider></v-divider>
                                                    </div>

                                                </div>
                                                <div class="divider m-0 d-md-none d-sm-block"></div>
                                            </div>

                                        </div>

                                    </div>

                                </v-col>
                            </v-row>
                        </v-card>

                    </v-col>

                </v-row>
            </v-card>
        </v-col>

    </v-row>
</div>
</template>

<script>
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon,

    },
    props: {
        item: {
            type: Object,
            default: {}
        }
    },
    data() {

        return {

        };
    },

    mounted() {

    },

    created() {
        
    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>

</style>
