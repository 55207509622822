<template>
<div>
<v-footer >
    <v-toolbar dense height="38" :elevation="!noDark? '0':'0'" :color="colorToolbar"  style="border-radius: 0px;font-family:Calibri">
        <v-toolbar-title >
             <p style="margin-top:20px !important;font-size:16px;font-weight: ;color:#505050;">
                {{ title }}</p>
            </v-toolbar-title>
        <v-spacer></v-spacer>

        <slot name="options"> </slot>

        <!-- //Inicio Botones -->
        <v-tooltip bottom="" v-if="add && $fun.getSecurity().IsAllowAdd">
            <template v-slot:activator="{ on }">
                <v-btn   v-on="on" small append elevation="0" @click="$emit('add')" class="capitalize ml-1 mr-1">
                    <v-icon color="success" style="font-size:12px;margin-right:5px;">fa-plus</v-icon>    Nuevo    
                </v-btn>
            </template>
            <span>Agregar</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="save && $fun.getSecurity().IsAllowSave">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0"  small v-on="on" class="capitalize save ml-1 mr-1" @click="$emit('save')" >
                 <v-icon  style="font-size:16px;margin-right:5px;">mdi-content-save</v-icon> Guardar
                </v-btn>
            </template>
            <span>Guardar</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="edit && $fun.getSecurity().IsAllowEdit">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0"   small v-on="on" @click="$emit('edit')"  class="ml-0 mr-0 capitalize">
                    <v-icon color="info" style="font-size:10px;margin-right:5px;">fa-pencil</v-icon>  Editar  
                    <!-- mdi-file-edit-outline -->
                </v-btn>
            </template>
            <span>Editar</span>
        </v-tooltip>

        <div v-if="item !== null && $fun.getSecurity().IsAllowDelete">
            <v-tooltip bottom="" v-if="restore && item.SecStatus == 0">
                <template v-slot:activator="{ on }">
                    <v-btn   small v-on="on" @click="$emit('delete', 1)" class="ml-1 mr-1">
                        <v-icon style="font-size:16px;">mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                </template>
                <span>Habilitar</span>
            </v-tooltip>

            <!-- && item.SecStatus == 1 -->

            <v-tooltip bottom="" v-if="remove">
                <template v-slot:activator="{ on }">
                    <v-btn elevation="0"  small v-on="on" @click="$emit('delete', 0)" class="ml-1 mr-3">
                        <!-- <v-icon style="font-size:16px;">fa-xmark</v-icon> -->
                        <v-icon  style="font-size:10px;">fa fa-trash</v-icon>
                        
                        <!-- mdi-close -->
                    </v-btn>
                </template>
                <span>Eliminar</span>
            </v-tooltip>
        </div>

        <v-tooltip bottom="" v-if="view && $fun.getSecurity().IsAllowView">
            <template v-slot:activator="{ on }">
                <v-btn icon text small v-on="on"  @click="$emit('view')" class="ml-1 mr-1">
                    <v-icon style="font-size:14px;">far fa-eye</v-icon>
                </v-btn>
            </template>
            <span>Ver</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="close">
            <template v-slot:activator="{ on }">
                <v-btn icon text v-on="on" small @click="$emit('close')" elevation="0" class="ml-1 mr-1">
                    <v-icon style="font-size:16px;">mdi-close</v-icon>
                </v-btn>
            </template>
            <span>Cerrar</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="pdf">
            <template v-slot:activator="{ on }">
                <v-btn icon text v-on="on" small @click="$emit('pdf')" elevation="0" class="ml-1 mr-1">
                    <v-icon style="font-size:18px;">far fa-file-pdf</v-icon>
                </v-btn>
            </template>
            <span>Cerrar</span>
        </v-tooltip>

         <!-- //Inicio Botones -->
        <!-- //Transacciones -->
        <v-tooltip bottom="" v-if="approve">
            <template  v-slot:activator="{ on }">
                <v-btn icon text v-on="on" small @click="approved()" class="ml-1 mr-1">
                    <v-icon color="green" style="font-size:16px;"> far fa-thumbs-up</v-icon>
                </v-btn>
            </template>
            <span>Aprobar</span></v-tooltip>

        <v-tooltip bottom="" v-if="approve">
            <template v-slot:activator="{ on }">
                <v-btn icon text v-on="on" small @click="disapproved()" class="ml-1 mr-1">
                    <v-icon color="red" style="font-size:16px;"> far fa-thumbs-down</v-icon>
                </v-btn>
                <!-- <v-btn icon text v-on="on" small @click="dialogDissaproved = true" class="ml-1 mr-1">
                    <v-icon color="red" style="font-size:16px;"> far fa-thumbs-down</v-icon>
                </v-btn> -->
            </template>
            <span>Desaprobar</span></v-tooltip>
        <!-- Fin Transacciones -->

        <!--  //Fin Botones -->

        <div v-if="search">
            <v-menu v-if="!searchInput" v-model="menuSearch" :close-on-content-click="false" offset-x>
                <template v-slot:activator="{ on, attrs }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ ons }">
                            <div v-on="ons" style="display:inline;">
                                <v-btn text small v-on="on" v-bind="attrs">
                                    <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <span>Buscar</span>
                    </v-tooltip>
                </template>
                <v-card rounded="">
                    <v-list>
                        <v-col style="padding-top:0px !important;padding-bottom:0px !important;">
                            <v-text-field outlined label="Buscar" style="margin:5px;" class="s-toolbr-search" ref="buscar" v-model="searchText" :append-icon="searchBottom ? '' : 'mdi-magnify'" autofocus=""  dense clearable hide-details>
                                <template v-slot:prepend>
                                    <v-btn icon x-small v-if="searchBottom" @click="getAnswer()">
                                        <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                                    </v-btn>
                                    <!-- <v-icon v-if="searchBottom" style="font-size:18px;">mdi-magnify</v-icon> -->
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-list>
                </v-card>
            </v-menu>
        </div>
        <div>
            <v-text-field v-if="searchInput" outlined style="font-size:12px !important;margin-top:4px;width:150px !important;" ref="buscar" height="20" class="" width="60" :append-icon="searchBottom ? '' : 'mdi-magnify'" v-model="searchText" dense clearable hide-details>
                <template v-slot:append>
                    <v-btn icon x-small v-if="searchBottom" @click="getAnswer()">
                        <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                    </v-btn>
                    <!-- <v-icon v-if="searchBottom" style="font-size:18px;">mdi-magnify</v-icon> append-icon="mdi-magnify"-->
                </template>
            </v-text-field>
        </div>

    </v-toolbar>
    
    
    </v-footer>
</div>
</template>

<script>
export default {
    name: "vcHeader",
    props: {
        title: {
            type: String,
            default: "Vuetify Confirm Dialog Example",
        },

        noDark: {
            type: Boolean,
            default: true,
        },
        close: {
            type: Boolean,
            default: false,
        },
        searchInput: {
            type: Boolean,
            default: false,
        },
        searchBottom: {
            type: Boolean,
            default: false
        },
        search: {
            type: Boolean,
            default: false,
        },

        //Eventos
        save: {
            type: Boolean,
            default: false,
        },
        add: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        remove: {
            type: Boolean,
            default: false,
        },
        restore: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Boolean,
            default: false,
        },
        view: {
            type: Boolean,
            default: false,
        },

        pdf: {
            type : Boolean,
            default : false
        },

        entity: {
            type: Object,
            default: null,
        },

        //Transacciones
        approve: {
            type: Boolean,
            default: false,
        },
        transactionApproved: {
            type: Object,
            default: null,
        },

    },

    data: () => ({
        menuSearch: "",
        searchText: "",
        item: {
            SecStatus: 1
        }
    }),
    mounted() {},
    methods: {
        getAnswer() {
            if (this.searchText == null) this.searchText = "";
            this.$emit("search", this.searchText);
        },

        approved() {
            this.$emit("approved");
        },

        disapproved()
        {
            this.$emit("disapproved");
        }
    },
    watch: {
        searchText() {
            if (!this.searchBottom)
                this.debouncedGetAnswer();
        },
        entity() {
            this.item = this.entity
            /* console.log("entityentityentityentity", this.item, this.restore) */
        },
    },
    computed: {
        colorToolbar() {
            return  'white';//localStorage.getItem('colorToolbar');
        }
    },
    created() {
        this.item = this.entity
       /*  console.log("entity al levantar", this.item, this.restore) */
        this.debouncedGetAnswer = _.debounce(this.getAnswer, 500);
    },
};
</script>

<style>
.basil {
    background-color: #fffbe6 !important;
}

.font-20 {
    font-size: 18px !important;
    padding-right: 5px;
}

.save {
    margin-right: 7px;
}

.tran {
    opacity: 0.9 !important;
    /*background: rgba(76, 175, 80, 1)*/
}
</style>
