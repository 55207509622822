<template>
<div>

    <vc-loading :processing="processing" :errors="errors" @close="processing=false;" />

    <!--CIRCUITO DE APRBACIONES MODAL-->
    <!-- <vc-modal-circuit close :circuits="circuits" :dialog="dialogCircuit" @close="dialogCircuit = false" @createCircuit="createCircuit($event)" md /> -->
    <!-- <vc-modal-circuit close :circuits="circuits" :TrsCode="TrsCode" :dialog="dialogCircuit" @close="dialogCircuit = false" :width="circuits.length > 1 ? 940 : 500" /> -->
    <v-dialog v-model="showUpload" v-if="showUpload" max-width="800">
        <vc-upload-files :config="configFile" @close="showUpload=false;" />
    </v-dialog>

    <div>
        <vc-header close @save="saveTmp()" @close="$emit('onClose')" :title="orderLocal.OttDocumentNumber + ' - ' + orderLocal.NtpFullName" :approve="optionApproved" :transactionApproved="transactionApproved">
            <template v-slot:options>
                <v-btn class="ml-1 mr-1 capitalize" title="Acta de Conformidad" elevation="0" style="text-transform:capitalize" small @click="conformity">
                    <v-icon style="font-size: 16px;margin-right:5px;">fa-solid fa-clipboard-check</v-icon>Acta de Conformidad
                    <!-- <i class="fa-solid fa-clipboard-check"></i> -->
                </v-btn>
            </template>
        </vc-header>
        <v-card class="mt-1">
            <!-- <v-col> -->
            <form id="formorder">
                <v-row style="margin:auto">
                    <v-col lg="3" cols="12">
                        <v-card outlined elevation="0" height="auto">
                            <v-col class="">

                                <v-row>
                                    <v-col class="s-col-text" cols="12" lg="12">
                                        <lgs-supplier disabled id="Supplier" label="Proveedor" v-model="Supplier" :value="Supplier" />
                                        <!-- <v-supplier  disabled label="Proveedor" v-model="Supplier" :value="Supplier" />
                                        <span v-if="ProviderBloqueado > 0" style="color: red; font-family: Calibri; font-size: 10px">Proveedor en Lista Negra</span> -->
                                    </v-col>
                                    <v-col lg="6" md="6" cols="12" class="s-col-text">
                                        <vc-text label="Contacto" disabled id="SupConName" v-model="Supplier.SupConName"></vc-text>
                                    </v-col>

                                    <v-col lg="6" md="6" cols="12" class="s-col-text">
                                        <vc-text number label="Telefono" disabled id="SupTelephone" v-model="Supplier.SupTelephone"></vc-text>
                                    </v-col>

                                    <v-col lg="12" cols="12" class="s-col-text">
                                        <vc-text label="Email" disabled id="SupEmail" v-model="Supplier.SupEmail"></vc-text>
                                    </v-col>

                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <vc-select-definition id="TypeCurrency" disabled :def="1020" v-model="orderLocal.TypeCurrency" label="Moneda" />
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <vc-select-definition id="TypeCredit" disabled :def="1021" v-model="orderLocal.TypeCredit" label="Tipo Credito" />
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <vc-select-definition id="TypePayment" disabled :def="1022" v-model="orderLocal.TypePayment" label="Forma de Pago" />
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <vc-text number id="OrdDeliveryDay" disabled label="Tiempo de entrega" v-model="orderLocal.OrdDeliveryDay"></vc-text>
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <vc-text number id="OrdWarrantyDay" disabled label="Garantia" v-model="orderLocal.OrdWarrantyDay"></vc-text>
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <vc-date id="OrdDate" label="Fecha" disabled v-model="orderLocal.OrdDate"></vc-date>
                                    </v-col>

                                    <v-col cols="12" class="s-col-form pt-0">
                                        <v-btn block text outlined elevation="0" class="capitalize mt-5" small @click="showUpload=true">
                                            <v-icon color="green" style="font-size:16px;margin-right:5px;">fa-solid fa-file</v-icon>Anexos
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <!--    <v-row style="margin-top:40px; margin-bottom: 0px;">
                                    <v-col lg="12" cols="12" class="s-col-text"><b style="font-family: ">Adjuntos</b>
                                        <v-btn class="ml-2" text color="success" small style="text-transform: initial" @click="uploadFile()">Upload
                                            <v-icon> mdi-arrow-up</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col lg="12" cols="12" class="s-col-text">
                                        <v-row>
                                            <v-col cols="12" v-for="(item, index) in itemsAttach" :key="item.RqdID" class="s-col-form">
                                                <v-file-input show-size :ref="'attach' + item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                                    <template slot="append" v-if="item.RqdRequired == 1">
                                                        <label style="color: red">(*)</label>
                                                    </template>
                                                </v-file-input>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row> -->

                            </v-col>
                        </v-card>
                    </v-col>

                    <v-col lg="9" cols="12">
                        <!-- <v-card outlined> -->

                        <v-row justify="center" style="border-left-style: outset;">
                            <v-col class="pr-0 pl-0">
                                <!-- <v-divider style="margin-bottom:1px;margin-top:-10px"></v-divider> -->

                                <v-row class="ml-2">
                                    <b>Datos Conformidad</b>
                                </v-row>
                                <v-divider style="margin-bottom:2px"></v-divider>
                                <v-row style="margin:auto">
                                    <v-col cols="4">
                                        <vc-date disabled cols="6" lg="4" md="4" label="Fecha Atencion" v-model="orderLocal.OttDate"></vc-date>
                                    </v-col>
                                    <v-col cols="4">
                                        <vc-text disabled label="Nª Guia de remision" v-model="orderLocal.OrdGuideRemission" ref="OrdGuideRemission"></vc-text>
                                    </v-col>

                                </v-row>

                                <!-- <v-row class="ml-2">
                                    <b>Sustentos Recibidos</b>
                                </v-row>
                                <v-divider style="margin-bottom:2px"></v-divider> -->

                                <v-row style="margin:auto">
                                    <lgs-order-purcharse-support disabled :support="itemsSupport" />

                                    <!-- <v-col v-for="(item, index) in Object.keys(itemsSupport_)" :key="index">
                                        <b>{{item}}</b>
                                        <div style="margin-top: -20px;">
                                            <v-checkbox disabled v-for="(d, index) in itemsSupport_[item]" :key="index" style="margin-bottom: -30px;" :label="d.RqsDetail" color="red" hide-details v-model="itemsRequiredSelected" :value="d">

                                            </v-checkbox>
                                        </div>
                                    </v-col> -->

                                    <!--  <v-col v-for="(item, index) in itemsRequiredSupport" :key="index" class="s-col-form">
                                        <b>{{index}}</b>
                                        <div style="margin-top: -20px;">
                                            <v-checkbox v-for="(it, ind) in item.details" :key="ind" :label="it" color="red" :value="it" v-model="itemsRequiredSelected" hide-details style="margin-bottom: -30px;">
                                            </v-checkbox>
                                        </div>

                                    </v-col> -->
                                </v-row>
                            </v-col>
                            <v-row>
                                <v-col>
                                    <v-card outlined>
                                        <v-data-table class="table-bordered" dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="OddID" :height="$vuetify.breakpoint.xs ? 'auto' : '200'" hide-default-footer>

                                        </v-data-table>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-col style="padding-top: 24px">
                                <v-row>
                                    <v-col cols="12">
                                        <v-row>
                                            <!--  <v-col cols="8" class="s-col-text">
                                                <v-card outlined>
                                                    <v-data-table class="table-bordered" fixed-header="" dense :items-per-page="-1" disable-sort hide-default-footer :headers="headersFiles" :items="orderLocal.itemsAttachs">
                                                        <template v-slot:item.RqdAttach="{ item }">
                                                            <v-icon color="red" @click="deleteFile(item)">mdi-close</v-icon>
                                                            <v-icon color="green" @click="openFile(item.Attach)">mdi-download</v-icon>
                                                            {{ item.RqdAttach }}
                                                        </template>

                                                        <template v-slot:item.SecCreate="{ item }">

                                                            {{$fun.formatDateTimeView(item.SecCreate)}}
                                                        </template>

                                                    </v-data-table>
                                                </v-card>
                                            </v-col> -->
                                            <v-col cols="12" class="s-col-text">
                                                <v-textarea disabled auto-grow ref="OrdObservation" label="Observaciones Generales" v-model="orderLocal.OrdObservation" />

                                            </v-col>

                                        </v-row>
                                    </v-col>

                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- </v-card> -->
                    </v-col>

                </v-row>
            </form>
            <!-- </v-col> -->
        </v-card>
    </div>
</div>
</template>

<script>
//Services
import _sOrderPurchaseAttended from "@/services/Logistics/LgsOrderPurcharseAttendedService";
import _sHelper from "@/services/HelperService.js"; 

/* import _AttachConfiguration from "@/services/General/GenAttachConfigurationService";
*/

//Components
import LgsOrderPurcharseSupport from '@/views/Logistics/LgsOrderPurcharse/LgsOrderPurcharseSupport.vue';
import LgsSupplier from "@/views/Logistics/LgsSupplier.vue";

export default {
    components: {
        LgsSupplier,
        LgsOrderPurcharseSupport
    },
    props: {
        order: {},
        value: null,
    },
    data() {
        return {

            orderLocal: {},
            Supplier: {
                SupID: null,
                SupDocumentNumber: null,
                SupName: null,
                SupConName: null,
                SupTelephone: null,
                SupEmail: null,
            },

            //Detalle
            itemsSAP: [],
            selectedSAP: [],
            headersSAP: [{
                    text: "Descripción",
                    value: "OddDescription",
                    sortable: false,
                    width: 340,
                },
                {
                    text: "Prc. U.(Sin IGV)",
                    value: "OddUnitPrice",
                    sortable: false,
                    width: 90,
                },
                {
                    text: "Cant. Solic.",
                    value: "OddQuantity",
                    sortable: false,
                    width: 50,
                },
                {
                    text: "Cant. recibida",
                    value: "OddQuantityServed",
                    sortable: false,
                    width: 50
                },

                {
                    text: "Und",
                    value: "OddUnitMeasurement",
                    sortable: false,
                    width: 50,
                },

                {
                    text: "Comentario",
                    value: "OddObservation",
                    sortable: false,
                    width: 400,
                },
            ],

            //Attach
            /* itemsAttach: [],
            headersFiles: [{
                    text: "Archivo",
                    value: "RqdAttach",
                },
                {
                    text: "Fec. Creaciòn",
                    value: "SecCreate",
                },
            ], */

            //Sustentos
            itemsSupport: [],
            //itemsSupport_: [],
            //itemsRequiredSelected: [],

            //Loading
            processing: false,
            errors: null,

            //Circuitos
            circuits: [],
            dialogCircuit: false,
            TrsCode: "TrsAttended",

            //Transacciones
            optionApproved: false,
            transactionApproved: null,

            //Attach
            showUpload: false,
            configFile: {
                TrsCode: "TrsAttended",
                DocEntry: this.value,
                OttID: "ID",
                service: _sOrderPurchaseAttended
            }
        }
    },
    computed: {

    },

    created() {

        if (this.value != null) {
            this.initialize()
        }

        /*  this.getConfigFiles() */
    },

    methods: {

        initialize() {
            _sOrderPurchaseAttended
                .byID({
                        OttID: this.value,
                    },
                    this.$fun.getUserID()
                )
                .then((r) => {

                    r.data.Result.itemsAttachs.forEach((element) => {
                        element.Attach = {
                            name: element.RqdAttach,
                        };
                    });

                    this.orderLocal = r.data.Result
                    this.Supplier = r.data.Result
                    this.itemsSAP = r.data.Result.itemsDetails;
                    this.itemsSupport = r.data.Result.itemsSupport;

                    console.log("al levantar", this.orderLocal)

                    //this.itemsSupport = []
                    //this.itemsSupport.push(...this.orderLocal.itemsSupport);

                    /* this.itemsSupport.forEach(element => {
                        this.itemsRequiredSelected.push(element);
                    }); */

                    //this.itemsSupport_ = _.groupBy(this.itemsSupport, "RqsGroup");

                    this.transactionApproved = this.orderLocal.Transaction;
                    if (this.orderLocal.Transaction != null)
                        if (this.orderLocal.Transaction.UsersApproved.length > 0)
                            if (
                                this.orderLocal.Transaction.UsersApproved.find(
                                    (x) => x.UsrID == this.$fun.getUserID()
                                )
                            )
                                if (this.orderLocal.OttStatus == 4) this.optionApproved = true;

                });

        },

        //Seleccionar Fila
        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },

        conformity() {
            _sOrderPurchaseAttended.generatedConformity(this.orderLocal.OrdID, this.orderLocal.OttDocumentNumber).then((resp) => {
                console.log(resp.data.Result.Message);
                _sHelper.download(resp.data.Result.Message, 2).then((r) => {
                    this.$fun.download(r.data, resp.data.Result.Message);
                });
            })
        }

        /*         getConfigFiles() {
                    _AttachConfiguration.list({
                        TrsCode: "TrsAttended",
                        CpyID: localStorage.getItem('CpyID')
                    }, this.$fun.getUserID()).then((resp) => {
                        this.itemsAttach = resp.data.Result;
                    });
                }, */

        /*    openFile(url) {
               if (url == null) return;
               _sHelper.download(url.name, 4).then((r) => {
                   this.$fun.download(r.data, url.name);
               });
           }, */

        /*         uploadFile() {
                    this.$fun.uploadFiles(this.itemsAttach, 4).then((resp) => {
                        if (resp.status == 200) {
                            if (resp.data !== null) {
                                resp.data.forEach((element) => {
                                    element.OttID = this.orderLocal.OttID;
                                    element.UsrCreateID = this.$fun.getUserID();
                                });

                                _sOrderPurchaseAttended
                                    .savefiles({
                                            itemsAttachs: resp.data,
                                        },
                                        this.$fun.getUserID()
                                    )
                                    .then((r) => {
                                        this.initialize();
                                    });
                            }
                        }
                    });
                }, */

        /*         deleteFile(item) {
                    delete item.Attach;
                    delete item.SecCreate;

                    item.SecStatus = 0;
                    item.UsrUpdateID = this.$fun.getUserID();
                    console.log(item);

                    _sOrderPurchaseAttended
                        .savefiles({
                                itemsAttachs: [item],
                            },
                            this.$fun.getUserID()
                        )
                        .then((r) => {
                            this.initialize();
                        });
                }, */

    },
};
</script>
