<template>
<div>
    <b-row>
        <b-col md="12">
            <b-card title="Basic" class="main-card mb-3">
                <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-success="fileUploaded"></vue-dropzone>
            </b-card>
           <!--  <div v-for="(item, index) in uploadedFiles" :key="index">
                <p>{{ item[0].RqdAttach }}</p>
                <button @click="removeFile(file)">Eliminar</button>
            </div> -->
        </b-col>
    </b-row>
</div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    props: {
        parameter: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {

            dropzoneOptions: {
                url: "https://190.223.56.84:8183/api/v1.0/helper/dropzone?parameter=" + this.parameter,
                thumbnailWidth: 150,
                maxFilesize: 2,
                headers: {
                    "My-Awesome-Header": "header value"
                },
                acceptedFiles: '.jpg,.jpeg,.png',
                maxFiles: 1, // Limita la cantidad máxima de archivos a 1
            },
            uploadedFiles:[]
        }
    },

    watch: {

    },

    methods: {

        fileUploaded(file, response) {
            console.log("fileUploaded",file,response);
            this.$emit('fileUploaded', response[0])
        },
    }
};
</script>
