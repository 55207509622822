import Service from "../Service";

const resource = "GenNaturalPerson/";

export default {
    pagination(parameters) {
        return Service.post(resource + "pagination", parameters);
    },

    save(ntp, requestID) {
        return Service.post(resource + "save", ntp, {
            params: { usrID: requestID },
        });
    },

    getNaturalPerson(document, requestID) {
        console.log("getNaturalPerson", document);
        return Service.post(
            resource + "getnaturalperson", {}, {
                params: { document: document },
            }
        );
    },

    personSearch(person, requestID) {
        return Service.post(resource + "personsearch", person, {
            params: {},
        });
    },



};